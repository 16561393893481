import PixisLogo from "assets/PixisLogo.svg";
import {
  HomeIcon,
  CreateIcon,
  FolderIcon,
  BrandkitIcon,
  HelpIcon,
  LayersIcon,
  LabelIcon,
} from "components/Icons";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "hooks/store";
import { MODEL_NAMES, PERMISSIONS, ROUTE_PATH_MAP } from "utils/enums";
import { Grid, Icon, List, ListItem } from "@mui/material";
import { TypographyBodyDefault } from "components/styledComponents/Typography";
import { useEventsProvider } from "mixPanelEvent";
import { hasRolePermission } from "utils/session";

export const navbarList = [
  {
    icon: LabelIcon,
    label: "Brands",
    value: ROUTE_PATH_MAP.BRANDS,
    multiBrand: true,
  },
  {
    icon: HomeIcon,
    label: "Home",
    value: ROUTE_PATH_MAP.HOME,
  },
  {
    icon: CreateIcon,
    label: "Create",
    value: ROUTE_PATH_MAP.CREATE,
  },
  {
    icon: FolderIcon,
    label: "Folders",
    value: ROUTE_PATH_MAP.FOLDERS,
  },
  {
    icon: BrandkitIcon,
    label: "Renders",
    value: ROUTE_PATH_MAP.RENDERS,
  },
  {
    icon: LayersIcon,
    label: "Assets",
    value: ROUTE_PATH_MAP.ASSETS,
  },
  {
    icon: HelpIcon,
    label: "Help",
    value: ROUTE_PATH_MAP.HELP,
  },
];

function Navbar() {
  const navigate = useNavigate();
  const { folderId } = useParams();
  const [active, setActive] = useState("");
  const { onLogoClick, onHelpClick } = useEventsProvider();

  useEffect(() => {
    setActive(window.location.pathname);
    if (folderId) setActive(ROUTE_PATH_MAP.FOLDERS);
  });

  const isMultiBrand = useAppSelector(store => store.user.isMultiBrand);
  const currentBrandId = useAppSelector(store => store.user.currentBrandId);
  const canCreate = hasRolePermission(MODEL_NAMES.PROJECT, PERMISSIONS.CREATE);

  const handleLogoClick = () => {
    onLogoClick();
    navigate(ROUTE_PATH_MAP.HOME);
  };

  return (
    <Grid
      sx={{
        minWidth: "96px",
        width: "96px",
        zIndex: 5,
      }}
    >
      <Grid
        sx={{
          width: "96px",
          padding: "16px",
          height: "calc(100vh)",
          display: "flex",
          position: "fixed",
          top: "0px",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          background: "var(--color-palette-gray-800, #253042)",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          borderRadius: "4px",
          zIndex: 5,
        }}
      >
        <img
          onClick={handleLogoClick}
          src={PixisLogo}
          style={{ width: "40px", height: "40px", cursor: "pointer" }}
        />
        <List sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {navbarList.filter(item => canCreate ? true : item.value !== ROUTE_PATH_MAP.CREATE).filter(item => isMultiBrand ? (currentBrandId ? true : item.multiBrand) : !item.multiBrand).map((page, index) => (
            <ListItem
              key={index}
              className={`${page.label}`}
              sx={{
                display: "flex",
                padding: "8px 4px",
                flexDirection: "column",
                gap: "4px",
                background:
                  active === page.value
                    ? "var(--color-palette-gray-700, #394455)"
                    : "transparent",
                borderRadius: "8px",
                ":hover": {
                  background: "var(--color-palette-gray-700, #394455)",
                  "& path": { fill: "white" },
                },
              }}
              onClick={() => { navigate(page.value); if (page.label === "Help") onHelpClick(); }}
            >
              <Icon
                sx={{
                  width: "24px",
                  height: "24px",
                  fontSize: "24px",
                  "& path": {
                    fill: active === page.value ? "white" : "#7D899C",
                  },
                  "& svg": { width: "24px", height: "24px" },
                }}
              >
                {page.icon}
              </Icon>
              <TypographyBodyDefault
                sx={{
                  fontWeight: active === page.value ? 600 : 400,
                  letterSpacing: "-0.1px",
                  color:
                    active === page.value
                      ? "var(--color-palette-white, #FFF)"
                      : "var(--color-palette-gray-500, #7D899C)",
                }}
              >
                {page.label}
              </TypographyBodyDefault>
            </ListItem>
          ))}
        </List>
        <img
          src={PixisLogo}
          style={{ width: "40px", height: "40px", opacity: 0 }}
        />
      </Grid>
    </Grid>
  );
}

export default Navbar;
