import { Checkbox, styled } from "@mui/material";
import React from "react";

export const StyledCheckbox = styled(Checkbox)((props) => {
  return {
    width: "20px",
    height: "20px",
    padding: "0",
		svg: {
			fill: "#AEB6C4"
		},
		"&.Mui-checked": {
			svg: {
				fill: "#3C88FC",
			}
		}
  };
});
