import React from "react";
import { ASSET_TYPE } from "utils/enums";
import { Asset } from "utils/types";

const getAssetViewComponent = (asset: Asset, styles: any, thumbnail: boolean = true) => {
    if (thumbnail && asset.thumbnail) {
        if (asset.thumbnail.mimeType.startsWith("video")) {
            return (<video autoPlay muted loop src={asset.thumbnail.url} className={styles["library__asset"]} />);
        }
        if (asset.thumbnail.mimeType.startsWith("image")) {
            return (<img src={asset.thumbnail.url} alt={asset.name} className={styles["library__asset"]} />);
        }
    }

    switch (asset.type) {
        case ASSET_TYPE.IMAGE:
        case ASSET_TYPE.LOGO:
        case ASSET_TYPE.PRODUCT:
        case ASSET_TYPE.BACKGROUND:
        case ASSET_TYPE.BACKGROUND_DISPLAY:
            return (<img src={asset.url} alt={asset.name} className={styles["library__asset"]} />);

        case ASSET_TYPE.GREEN_SCREEN:
        case ASSET_TYPE.VIDEO:
            return (<video autoPlay muted loop src={asset.url} className={styles["library__asset"]} />);

        case ASSET_TYPE.COLOR:
            return (<div style={{ width: "100%", height: "100%", borderRadius: "4px", backgroundColor: asset.mimeType }}></div>);

        case ASSET_TYPE.FONT:
            return (<h3 className={styles["font-title"]} title={asset.name} style={{ fontFamily: `CustomFont_${asset.id}` }}>{asset.name}</h3>);

        default:
            return (<h3 className={styles["font-title"]} title={asset.name}>{asset.name}</h3>);
    }
}

export default getAssetViewComponent;
