import { Button, Typography } from "@mui/material";
import { AllIcon, BrandkitIcon, FileIcon, NotificationIcon, SquareSvg } from "components/Icons";
import { useAppDispatch, useAppSelector } from "hooks/store";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { postApi } from "utils/fetchUtils";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { notificationRedirect } from "utils/enums";
import { useNavigate } from "react-router-dom";


import "./notification.css";
import { getAllNotificationData } from "store/notification";

const NotificationTable = () => {
  const dispatch=useAppDispatch()
  
  const navigate = useNavigate();
  const notificationList = useAppSelector(
    (store) => store.notification.notificationList
  );
  const [filter, setFilter] = useState(notificationList);
  const [activeButton, setActiveButton] = useState(true);
  useEffect(()=>{
    dispatch(getAllNotificationData())
  },[notificationList])

  useEffect(() => {
    if (activeButton) {
      setFilter(notificationList);
    } else {
      const data = filter.filter((key:any) => key?.flag_view == false);
      setFilter(data);
    }
  }, [notificationList, activeButton]);

  const handleNotification = (data: any) => {

    postApi("/notification/read-notification",{notification_ids:[data?.id]}).then(()=>{
      dispatch(getAllNotificationData())
    })
    const notData = notificationRedirect?.[data?.notification_type];
    if (notData?.path == "/renders") {
      navigate(notData.path, {
        state: {
          id: data?.field_value,
          tabNumber: notData?.tabNumber,
        },
      });
    }
    if (notData?.path == "/assets") {
      navigate(notData.path);
    }
    if (notData?.path == "/") {
      if (notData?.isModelOpen) {
        navigate("/", { state: { openProfile: true } });
      } else {
        navigate("/");
      }
    }
    if (notData?.path == "/contact-us") {
      navigate("/contact-us");
    }
  };



  const buttonStyleAll = {
    border: activeButton ? "none" : "1px solid #7D899C",
    textTransform:"none",
    borderRadius: "9999px", // For making it a capsule shape
    padding: "8px 16px",
    color: activeButton ? "#ffff" : "#7D899C",
    marginRight: "8px", // Adjust spacing between buttons as needed
    backgroundColor: activeButton ? "#394455" : "transparent",
    '&:hover': { backgroundColor: activeButton ? "#394455" : "transparent"}
  };
  const buttonStyleUnread = {
    border: !activeButton ? "none" : "1px solid #7D899C",
    textTransform:"none",
    borderRadius: "9999px", // For making it a capsule shape
    padding: "8px 16px",
    color: !activeButton ? "#ffff" : "#7D899C",
    marginRight: "8px", // Adjust spacing between buttons as needed
    backgroundColor: !activeButton ? "#394455" : "transparent",
    '&:hover': {
      backgroundColor: !activeButton ? "#394455" : "transparent"
    }
  };


  return (
    <>
      <div
        style={{
          width: "60%",
          height: "80vh",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Header */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <span
            style={{
              fontFamily: "Inter",
              fontSize: "24px",
              fontWeight: 600,
              lineHeight: "32px",
              letterSpacing: "-0.015em",
              color: "#ffff",
              textAlign: "left",
            }}
          >
            Notifications
          </span>

          <div>
            <Button
              sx={buttonStyleUnread}
              startIcon={FileIcon}
              onClick={() => {
                setActiveButton(false);
                const data = filter.filter((key:any) => key?.flag_view == false);
                setFilter(data);
              }}
            >
              Unread
            </Button>
            <Button

              sx={buttonStyleAll}
              startIcon={BrandkitIcon}
              onClick={() => {
                setActiveButton(true);
                setFilter(notificationList);
              }}
            >
              All
            </Button>
          </div>
        </div>

        <div style={{ overflowY: "auto", flex: 1 }}>
          {filter.map((notification: any, index) => {
            return (
              <div
                key={index}
                onClick={() => handleNotification(notification)}
                className="notificationHover"
              >
                <Typography
                  variant="body2"
               style={{ fontSize: '10px', lineHeight: '12px', fontWeight: 400, fontFamily: 'Inter', color: '#7D899C', marginBottom: '4px' }}
                >
                  <FiberManualRecordIcon
                    sx={{
                      fontSize: 10,
                      marginRight: "4px",
                      color: notification?.flag_view ? "#7D899C" : "#F77108",
                    }}
                  />
                  {moment(notification?.createdAt).calendar()}
                </Typography>
                <Typography style={{ color: '#ffffff', fontSize: '12px', lineHeight: '14px' ,fontFamily: 'Inter' }}>
                  {notification?.message_body}
                </Typography>
              </div>
            );
          })}

          {filter.length === 0 && (
            <Typography>No notifications</Typography>
          )}
        </div>

        {/* Footer */}
      </div>
    </>
  );
};

export default NotificationTable;
