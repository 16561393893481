import { styled } from "@mui/material";
import React from "react";

import { ToastContainer, Slide } from "react-toastify";

const StyledContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
    width:auto;
    min-width: 300px;
  }
.Toastify__toast-theme--light.Toastify__toast--info {
    background:rgb(102, 178, 255);
    color:rgb(1, 67, 97);
    min-height:unset !important;
    padding : 6px 12px;
    font-size : 0.875rem;
    font-weight: 400;
    margin-left:24px
}
.Toastify__toast-theme--light.Toastify__toast--success {
    background:rgb(102, 255, 102);
    color:rgb(30, 70, 32);
    min-height:unset !important;
    padding : 6px 12px;
    font-size : 0.875rem;
    font-weight: 400;
    margin-left:24px
}
.Toastify__toast-theme--light.Toastify__toast--warning {
    background:rgb(255, 178, 102);
    color:rgb(102, 60, 0);
    min-height:unset !important;
    padding : 6px 12px;
    font-size : 0.875rem;
    font-weight: 400;
    margin-left:24px
}
.Toastify__toast-theme--light.Toastify__toast--error {
    background:rgb(255, 102, 102);
    color:rgb(95, 33, 32);
    min-height:unset !important;
    padding : 6px 12px;
    font-size : 0.875rem;
    font-weight: 400;
    margin-left:24px
}
.Toastify__close-button {
    align-self:center;
    padding: 4px 0px 0px 16px;
    opacity:0.7
}
.Toastify__close-button > svg {
    color:rgb(95, 33, 32);
    height:21px;
    width:17px;
}
`;


function StyledToastContainer() {
    return (
        <StyledContainer
            position="bottom-center"
            theme={"dark"}
            autoClose={4000}
            style={{ zIndex: 9999999999999 }}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            draggable
            pauseOnHover
            transition={Slide}
            closeButton={true}
        />
    )
}


export default React.memo(StyledToastContainer)