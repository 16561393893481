import {createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getApi } from "../utils/fetchUtils";


const initialState = {
  notificationList: [],
};

export const getAllNotificationData = createAsyncThunk(
  "getNotificationData",
  async () => {
    const response = await getApi("notification/notification-log");

    return response;
  }
);
const notificationSlices = createSlice({
  name: "asset",
  initialState,
  reducers: {
    updateNotification: (state, { payload }) => {
      

    
      return  {...state,notificationList:payload}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllNotificationData.fulfilled, (state, { payload }) => {
      return { ...state, notificationList: payload };
    });
  },
});

export const { updateNotification } = notificationSlices.actions;

export default notificationSlices.reducer;
