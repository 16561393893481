import React from "react";
import { Dialog, Grid } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  TypographyBodyDefault,
  TypographyBodyLarge,
  TypographyBodyMedium,
} from "components/styledComponents/Typography";
import { useAuth0 } from "@auth0/auth0-react";
import PersonIcon from "@mui/icons-material/Person";
import { useEventsProvider } from "mixPanelEvent";
import { useAppSelector } from "hooks/store";
import { USER_ROLE } from "utils/enums";

type ModalProps = {
  onClose: () => void;
  open: boolean;
};

const UserProfileModal = (props: ModalProps) => {
  const { onClose, open } = props;
  const { user } = useAuth0();
  const userRole = useAppSelector((store) => store.user.role);
  const { picture, name, email } = user || {};
  const { onProfileSave, onProfileResetPassword } = useEventsProvider();
  const brand = useAppSelector((store) => store.user.brand?.name ?? "Pixis");
  const propertyData = useAppSelector(
    (store) => store.property.brandProperties
  );
  var brandName = "";
  if (propertyData && propertyData?.some((item) => item.key === "name")) {
    let brandData = propertyData?.filter((item) => item.key === "name");
    brandName = brandData[0].value;
  } else {
    brandName = brand;
  }

  const onResetPassword = () => {
    onProfileResetPassword();
  };

  const onSave = () => {
    onProfileSave();
  };

  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        PaperProps={{
          sx: {
            background: "var(--color-palette-gray-800, #253042)",
            borderRadius: "16px",
            padding: "32px",
            maxWidth: "1200px",
            minHeight: "128px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            gap: "2rem",
          },
        }}
      >
        <Grid
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={4}
          width={"100%"}
          flexDirection={"column"}
        >
          <Grid
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            gap={1}
          >
            <TypographyBodyLarge
              sx={{
                color: "var(--color-palette-white, #FFF)",
                alignSelf: "center",
              }}
            >
              {"Profile"}
            </TypographyBodyLarge>
            <CloseIcon
              sx={{
                width: "32px",
                height: "32px",
                padding: "4px",
                color: "white",
                bgcolor: "#1A202B",
                borderRadius: "100px",
                cursor: "pointer",
                "&:hover": {
                  bgcolor: "#394455",
                },
              }}
              onClick={onClose}
            />
          </Grid>
          <Grid
            width={"100%"}
            display={"flex"}
            flexDirection={"row"}
            gap={3}
            flex={1}
          >
            <Grid>
              <div
                style={{
                  height: "140px",
                  width: "140px",
                  borderRadius: "16px",
                  backgroundColor: "var(--color-palette-gray-900, #1A202B)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {picture ? (
                  <img
                    src={picture}
                    alt="profile-pic"
                    style={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "16px",
                    }}
                  />
                ) : (
                  <PersonIcon
                    sx={{
                      fontSize: "5rem",
                      color: "var(--color-palette-gray-800, #253042)",
                    }}
                  />
                )}
              </div>
            </Grid>
            <Grid display={"flex"} flexDirection={"column"} gap={2} flex={1}>
              <TypographyBodyLarge sx={{ color: "#fff" }}>
                Details
              </TypographyBodyLarge>
              <Grid
                display={"flex"}
                flexDirection={"column"}
                gap={1.5}
                flex={1}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TypographyBodyMedium sx={{ width: "120px" }}>
                    Name
                  </TypographyBodyMedium>
                  <TypographyBodyDefault>{name}</TypographyBodyDefault>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TypographyBodyMedium sx={{ width: "120px" }}>
                    Brand
                  </TypographyBodyMedium>
                  <TypographyBodyDefault>{brandName}</TypographyBodyDefault>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TypographyBodyMedium sx={{ width: "120px" }}>
                    Email ID
                  </TypographyBodyMedium>
                  <TypographyBodyDefault>{email}</TypographyBodyDefault>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TypographyBodyMedium sx={{ width: "120px" }}>
                    Access Level
                  </TypographyBodyMedium>
                  <TypographyBodyDefault>
                    {userRole === USER_ROLE["ca-brand-admin"]
                      ? "Manager"
                      : "Editor"}
                  </TypographyBodyDefault>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TypographyBodyMedium sx={{ width: "120px" }}>
                    Language
                  </TypographyBodyMedium>
                  <TypographyBodyDefault>{"English"}</TypographyBodyDefault>
                </div>
              </Grid>
              {/* <Grid
                display={"flex"}
                flexDirection={"column"}
                gap={1.5}
                flex={1}
              >
                <TypographyBodyDefault>Other Details</TypographyBodyDefault>
                <TypographyBodyDefault>Date of birth</TypographyBodyDefault>
                <TypographyBodyDefault>Gender</TypographyBodyDefault>
                <TypographyBodyDefault>Country</TypographyBodyDefault>
              </Grid>
              <Grid
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                gap={2}
                marginTop={"96px"}
              >
                <PrimaryButton
                  onClick={onResetPassword}
                  sx={{
                    p: "12px 24px",
                    width: "100%",
                    height: "40px",
                    backgroundColor: "transparent",
                    border: "1px solid #7D899C",
                    "&:hover": {
                      backgroundColor: "transparent",
                    },
                  }}
                >
                  <TypographyCTA1
                    sx={{
                      color: "#7D899C",
                    }}
                  >
                    Reset password
                  </TypographyCTA1>
                </PrimaryButton>
                <PrimaryButton
                  onClick={onSave}
                  sx={{
                    p: "12px 48px",
                    height: "40px",
                  }}
                >
                  <TypographyCTA1 sx={{ color: "white" }}>Save</TypographyCTA1>
                </PrimaryButton>
              </Grid>*/}
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default UserProfileModal;
