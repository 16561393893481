import { TablePagination } from '@mui/material';
import React from 'react';
import {  styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const TablePaginationStyled = styled(TablePagination)(() => {
    return {
        border: 'none',
        '& .MuiToolbar-root': {
            minHeight: '52px',
            alignItems: 'center'
        },
        '& .MuiToolbar-root .MuiTablePagination-selectLabel': {
            marginBottom: '0px',
            fontFamily: "Inter !important",
            fontWeight: "400 !important",
            fontSize: "14px !important",
            lineHeight: "22px !important",
            color: "#AEB6C4",
        },
        '& .MuiToolbar-root .MuiTablePagination-displayedRows': {
            marginBottom: '0px',
            fontFamily: "Inter !important",
            fontWeight: "400 !important",
            fontSize: "14px !important",
            lineHeight: "22px !important",
            color: "#AEB6C4",
        },
        '& .MuiToolbar-root .MuiInputBase-root': {
            fontFamily: "Inter !important",
            fontWeight: "400 !important",
            fontSize: "14px !important",
            lineHeight: "22px !important",
            color: "#AEB6C4",
            padding: '2px 8px 0px 8px',
            '& .MuiSvgIcon-root': {
                color: "#AEB6C4",
            }
        },
        
        '& .MuiToolbar-root .MuiTablePagination-actions .MuiButtonBase-root': {
            color: "#AEB6C4",
        },
    };
});
const useStyles2 = makeStyles(theme => ({
    selectDropdown: { color: "#fff",   backgroundColor: "#1A202B" },
    menuItem: {
          fontFamily: "Inter !important",
          fontWeight: "400 !important",
          fontSize: "14px !important",
          lineHeight: "22px !important",
          color: "#AEB6C4",
      "&:hover": {
        backgroundColor: "#394455",
      }
    }
}));

type StyledTablePaginationProps = { rowsPerPage: number, page: number, count: number, onPageChange: any, onRowsPerPageChange: any }

const StyledTablePagination = ({ page, rowsPerPage, onRowsPerPageChange, count, onPageChange }: StyledTablePaginationProps) => {
    const classes = useStyles2();

    if (count <= rowsPerPage) { return <></>; }

    return (
        <TablePaginationStyled
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            colSpan={3}
            count={count || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
            SelectProps={{
                MenuProps: { classes: { paper: classes.selectDropdown } }
            }}
            classes={{ menuItem: classes.menuItem }}
        />
    );
}

export default React.memo(StyledTablePagination);
