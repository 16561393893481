import React from "react";
import { Grid } from "@mui/material";
import { PrimaryButton } from "./styledComponents/Button";
import {
  TypographyBodyLarge,
  TypographyCTA2,
  TypographyTitle,
} from "./styledComponents/Typography";
import TemplateImage from "../assets/templateImgTemp.png";

type BrandsDisplayCardProps = {
  primaryText: string;
  subText: string;
  btnClick: Function;
  btnText: string;
};

const BrandsDisplayCard = (props: BrandsDisplayCardProps) => {
  return (
    <Grid
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      sx={{
        marginTop: "120px",
        width: "60%",
        height: "340px",
        color: "white",
        borderRadius: "16px",
        background: "var(--color-palette-gray-800, #253042)",
      }}
    >
      <Grid
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start "}
        justifyContent={"center"}
        width={"60%"}
        p={6}
        gap={2}
      >
        <TypographyTitle>{props.primaryText}</TypographyTitle>
        <TypographyBodyLarge>{props.subText}</TypographyBodyLarge>
        <PrimaryButton
          sx={{ p: "16px 24px", br: "8px" }}
          onClick={props.btnClick}
        >
          <TypographyCTA2>{props.btnText}</TypographyCTA2>
        </PrimaryButton>
      </Grid>
      <Grid
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        width={"40%"}
        height={"100%"}
      >
        <img
          src={TemplateImage}
          style={{ height: "100%", objectFit: "cover" }}
        />
      </Grid>
    </Grid>
  );
};

export default BrandsDisplayCard;
