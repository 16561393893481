import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { postFormApi } from "../utils/fetchUtils";
import { CallbackType, SegmentMaskData } from "../utils/types";

const initialState = {
  maskData: null as SegmentMaskData | null,
}

export const segmentationSlice = createSlice({
  name: "segmentation",
  initialState,
  reducers: {
    updateMaskImages: (state, action: PayloadAction<SegmentMaskData>) => {
      state.maskData = action.payload;
    },
    resetMaskImages :(state) => {
      state.maskData =  null;
    },
  },
});

export default segmentationSlice.reducer;

export const getAllMaskImages = (image: Blob, callback?: CallbackType): AppThunk =>
  async dispatch => {
    try {
      const response = await postFormApi("ai/segment-anything", { image }, undefined, true);
      dispatch(segmentationSlice.actions.updateMaskImages(response));
    }
    catch (err) {
      console.error(err);
    }
    finally {
      callback?.();
    }
  }

export const resetMaskImages = segmentationSlice.actions.resetMaskImages;
