import React from "react";
import { ClickAwayListener, Dialog, Grid } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  TypographyBodyDefault,
  TypographyBodyLarge,
  TypographyCTA1,
} from "../styledComponents/Typography";
import { AsyncButton, PrimaryButton } from "../styledComponents/Button";

type ModalProps = {
  title: string;
  onClose: () => void;
  open: boolean;
  onDelete: () => Promise<void>;
  warning?: string;
  btnTitle?: string;
};

const Alert = (props: ModalProps) => {
  const { title, onClose, open, onDelete, warning, btnTitle="Delete" } = props;

  return (
    <ClickAwayListener
      onClickAway={(e) => {
        e.preventDefault();
      }}
    >
      <Dialog
        onClose={onClose}
        open={open}
        sx={{ zIndex: 1000000000 }}
        PaperProps={{
          sx: {
            background: "var(--color-palette-gray-800, #253042)",
            borderRadius: "16px",
            padding: "32px",
            width: "35vw",
            maxWidth: "400px",
            minHeight: "250px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            gap: "2rem",
          },
        }}
      >
        <Grid
          display={"flex"}
          alignItems={"flex-start"}
          justifyContent={"space-between"}
          gap={4}
          width={"100%"}
        >
          <TypographyBodyLarge
            sx={{
              color: "var(--color-palette-white, #FFF)",
              alignSelf: "center",
            }}
          >
            {title}
          </TypographyBodyLarge>
          <CloseIcon
            sx={{
              width: "32px",
              height: "32px",
              padding: "4px",
              color: "white",
              bgcolor: "#1A202B",
              borderRadius: "100px",
              cursor: "pointer",
              "&:hover": {
                bgcolor: '#394455'
              }
            }}
            onClick={onClose}
          />
        </Grid>
        <TypographyBodyDefault sx={{ WebkitLineClamp: 4 }}>
          {warning ? warning : "This action cannot be undone."}
        </TypographyBodyDefault>
        <PrimaryButton sx={{ p: "16px 24px", width: "100%" }} onClick={onClose}>
          <TypographyCTA1>Go Back</TypographyCTA1>
        </PrimaryButton>
        <AsyncButton
          sx={{ p: "16px 24px", width: "100%" }}
          onClick={onDelete}
          onEnd={onClose}
          text={btnTitle}
          variant="secondary"
        />
      </Dialog>
    </ClickAwayListener>
  );
};

export default Alert;
