import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { UploadIcon } from "components/Icons";
import { Grid, Icon } from "@mui/material";
import Alert from "components/modals/Alert";
import { Asset } from "utils/types";
import { ASSET_TYPE, MODAL_MODE, MODEL_NAMES, PERMISSIONS, USER_GUIDE_STEP } from "utils/enums";
import {
  TypographyBodyLarge,
  TypographyBodyMedium,
} from "components/styledComponents/Typography";
import getAssetViewComponent from "components/AssetView";
import styles from "../index.module.css";
import AssetModal from "components/modals/AssetModal";
import Tooltip from "components/styledComponents/Tooltip";
import { useEventsProvider } from "mixPanelEvent";
import { hasRolePermission } from "utils/session";
import AssetsDetailJoyride from "components/UserGuideJoyride/assetsDetailJoyride";
import BrandWebsite from "./sections/BrandWebsite";
import BrandGuideline from "./sections/BrandGuideline";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { deleteAssetsById } from "store/asset";
import { SnackbarContext } from "components/Snackbar";


const StylesTab = () => {
  const [openUploadModal, setOpenUploadModal] = useState(false);

  const [imageAssets, setImageAssets] = useState<Asset[] | null>([]);
  const [colorAssets, setColorAssets] = useState<Asset[] | null>([]);
  const [fontAssets, setFontAssets] = useState<Asset[] | null>([]);
  const [selectedId, setSelectedId] = useState<string>("")
  const [openAlert, setOpenAlert] = useState(false);
  const brandkitAssets = useAppSelector(store => store.asset.brandkitAssets);
  const [assetType, setAssetType] = useState<ASSET_TYPE>(ASSET_TYPE.LOGO);
  const { onBrandStyleAddFont, onBrandStyleAddColor, onBrandStyleAddLogo } = useEventsProvider();
  const canCreate = hasRolePermission(MODEL_NAMES.BRANDKIT, PERMISSIONS.CREATE);
  const canDelete = hasRolePermission(MODEL_NAMES.BRANDKIT, PERMISSIONS.DELETE);
  const dispatch = useAppDispatch();
  const { showSnackbar } = useContext(SnackbarContext);

  const propertyData = useAppSelector(store => store.property.userProperties);
  const isJoyrideVisible = propertyData && propertyData.length > 0 && !propertyData.some(item => item.key === USER_GUIDE_STEP.ASSETS_DETAILS) && false
  const isBrandLevel = useAppSelector(store => store?.user?.brand?.id);
  const currentBrandId = useAppSelector(store => store?.user?.currentBrandId);
  const isSuperAdmin = useAppSelector(store => store.user.isSuperAdmin);
  const isMultiBrand = useAppSelector(store => store.user.isMultiBrand);
  const checkBrandLevel = isSuperAdmin && isMultiBrand ? currentBrandId : isBrandLevel

  useEffect(() => {
    if (!brandkitAssets) {
      setImageAssets(null);
      setColorAssets(null);
      setFontAssets(null);
    } else {
      setImageAssets(
        brandkitAssets.filter(asset => asset.type === ASSET_TYPE.LOGO).sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
      );
      setColorAssets(
        brandkitAssets.filter(asset => asset.type === ASSET_TYPE.COLOR).sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
      );
      setFontAssets(
        brandkitAssets.filter(asset => asset.type === ASSET_TYPE.FONT).sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
      );
    }
  }, [brandkitAssets]);


  const handleDelete = (assetIds: string[]) =>
    new Promise<void>((resolve) => {
      dispatch(
        deleteAssetsById(assetIds, showSnackbar, () => {
          setSelectedId("");
          resolve();
        })
      );
    });
  return (
    <>
      {isJoyrideVisible && <AssetsDetailJoyride />}
      {openAlert && selectedId && (
        <Alert
          open={openAlert}
          title="Are you sure you want to delete selected item?"
          onClose={() => { setOpenAlert(false); setSelectedId("") }}
          onDelete={() =>
            handleDelete([selectedId])
          }
        />
      )}
      {openUploadModal && (
        <AssetModal
          modalMode={MODAL_MODE.UPLOAD}
          uploadAssetType={assetType}
          open={openUploadModal}
          isBrandkit
          onClose={() => {
            setOpenUploadModal(false);
          }}
        />
      )}

      <Grid
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        justifyContent={"center"}
        width={"100%"}
        gap={8}
      >
        <Grid
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          width={"100%"}
          gap={2}
        >
          <TypographyBodyLarge
            sx={{ color: "var(--color-palette-white, #FFF)" }}

          >
            Logos
          </TypographyBodyLarge>
          <Grid
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            gap={2}
            flexWrap={"wrap"}
          >
            {imageAssets &&
              imageAssets.map((asset) => {
                return (
                  <Grid
                    key={asset.id}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    minHeight={"110px"}
                    gap={"6px"}
                  >
                    <Grid
                      sx={{
                        width: "80px",
                        height: "80px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "#AEB6C4",
                        position: 'relative'
                      }}
                      className={styles.style_tab_parent}
                    >
                      {getAssetViewComponent(asset, styles)}
                      {
                        canDelete && <div onClick={() => {
                          setSelectedId(asset.id);
                          setOpenAlert(true)
                        }} className={styles.style_tab_close}>
                          <HighlightOffIcon sx={{ color: 'var(--color-palette-blue-300, #3C88FC)' }} />
                        </div>
                      }

                    </Grid>
                    <Grid
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                    >
                      <Tooltip title={asset.name}>
                        <TypographyBodyMedium
                          sx={{
                            color: "var(--color-palette-white, #FFF)",
                            maxWidth: "80px",
                          }}
                        >
                          {asset.name}
                        </TypographyBodyMedium>
                      </Tooltip>
                      <TypographyBodyMedium>
                        {asset.mimeType}
                      </TypographyBodyMedium>
                    </Grid>
                  </Grid>
                );
              })}
            {canCreate && <Tooltip title="Add New Logo">
              <Grid
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                minHeight={"110px"}
                id="logos"
                gap={"6px"}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  onBrandStyleAddLogo();
                  setAssetType(ASSET_TYPE.LOGO);
                  setOpenUploadModal(true);
                }}
              >
                <Grid
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{
                    width: "80px",
                    height: "80px",
                    borderRadius: "16px",
                    border: "1px dashed var(--color-palette-gray-700, #394455)",
                  }}
                >
                  <Icon>{UploadIcon}</Icon>
                </Grid>
                <TypographyBodyMedium
                  sx={{ color: "var(--color-palette-white, #FFF)" }}
                >
                  Add Logo
                </TypographyBodyMedium>
              </Grid>
            </Tooltip>}
          </Grid>
        </Grid>
        <Grid
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          width={"100%"}
          gap={2}
        >
          <TypographyBodyLarge
            sx={{ color: "var(--color-palette-white, #FFF)" }}

          >
            Colors
          </TypographyBodyLarge>
          <Grid
            display={"flex"}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            gap={2}
            flexWrap={"wrap"}
          >
            {colorAssets &&
              colorAssets.map((asset) => {
                return (
                  <Grid
                    key={asset.id}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    minHeight={"110px"}
                    gap={"6px"}
                    className={styles.style_tab_parent}
                  >
                    <Grid sx={{ width: "64px", height: "64px" }}>
                      {getAssetViewComponent(asset, styles)}
                      {
                        canDelete && <div onClick={() => {
                          setSelectedId(asset.id);
                          setOpenAlert(true)
                        }} className={styles.style_tab_close}>
                          <HighlightOffIcon sx={{ color: 'var(--color-palette-blue-300, #3C88FC)' }} />
                        </div>
                      }

                    </Grid>
                    <Grid
                      display={"flex"}
                      alignItems={"center"}
                      justifyContent={"center"}
                      flexDirection={"column"}
                    >
                      <Tooltip title={asset.name}>
                        <TypographyBodyMedium
                          sx={{ color: "var(--color-palette-white, #FFF)" }}
                        >
                          {asset.name}
                        </TypographyBodyMedium>
                      </Tooltip>
                      <TypographyBodyMedium>
                        {asset.mimeType}
                      </TypographyBodyMedium>
                    </Grid>
                  </Grid>
                );
              })}
            {canCreate && <Tooltip title="Add New Color">
              <Grid
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                id="colors"
                minHeight={"110px"}
                gap={"6px"}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  onBrandStyleAddColor();
                  setAssetType(ASSET_TYPE.COLOR);
                  setOpenUploadModal(true);
                }}
              >
                <Grid
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{
                    width: "64px",
                    height: "64px",
                    borderRadius: "16px",
                    border: "1px dashed var(--color-palette-gray-700, #394455)",
                  }}
                >
                  <Icon>{UploadIcon}</Icon>
                </Grid>
                <TypographyBodyMedium
                  sx={{ color: "var(--color-palette-white, #FFF)" }}
                >
                  Add Color
                </TypographyBodyMedium>
              </Grid>
            </Tooltip>}
          </Grid>
        </Grid>
        <Grid
          display={"flex"}
          flexDirection={"column"}
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          width={"100%"}
          gap={2}
        >
          <TypographyBodyLarge
            sx={{ color: "var(--color-palette-white, #FFF)" }}

          >
            Fonts
          </TypographyBodyLarge>
          <Grid
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={2}
            flexWrap={"wrap"}
          >
            {fontAssets &&
              fontAssets.map((asset) => {
                return (
                  <Grid
                    key={asset.id}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    gap={"6px"}
                    className={styles.style_tab_parent}
                  >
                    <Grid width={"200px"}>
                      {getAssetViewComponent(asset, styles)}
                    </Grid>
                    {
                      canDelete && <div onClick={() => {
                        setSelectedId(asset.id);
                        setOpenAlert(true)
                      }} className={styles.style_tab_close}>
                        <HighlightOffIcon sx={{ color: 'var(--color-palette-blue-300, #3C88FC)' }} />
                      </div>
                    }

                  </Grid>
                );
              })}
            {canCreate && <Tooltip title="Add New Font">
              <Grid
                display={"flex"}
                flexDirection={"column"}
                id="fonts"
                alignItems={"center"}
                gap={"6px"}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  onBrandStyleAddFont();
                  setAssetType(ASSET_TYPE.FONT);
                  setOpenUploadModal(true);
                }}
              >
                <Grid
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  sx={{
                    padding: "20px 34px",
                    borderRadius: "16px",
                    border: "1px dashed var(--color-palette-gray-700, #394455)",
                    gap: "6px",
                  }}
                >
                  <Icon>{UploadIcon}</Icon>
                  <TypographyBodyMedium
                    sx={{ color: "var(--color-palette-white, #FFF)" }}
                  >
                    Add Font Style
                  </TypographyBodyMedium>
                </Grid>
              </Grid>
            </Tooltip>}
          </Grid>
        </Grid>
        {
          checkBrandLevel ? <>
            <BrandWebsite />
            <BrandGuideline />
          </> : <></>
        }

      </Grid>
    </>
  );
};

export default StylesTab;
