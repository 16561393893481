import React, { Suspense, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import "./App.css";
import routes from "router";
import { selectBrand } from "store/user";
import { ROUTE_PATH_MAP, TOKEN_SOURCE } from "utils/enums";
import { useAuth0 } from "@auth0/auth0-react";
import "react-toastify/dist/ReactToastify.css";
import StyledToastContainer from "components/styledComponents/ToastContainer";
import LoadingPage from "containers/LoadingPage";

import io from "socket.io-client";
import { updateNotification } from "store/notification";

const SERVER_URL: any = process.env.REACT_APP_SOCKETURL; // Replace with your server URL

const openPaths = [ROUTE_PATH_MAP.AUTH];
const bothPaths = [ROUTE_PATH_MAP.AUTH_BRAND_INVITE];

function App() {
  const routesResult = useRoutes(routes);
  const dispatch = useAppDispatch();
  const notificationList = useAppSelector(
    (store) => store.notification.notificationList
  );

  const currentBrandId = useAppSelector((store) => store.user.currentBrandId);
  const brandId = useAppSelector((store) => store.user.session?.brandId);

  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const isOpenPath = openPaths.reduce(
    (prev, curr) => prev || pathname.startsWith(curr.replaceAll("/*", "")),
    false
  );
  const isBothPath = bothPaths.reduce(
    (prev, curr) => prev || pathname.startsWith(curr.replaceAll("/*", "")),
    false
  );
  const firstRender = useRef(true);
  const { isAuthenticated, getIdTokenClaims, user } = useAuth0();
  const [socket, setSocket] = useState<any>(null);
  useEffect(() => {
    if (isAuthenticated && brandId) {
      const getSocket = io(SERVER_URL, {
        transports: ["websocket"],
        reconnection: true,
        retries: 180000,
        query: {
          userId: user?.sub,
          brandId,
        },
      });

      setSocket(getSocket);
    }
  }, [isAuthenticated, brandId]);

  socket?.on("notification", (data: any) => {
    const finalData = [{ flag_view: false, ...data }, ...notificationList];
    if (
      notificationList?.find((key: any) => key?.id == data?.id)?.id == undefined
    )
      dispatch(updateNotification(finalData));
  });

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      const timeout = setTimeout(() => {
        if (!isOpenPath) {
          navigate(ROUTE_PATH_MAP.AUTH_SIGNUP);
        }
      }, 5000);
      return () => clearTimeout(timeout);
    }
    const getTokenAndMakeApiCalls = async () => {
      const claims = await getIdTokenClaims();
      window.localStorage.setItem("token", claims?.__raw ?? "");
      window.localStorage.setItem("token_source", TOKEN_SOURCE.AUTH0);
      dispatch(selectBrand(currentBrandId));
    };
    if (isAuthenticated) {
      if (brandId && brandId.length > 0) {
      }

      if (isOpenPath && !isBothPath) {
        navigate(ROUTE_PATH_MAP.HOME);
      }
      getTokenAndMakeApiCalls();
    } else if (!isOpenPath) {
      navigate(ROUTE_PATH_MAP.AUTH_LOGOUT);
    }
  }, [isAuthenticated]);

  return (
    <div className="App">
      {isOpenPath || isAuthenticated ? (
        <>
          <Suspense fallback={<LoadingPage />}>{routesResult}</Suspense>
          <StyledToastContainer />
        </>
      ) : (
        <LoadingPage />
      )}
    </div>
  );
}

export default App;
