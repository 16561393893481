import React from "react";

import { Grid } from "@mui/material";
import { TypographyBodyLarge, TypographyBodyMedium, TypographyCTA1, TypographyTitle, TypographyTitleGroup } from "components/styledComponents/Typography";
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { PrimaryButton } from "components/styledComponents/Button";
interface MyProps {
    children: React.ReactNode
}

interface MyState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<MyProps, MyState> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        console.log("getDerivedStateFromError", error)
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any) {
        console.log("componentDidCatch", error)
        // You can also log the error to an error reporting service
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <Grid
                    width={"100%"}
                    height={"100%"}
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={3}
                >
                    <WarningAmberOutlinedIcon sx={{ color: 'white', fontSize: '100px' }} />
                    <Grid
                        display={"flex"}
                        flexDirection={"column"}
                        gap={1}
                        alignItems={"center"}
                    >
                        <TypographyTitle sx={{ color: 'white', }} >Oops! Something went wrong.</TypographyTitle>
                        <TypographyTitle sx={{ color: 'white', }} >Please try again later.</TypographyTitle>
                        <PrimaryButton onClick={() => { window.location.href = "/" }} sx={{ width: '160px' }} >
                            <TypographyCTA1>Back to Home</TypographyCTA1>
                        </PrimaryButton>
                    </Grid>

                </Grid>
            );
        }

        return this.props.children;
    }
}
export default ErrorBoundary