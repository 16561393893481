import React from 'react';
import { toast } from 'react-toastify';
import { SEVERITY } from '../../utils/enums';

export function openToast (msg: string, type: SEVERITY) {

    const typeMapped = {
        success: 'success',
        error: 'error',
        warning: 'warn',
        info: 'info',
    };

    return toast[typeMapped[type]](msg);
}
