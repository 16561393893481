import React, { useCallback, useContext, useState } from "react";
import { Button, ButtonProps, styled } from "@mui/material";
import { TypographyCTA1 } from "./Typography";
import { Loader } from "../../components/Loader";
import { SEVERITY } from "../../utils/enums";
import { SnackbarContext } from "../../components/Snackbar";

export const PrimaryButton = styled(Button)(() => {
  return {
    color: "#fff",
    display: "flex",
    padding: "8px 16px",
    justifyContent: "center",
    alignItems: "center",

    borderRadius: "6px",
    background: "var(--color-primary-primary-400, #0869FB)",
    textTransform: "none",
    ":hover": {
      background: "var(--color-palette-blue-300, #3C88FC)",
    },
    ":active": {
      background: "var(--color-palette-blue-200, #74AAFD)",
    },
    ":disabled": {
      opacity: 0.5,
      background: "var(--color-palette-blue-400, #0869FB)",
    }
  };
});


export const SecondaryButton = styled(Button)(() => {
  return {
    color: "var(--color-palette-gray-400, #AEB6C4)",
    display: "flex",
    padding: "8px 16px",
    justifyContent: "center",
    alignItems: "center",

    borderRadius: "6px",
    background: "var(--color-palette-gray-700, #394455)",
    textTransform: "none",
    ":hover": {
      background: "var(--color-palette-gray-600, #5C6674)",
    },
    ":active": {
      background: "var(--color-palette-gray-500, #7D899C)",
    },
    ":disabled": {
      opacity: 0.5,
    }
  };
});

export const AsyncButton = (props: ButtonProps & { onClick: () => Promise<void>, variant?: "primary" | "secondary", text?: string, onEnd?: () => void }) => {
  const { showSnackbar } = useContext(SnackbarContext);

  const [isLoading, setIsLoading] = useState(false);
  const variant = props.variant ?? "primary";

  const onClick = useCallback(async () => {
    if (isLoading) { return; }
    try {
      setIsLoading(true);
      await props.onClick?.();
      props.onEnd?.();
    }
    catch (error: any) {
      showSnackbar(error, SEVERITY.WARNING);
    }
    finally {
      setIsLoading(false);
    }
  }, [isLoading, props.onClick]);

  const content = isLoading ? (
    <Loader type="primary" />
  ) : (
    props.text ? <TypographyCTA1>
      {props.text}
    </TypographyCTA1>
    : props.children
  );

  return (
    variant === "primary" ?
      <PrimaryButton {...props} onClick={onClick}>{content}</PrimaryButton>
      :
      <SecondaryButton {...props} onClick={onClick}>{content}</SecondaryButton>
  );
}
