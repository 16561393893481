import React, { useContext, useState } from "react";
import { Dialog, Grid, Input, capitalize } from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import {
  TypographyBodyDefault,
  TypographyBodyLarge,
  TypographyBodySmallNormal,
} from "components/styledComponents/Typography";
import { AsyncButton } from "components/styledComponents/Button";
import { ASSET_TYPE, MODAL_MODE, SEVERITY } from "utils/enums";
import styles from "./brandGuideline.module.css";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { deleteAssetsById, uploadBrandGuidelineAssets } from "store/asset";
import { SnackbarContext } from "components/Snackbar";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { createBrandProperty, updateBrandProperty } from "store/property";
import { downloadFileFromURL } from "utils/utils";

type ModalProps = {
  onClose: () => void;
  open: boolean;
  modalMode: MODAL_MODE;
  currentGuideline?: { url: string; name: string };
};

const BrandGuidelineModal = (props: ModalProps) => {
  const { onClose, open, modalMode, currentGuideline } = props;

  const [dragActive, setDragActive] = useState(false);
  const [name, setName] = useState("");
  const [uploadText, setUploadText] = useState("Upload Files");
  const [fileData, setFileData] = useState<FileList>(new DataTransfer().files);
  const dispatch = useAppDispatch();
  const { showSnackbar } = useContext(SnackbarContext);
  const propertyData = useAppSelector(
    (store) => store.property.brandProperties
  );
  const brandGuidelines = propertyData
    ? propertyData.find((item) => item.key === "brand-guidelines")
    : undefined;
  const pdfAssets = useAppSelector((store) =>
    store.asset.brandkitAssets?.filter((asset) => asset.type === ASSET_TYPE.PDF)
  );

  const onFileChange = (
    e: React.DragEvent | React.ChangeEvent,
    files: FileList | null
  ) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    if (files && files[0]) {
      if (name === "") setName(autofillName(files[0].name));
      // at least one file has been dropped so do something
      setFileData(files);
      setUploadText(files[0].name);
    }
  };

  const handleDrag = function (e: React.DragEvent) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const autofillName = (input: string) => {
    const name = input
      .split(".")[0] // removing extension
      .replaceAll(/[_-]+/g, " ") // replacing _ and - char with space
      .replaceAll(/[^a-zA-Z0-9 ]+/g, ""); // removing all other char other than a-zA-Z0-9 and space

    return capitalize(name);
  };

  const onSubmit = () =>
    new Promise<void>((resolve, reject) => {
      if (!fileData.length) {
        return reject("No file selected.");
      }
      const assetIds = pdfAssets?.map((asset) => asset.id) ?? [];
      dispatch(
        uploadBrandGuidelineAssets({ file: fileData[0], name }, (asset) => {
          dispatch(deleteAssetsById(assetIds));
          const callback = () => {
            showSnackbar?.("Brand Guidelines uploaded.", SEVERITY.SUCCESS);
            resolve();
          };
          if (brandGuidelines) {
            dispatch(
              updateBrandProperty(
                "brand-guidelines",
                asset.metadata.guidelines,
                undefined,
                callback
              )
            );
          } else {
            dispatch(
              createBrandProperty(
                "brand-guidelines",
                asset.metadata.guidelines,
                undefined,
                callback
              )
            );
          }
        })
      );
    }).then(onClose);

  const onDownload = () =>
    new Promise<void>((resolve) => {
      if (currentGuideline) {
        downloadFileFromURL(
          currentGuideline.url,
          currentGuideline.name,
          showSnackbar
        );
        resolve();
      }
    });

  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          background: "var(--color-palette-gray-800, #253042)",
          borderRadius: "16px",
          padding: "32px",
          width: "35vw",
          minWidth: "400px",
          maxWidth: "1200px",
          minHeight: "128px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: "2rem",
        },
      }}
    >
      <Grid
        display={"flex"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        gap={4}
        width={"100%"}
      >
        <TypographyBodyLarge
          sx={{
            color: "var(--color-palette-white, #FFF)",
            alignSelf: "center",
          }}
        >
          {currentGuideline ? currentGuideline.name : "Upload Guideline"}
        </TypographyBodyLarge>
        <CloseIcon
          sx={{
            width: "32px",
            height: "32px",
            padding: "4px",
            color: "white",
            bgcolor: "#1A202B",
            borderRadius: "100px",
            cursor: "pointer",
            "&:hover": {
              bgcolor: "#394455",
            },
          }}
          onClick={onClose}
        />
      </Grid>
      <Grid
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        gap={2}
        onDragEnter={handleDrag}
        width={"100%"}
      >
        <Grid display={"flex"} gap={2} flexDirection={"column"} width={"100%"}>
          {modalMode !== MODAL_MODE.UPLOAD ? (
            <Grid
              width={"100%"}
              height={"10rem"}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {currentGuideline && (
                <PictureAsPdfIcon
                  sx={{
                    fontSize: "4rem",
                    color: "var(--color-palette-gray-500, #7D899C)",
                  }}
                />
              )}
            </Grid>
          ) : (
            <>
              <Input
                id="input-file-upload"
                type="file"
                sx={{ display: "none" }}
                inputProps={{ accept: "pdf,txt" }}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  onFileChange(e, e.target.files)
                }
              />
              <label
                id="label-file-upload"
                htmlFor="input-file-upload"
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "0.5rem",
                  border: "1px solid #596573",
                  borderRadius: "1rem",
                  margin: "0",
                  cursor: "pointer",
                  opacity: dragActive ? 0.5 : 1,
                  background: dragActive ? "#394455" : "#253041",
                  padding: "1rem",
                }}
                className={`${styles["upload_label"]}`}
              >
                <div className={`${styles["asset-div"]}`}>
                  <PictureAsPdfIcon
                    sx={{
                      fontSize: "1.5rem",
                      color: "var(--color-palette-gray-500, #7D899C)",
                    }}
                  />
                </div>
                <div>
                  <TypographyBodyDefault
                    className={`${styles["upload__text"]}`}
                  >
                    {uploadText}
                  </TypographyBodyDefault>
                  <TypographyBodySmallNormal
                    className={`${styles["upload__sub_text"]} ${styles["upload__text"]}`}
                  >
                    {`${["pdf", "txt"].join(", ")} only*`}
                  </TypographyBodySmallNormal>
                </div>
              </label>
              {dragActive && (
                <div
                  id="drag-file-element"
                  className={`${styles["dnd__area"]}`}
                  onDragEnter={handleDrag}
                  onDragLeave={handleDrag}
                  onDragOver={handleDrag}
                  onDrop={(e) => onFileChange(e, e.dataTransfer.files)}
                ></div>
              )}
            </>
          )}
        </Grid>
        <TypographyBodySmallNormal sx={{ color: "rgb(255, 178, 102)" }}>
          {
            "⚠️ This action will remove previous brand guidelines and cannot be undone."
          }
        </TypographyBodySmallNormal>
      </Grid>
      {modalMode === MODAL_MODE.UPLOAD ? (
        <Grid
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <AsyncButton
            sx={{ p: "12px 24px" }}
            onClick={onSubmit}
            text="Upload"
          />
        </Grid>
      ) : (
        <Grid
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <AsyncButton
            sx={{ p: "12px 24px" }}
            onClick={onDownload}
            text="Download"
          />
        </Grid>
      )}
    </Dialog>
  );
};

export default BrandGuidelineModal;
