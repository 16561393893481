import React from "react";
import { CircularProgress } from "@mui/material";

type LoaderProps = {
  type?: string,
  size?: string,
  style?: any
}


export function Loader({ type = "", size = "1rem", style = {} }: LoaderProps) {
  return <CircularProgress size={size} sx={{ color: type === "primary" ? "white" : "primary", minHeight: "24px", minWidth: "24px", ...style }} />;
}
