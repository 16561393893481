import FacebookAd from "./FacebookAd";
import ImageAd from "./ImageAd";
import React from "react";
import { PROJECT_TYPE } from "utils/enums";
import { AdDataPrompt, AdVariant } from "utils/types";

const GetAdViewComponent = ({ ad, variant, index, onImageLoadCb }: {
	ad: AdDataPrompt,
	variant: AdVariant,
	index?: number,
	onImageLoadCb?: Function
}

) => {
	if (ad.prompt?.type === PROJECT_TYPE.FACEBOOK_AD) {
		return <FacebookAd ad={ad} variant={variant} />;
	}
	return <ImageAd onImageLoadCb={onImageLoadCb} index={index} ad={ad} variant={variant} />;
};

export default GetAdViewComponent;
