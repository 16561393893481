import React, { useContext, useState } from "react";
import moment from "moment";
import {
  DeleteIcon,
  EditIcon,
  LinkIcon,
  OpenInNewIcon,
} from "components/Icons";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Icon,
  TableSortLabel,
  Skeleton,
  TableFooter,
  Grid,
} from "@mui/material";
import { Asset } from "utils/types";
import { ASSET_TYPES, MODAL_MODE, MODEL_NAMES, PERMISSIONS } from "utils/enums";
import { copyToClipboard, enumToName } from "utils/utils";
import {
  TypographyBodyDefault,
  TypographyBodyMedium,
} from "components/styledComponents/Typography";
import getAssetViewComponent from "components/AssetView";
import styles from "./assets_table.module.css";
import { Link } from "react-router-dom";
import Alert from "components/modals/Alert";
import { useAppDispatch, useAppSelector } from "hooks/store";
import { deleteAssetsById } from "store/asset";
import { SnackbarContext } from "components/Snackbar";
import AssetModal from "components/modals/AssetModal";
import StyledTablePagination from "./TablePagination";
import Tooltip from "components/styledComponents/Tooltip";
import AsyncDownloadIcon from "components/AsyncDownloadIcon";
import BrandsDisplayCard from "components/BrandsDisplayCard";
import { useEventsProvider } from "mixPanelEvent";
import { StyledCheckbox } from "components/styledComponents/Checkbox";
import { hasRolePermission } from "utils/session";

const CellSkeleton = () => (
  <Skeleton
    sx={{ minWidth: "100%", bgcolor: "grey.600" }}
    width="100%"
    height={40}
    variant="text"
  />
);

const AssetsTable = ({
  handleGetStarted,
  debounceSearch,
}: {
  handleGetStarted: Function;
  debounceSearch: string;
}) => {
  const dispatch = useAppDispatch();
  const [openAlert, setOpenAlert] = useState(false);
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const [selectedAssetId, setSelectedAssetId] = useState<string>();
  const { showSnackbar } = useContext(SnackbarContext);
  const [editasset, setEditAsset] = useState(false); // useState for edit options
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const {
    onBrandAssetsDelete,
    onBrandAssetCopylink,
    onBrandAssetEdit,
    onBrandAssetOpenNewTab,
  } = useEventsProvider();

  const assets = useAppSelector((store) => store.asset.allAssets);
  const brandUsers = useAppSelector((store) => store.user.brandUsers ?? {});
  const canDelete = hasRolePermission(MODEL_NAMES.ASSET, PERMISSIONS.DELETE);
  const canUpdate = hasRolePermission(MODEL_NAMES.ASSET, PERMISSIONS.UPDATE);
  const userId = useAppSelector(store => store.user.session?.userId);

  const [sortConfig, setSortConfig] = useState<{
    field: string | undefined;
    direction: "asc" | "desc" | undefined;
  }>({ field: "createdAt", direction: "desc" });

  const handleSort = (field: string) => {
    let direction: "asc" | "desc" = "asc";
    if (sortConfig.field === field && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ field, direction });
  };

  const sortedValues = assets
    ? Object.values(assets)
      .filter((asset) => ASSET_TYPES.includes(asset.type))
      .sort((a: any, b: any) => {
        if (sortConfig.field) {
          const fieldA = a[sortConfig.field];
          const fieldB = b[sortConfig.field];
          if (!fieldA && !fieldB) return 0;
          if (!fieldA || fieldA < fieldB) {
            return sortConfig.direction === "asc" ? -1 : 1;
          }
          if (!fieldB || fieldA > fieldB) {
            return sortConfig.direction === "asc" ? 1 : -1;
          }
        }
        return 0;
      })
    : [];

  const handleDelete = (assetIds: string[]) =>
    new Promise<void>((resolve) => {
      dispatch(
        deleteAssetsById(assetIds, showSnackbar, () => {
          onBrandAssetsDelete(assetIds);
          setSelected(new Set());
          resolve();
        })
      );
    });

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filterAssets = (val: string) => {
    return val
      ? sortedValues?.filter((item) =>
        item?.name?.toLocaleLowerCase()?.startsWith(val.toLocaleLowerCase())
      )
      : sortedValues;
  };

  const filteredValues = filterAssets(debounceSearch);
  const currentPageValues = filteredValues.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const isSelected = (id: string) => selected.has(id);

  let areAllValuesSelected = currentPageValues.length > 0 ? true : false;

  currentPageValues.forEach((val) => {
    if (!isSelected(val.id)) areAllValuesSelected = false;
  });

  const currentPageSelectedValues = currentPageValues.filter((val) =>
    selected.has(val.id)
  );

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newSelected = new Set(selected);

    if (event.target.checked) {
      for (const val of currentPageValues) {
        newSelected.add(val.id);
      }
    } else {
      for (const val of currentPageSelectedValues) {
        newSelected.delete(val.id);
      }
    }

    setSelected(newSelected);
  };

  const handleSelectClick = (event: React.MouseEvent<unknown>, id: string) => {
    event.preventDefault();
    event.stopPropagation();
    const newSelected = new Set(selected);

    if (selected.has(id)) {
      newSelected.delete(id);
    } else {
      newSelected.add(id);
    }

    setSelected(newSelected);
  };

  return (
    <>
      {openAlert && currentPageSelectedValues.length > 0 && (
        <Alert
          open={openAlert}
          title="Are you sure you want to delete selected item?"
          onClose={() => { setOpenAlert(false); setSelected(new Set()) }}
          onDelete={() =>
            handleDelete(currentPageSelectedValues.map((val) => val.id))
          }
        />
      )}
      {editasset && selectedAssetId && assets?.[selectedAssetId] && (
        <AssetModal
          modalMode={MODAL_MODE.EDITING}
          asset={assets[selectedAssetId]}
          open={editasset}
          onClose={() => {
            setEditAsset(false);
          }}
        />
      )}
      {assets && sortedValues.length === 0 ? (
        <BrandsDisplayCard
          primaryText="Creative branded templates to use anywhere"
          subText="With brand colors and logos"
          btnClick={handleGetStarted}
          btnText="Get Started"
        />
      ) : (
        <TableContainer
          sx={{
            width: "100%",
            maxWidth: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            border: "1px solid var(--color-palette-gray-800, #253042)",
            background: "var(--color-palette-gray-800, #253042)",
            borderRadius: assets && Object.keys(assets).length < 5 ? "0px" : "24px",
            maxHeight: "calc(100vh - 240px)",
          }}
        >
          <Table
            sx={{
              gap: "2rem",
              width: "100%",
              maxWidth: "100%",
              color: "#FFFFFF",
            }}
            stickyHeader
          >
            <TableHead
              sx={{
                position: "sticky",
                top: 0,
                background: "var(--color-palette-gray-800, #253042)",
                "& .MuiTableCell-root": {
                  backgroundColor: "var(--color-palette-gray-800, #253042)",
                },
                zIndex: 5,
              }}
            >
              <TableRow
                sx={{
                  display: "flex",
                  flexWrap: "nowrap",
                  padding: "16px 32px",
                  "td, th": {
                    border: 0,
                    padding: "0px",
                    paddingRight: "48px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  },
                }}
              >
                <TableCell width={"3%"}>
                  <StyledCheckbox
                    checked={areAllValuesSelected}
                    onChange={handleSelectAllClick}
                  />
                </TableCell>
                <TableCell width={"12%"} align="left">
                  <TypographyBodyMedium width={"100%"}>
                    Preview
                  </TypographyBodyMedium>
                </TableCell>
                <TableCell width={"17%"} align="left">
                  <TableSortLabel
                    sx={{
                      color: "#7D899C",
                      "&:hover": { color: "#7D899C" },
                      "&:focus": { color: "#7D899C" },
                    }}
                    direction={
                      sortConfig.field === "name" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("name")}
                  >
                    <TypographyBodyMedium>Name</TypographyBodyMedium>
                  </TableSortLabel>
                </TableCell>
                <TableCell width={"18%"} align="left">
                  <TableSortLabel
                    sx={{
                      color: "#7D899C",
                      "&:hover": { color: "#7D899C" },
                      "&:focus": { color: "#7D899C" },
                    }}
                    direction={
                      sortConfig.field === "type" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("type")}
                  >
                    <TypographyBodyMedium>Type</TypographyBodyMedium>
                  </TableSortLabel>
                </TableCell>
                <TableCell width={"18%"} align="left">
                  <TableSortLabel
                    sx={{
                      color: "#7D899C",
                      "&:hover": { color: "#7D899C" },
                      "&:focus": { color: "#7D899C" },
                    }}
                    direction={
                      sortConfig.field === "type" ? sortConfig.direction : "asc"
                    }
                    onClick={() => handleSort("tags")}
                  >
                    <TypographyBodyMedium>Tags</TypographyBodyMedium>
                  </TableSortLabel>
                </TableCell>
                <TableCell width={"15%"} align="left">
                  <TableSortLabel
                    sx={{
                      color: "#7D899C",
                      "&:hover": { color: "#7D899C" },
                      "&:focus": { color: "#7D899C" },
                    }}
                    direction={
                      sortConfig.field === "createdAt"
                        ? sortConfig.direction
                        : "asc"
                    }
                    onClick={() => handleSort("createdAt")}
                  >
                    <TypographyBodyMedium>Uploaded</TypographyBodyMedium>
                  </TableSortLabel>
                </TableCell>
                <TableCell width={"17%"} align="left">
                  {currentPageSelectedValues.length > 0 ? (
                    <Grid
                      width={"100%"}
                      display={"flex"}
                      alignItems={"center"}
                      gap={2}
                    >
                      <TypographyBodyMedium>{`${currentPageSelectedValues.length} selected`}</TypographyBodyMedium>
                      <Grid
                        display={"flex"}
                        alignItems={"center"}
                        sx={{
                          ".MuiCircularProgress": {
                            width: "20px",
                            height: "20px",
                          },
                        }}
                      >
                        <AsyncDownloadIcon
                          styles={styles}
                          files={assets ? currentPageSelectedValues : []}
                        />
                        {canDelete && (
                          <Tooltip title={"Delete Selection"}>
                            <Icon
                              sx={{
                                cursor: "pointer",
                                width: "20px",
                                height: "20px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                svg: { width: "18px", height: "18px" },
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setOpenAlert(true);
                              }}
                            >
                              {DeleteIcon}
                            </Icon>
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    <TypographyBodyMedium>Actions</TypographyBodyMedium>
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                gap: "0.3rem",
                background: "var(--color-palette-gray-900, #1A202B)",
              }}
            >
              {assets === null ? (
                <>
                  {Array(5)
                    .fill(0)
                    .map((val, idx) => (
                      <TableRow
                        key={idx}
                        sx={{
                          display: "flex",
                          flexWrap: "nowrap",
                          borderColor: "rgba(255, 255, 255, 0.1)",
                          padding: "16px 32px",
                          "td, th": {
                            border: 0,
                            padding: "0px",
                            paddingRight: "48px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-start",
                            alignSelf: "center",
                          },
                          ":hover": {
                            background:
                              "var(--color-palette-gray-700, #394455)",
                          },
                          height: "84px",
                          maxHeight: "84px",
                        }}
                      >
                        <TableCell width={"3%"}>
                          <CellSkeleton />
                        </TableCell>
                        <TableCell width={"12%"}>
                          <CellSkeleton />
                        </TableCell>
                        <TableCell width={"17%"} align="left">
                          <CellSkeleton />
                        </TableCell>
                        <TableCell width={"18%"} align="left">
                          <CellSkeleton />
                        </TableCell>
                        <TableCell width={"18%"} align="left">
                          <CellSkeleton />
                        </TableCell>
                        <TableCell width={"15%"} align="left">
                          <CellSkeleton />
                        </TableCell>
                        <TableCell width={"17%"} align="left">
                          <CellSkeleton />
                        </TableCell>
                      </TableRow>
                    ))}
                </>
              ) : (
                <>
                  {filterAssets(debounceSearch).length > 0 ? (
                    currentPageValues.map((asset: Asset) => {
                      const checked = isSelected(asset.id);
                      return (
                        <Link to={asset.url} key={asset.id} target="_blank">
                          <TableRow
                            sx={{
                              display: "flex",
                              flexWrap: "nowrap",
                              borderColor: "rgba(255, 255, 255, 0.1)",
                              padding: "16px 32px",
                              "td, th": {
                                border: 0,
                                padding: "0px",
                                paddingRight: "48px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                alignSelf: "center",
                              },
                              ":hover": {
                                background:
                                  "var(--color-palette-gray-700, #394455)",
                                "& .action": {
                                  display: "flex",
                                },
                              },
                              height: "84px",
                              maxHeight: "84px",
                            }}
                          >
                            <TableCell width={"3%"}>
                              <StyledCheckbox
                                checked={checked}
                                onClick={(event) => {
                                  handleSelectClick(event, asset.id);
                                }}
                              />
                            </TableCell>
                            <TableCell width={"12%"} align="left">
                              <div className={`${styles.asset_div}`}>
                                {getAssetViewComponent(asset, styles, true)}
                              </div>
                            </TableCell>
                            <TableCell width={"17%"} align="left">
                            
                                <TypographyBodyDefault
                                  sx={{ WebkitLineClamp: 2 }}
                                >
                                  {asset.name}
                                </TypographyBodyDefault>
                        
                            </TableCell>
                            <TableCell width={"18%"} align="left">
                              <TypographyBodyDefault>
                                {enumToName(asset.type)}
                              </TypographyBodyDefault>
                            </TableCell>
                            <TableCell width={"18%"} align="left">
                              <TypographyBodyDefault
                                sx={{ WebkitLineClamp: 2 }}
                              >
                                {asset.tags.join(", ")}
                              </TypographyBodyDefault>
                            </TableCell>
                            <TableCell
                              width={"15%"}
                              align="left"
                              sx={{ display: "flex", gap: "16px" }}
                            >
                             
                                <TypographyBodyDefault
                                  sx={{ WebkitLineClamp: 2 }}
                                >
                                  {moment(asset.createdAt).fromNow()}
                                </TypographyBodyDefault>
                       
                            </TableCell>
                            <TableCell
                              width={"17%"}
                              align="left"
                              sx={{
                                display: "flex",
                                gap: "8px",
                                a: { height: "24px" },
                              }}
                            >
                              {currentPageSelectedValues.length === 0 && (
                                <>
                                  <TypographyBodyDefault
                                    className="action"
                                    sx={{
                                      display: "none",
                                      "&:hover": {
                                        transform: "scale(1.2)",
                                        "& .material-icons > svg > path": {
                                          stroke: 'var(--color-palette-gray-400, #AEB6C4)'
                                        }
                                      },
                                     
                                    }}
                                  >
                                    <Tooltip title={"Open in New Tab"}>
                                      <Link
                                        to={asset.url}
                                        target="_blank"
                                        onClick={onBrandAssetOpenNewTab}
                                      >
                                        <Icon  >{OpenInNewIcon}</Icon>
                                      </Link>
                                    </Tooltip>
                                  </TypographyBodyDefault>

                                  <TypographyBodyDefault
                                    className="action"
                                    sx={{
                                      display: "none",
                                      "&:hover": {
                                        transform: "scale(1.2)",
                                        "& .material-icons > svg > path": {
                                          stroke: 'var(--color-palette-gray-400, #AEB6C4)'
                                        }
                                      },
                                    }}
                                  >
                                    <Tooltip title={"Copy Link"}>
                                      <Icon
                                        sx={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          onBrandAssetCopylink();
                                          copyToClipboard(
                                            asset.url,
                                            showSnackbar,
                                            "Link"
                                          );
                                        }}
                                      >
                                        {LinkIcon}
                                      </Icon>
                                    </Tooltip>
                                  </TypographyBodyDefault>

                                  <TypographyBodyDefault
                                    className="action"
                                    sx={{
                                      display: "none",
                                      "&:hover": {
                                        transform: "scale(1.2)",
                                        "& .material-icons > svg > path": {
                                          stroke: 'var(--color-palette-gray-400, #AEB6C4)'
                                        }
                                      },
                                    }}
                                  >
                                    <AsyncDownloadIcon files={[asset]} />
                                  </TypographyBodyDefault>

                                  {(canUpdate || userId === asset.userId) && (
                                    <TypographyBodyDefault
                                      className="action"
                                      sx={{
                                        display: "none",
                                        "&:hover": {
                                          transform: "scale(1.2)",
                                          "& .material-icons > svg > path": {
                                            stroke: 'var(--color-palette-gray-400, #AEB6C4)'
                                          }
                                        },
                                      }}
                                    >
                                      <Tooltip title={"Edit"}>
                                        <Icon
                                          onClick={(e) => {
                                            e.preventDefault();
                                            onBrandAssetEdit();
                                            setSelectedAssetId(asset.id);
                                            setEditAsset(true);
                                          }}
                                        >
                                          {EditIcon}
                                        </Icon>
                                      </Tooltip>
                                    </TypographyBodyDefault>
                                  )}

                                  {(canDelete || userId === asset.userId) && (
                                    <TypographyBodyDefault
                                      className="action"
                                      sx={{
                                        display: "none",
                                        "&:hover": {
                                          transform: "scale(1.2)",
                                          "& .material-icons > svg > path": {
                                            stroke: 'var(--color-palette-gray-400, #AEB6C4)'
                                          }
                                        },
                                      }}
                                    >
                                      <Tooltip title={"Delete"}>
                                        <Icon
                                          sx={{ cursor: "pointer" }}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            setSelected((prev) =>
                                              prev.add(asset.id)
                                            );
                                            setOpenAlert(true);
                                          }}
                                        >
                                          {DeleteIcon}
                                        </Icon>
                                      </Tooltip>
                                    </TypographyBodyDefault>
                                  )}
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                        </Link>
                      );
                    })
                  ) : (
                    <TypographyBodyMedium
                      sx={{
                        margin: "16px auto",
                        width: "fit-content",
                        color: "white",
                      }}
                    >
                      No result found
                    </TypographyBodyMedium>
                  )}
                </>
              )}
            </TableBody>
            {filterAssets(debounceSearch).length > page && (
              <TableFooter>
                <StyledTablePagination
                  count={filterAssets(debounceSearch).length || 0}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableFooter>
            )}
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default AssetsTable;
