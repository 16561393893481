import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { store } from './store'
import { Provider } from 'react-redux';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';
import './index.css';
import SnackbarProvider from './components/Snackbar';
import { debounce } from 'lodash';
import { saveState } from './store/browser-storage';
import mixpanel from 'mixpanel-browser';
import EventsProvider from 'mixPanelEvent';
import * as serviceWorker from './serviceWorker';

if (process.env.REACT_APP_MIX_PANEL_TOKEN) {
  mixpanel.init(process.env.REACT_APP_MIX_PANEL_TOKEN);
}

store.subscribe(
  debounce(() => {
    saveState(store.getState());
  }, 800)
);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <SnackbarProvider>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN!}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
          authorizationParams={{
            redirect_uri: window.location.origin,
          }}
        >
          <BrowserRouter>
            <EventsProvider>
              <App />
            </EventsProvider>
          </BrowserRouter>
        </Auth0Provider>
      </SnackbarProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);


serviceWorker.unregister();