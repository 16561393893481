import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { deleteApi, getApi, postApi } from '../utils/fetchUtils';
import { CallbackModelType, CallbackType, Folder, Project, showSnackbarType } from '../utils/types';
import { SEVERITY } from '../utils/enums';
import { AppThunk, store } from '../store';
import { deleteProjectById } from "./project";

const initialState = {
  allFolders: null as { [id: string]: Folder } | null,
};

const folderSlice = createSlice({
  name: "folder",
  initialState,
  reducers: {
    updateAllFolders: (state, action: PayloadAction<Folder[]>) => {
      state.allFolders = {};
      action.payload.forEach(folder => {
        if (!state.allFolders) { state.allFolders = {}; }
        state.allFolders[folder.id] = folder;
      });
    },
    updateFolder: (state, action: PayloadAction<Folder>) => {
      if (!state.allFolders) state.allFolders = {};
      state.allFolders[action.payload.id] = action.payload;
    },
    removeFolder: (state, action: PayloadAction<string>) => {
      delete state.allFolders?.[action.payload];
    },
  },
});

export default folderSlice.reducer;

export const getAllFolders = (callback?: CallbackType): AppThunk =>
  async dispatch => {
    const allFolders: Folder[] = await getApi("folder");
    dispatch(folderSlice.actions.updateAllFolders(allFolders));
    callback?.();
  }

export const createFolder = (payload: any, showSnackbar?: showSnackbarType, callback?: CallbackModelType<Folder>): AppThunk =>
  async dispatch => {
    const cb = (folder: Folder) => {
      showSnackbar?.("Folder created.", SEVERITY.SUCCESS);
      callback?.(folder);
    }

    const folder: Folder = await postApi("folder", payload);
    dispatch(folderSlice.actions.updateFolder(folder));
    cb(folder);
  }

export const updateFolderById = (id: string, name: string, showSnackbar?: showSnackbarType): AppThunk =>
  async dispatch => {
    const updatedFolder: Folder = await postApi(`folder/${id}`, { name: name.trim() });
    showSnackbar?.("Folder updated.", SEVERITY.SUCCESS);
    dispatch(folderSlice.actions.updateFolder(updatedFolder));
  }

export const deleteFolderById = (id: string, showSnackbar?: showSnackbarType, callback?: CallbackType): AppThunk =>
  async dispatch => {
    const cb = () => {
      showSnackbar?.("Folder deleted.", SEVERITY.SUCCESS);
      callback?.();
    }

    await deleteApi(`folder/${id}`);
    dispatch(folderSlice.actions.removeFolder(id));
    const projects: Project[] = Object.values(store.getState().project.projects ?? {}).filter(p => p.folderId === id);
    for (const project of projects) {
      dispatch(deleteProjectById(project.id, undefined, undefined, true));
    }

    cb();
  }

export const deleteFoldersById = (ids: string[], showSnackbar?: showSnackbarType, callback?: CallbackType): AppThunk =>
  async dispatch => {
    const cb = () => {
      showSnackbar?.("Folder(s) deleted.", SEVERITY.SUCCESS);
      callback?.();
    }
    for (const id of ids) {
      dispatch(deleteFolderById(id))
    }
    cb();
  }