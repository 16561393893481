import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "store";
import { USER_GUIDE_STEP } from "utils/enums";
import { UserGuide } from "utils/types";

const initialState = {
    userGuide: {
        isNewUser: false,
        currentStep: null,
        completedStep: [],
    } as UserGuide
}
export const userGuideSlice = createSlice({
    name: 'userGuide',
    initialState,
    reducers: {
        updateUserGuide: (state, action: PayloadAction<{ currentStep: USER_GUIDE_STEP[], nextStep: USER_GUIDE_STEP }>) => {
            state.userGuide.completedStep = [...state.userGuide.completedStep, ...action.payload.currentStep]
            state.userGuide.currentStep = action.payload.nextStep;
            if (action.payload.nextStep === USER_GUIDE_STEP.END) {
                state.userGuide.isNewUser = false;
            }
        },
        updateNewUser: (state, action: PayloadAction<boolean>) => {
            if (action.payload) {
                state.userGuide.isNewUser = action.payload;
                state.userGuide.currentStep = USER_GUIDE_STEP.CARD;
                state.userGuide.completedStep = [];
            } else {
                state.userGuide.isNewUser = action.payload;
                state.userGuide.currentStep = null;
                state.userGuide.completedStep = [];
            }

        }
    }
})

export default userGuideSlice.reducer;

export const updateUserGuideStep = (currentStep: USER_GUIDE_STEP[], nextStep: USER_GUIDE_STEP): AppThunk =>
    async dispatch => {
        dispatch(userGuideSlice.actions.updateUserGuide({ currentStep: currentStep, nextStep: nextStep }));
    }