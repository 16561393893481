import PixisLogo from "assets/PixisLogo.svg";
import {
  HomeIcon,
  NotificationIcon,
  SeperatorDashedIcon,
  SeperatorIcon,
} from "components/Icons";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/store";
import {
  MODEL_NAMES,
  PERMISSIONS,
  PROJECT_TYPE,
  ROUTE_PATH_MAP,
  SEVERITY,
  USER_GUIDE_STEP,
} from "utils/enums";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { ASSET_TYPE, notificationRedirect } from "utils/enums";

import "./header.css";
import {
  Avatar,
  Badge,
  BadgeProps,
  Box,
  Breadcrumbs,
  Button,
  ClickAwayListener,
  Divider,
  Fade,
  Grid,
  Icon,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Popover,
  Typography,
  styled,
} from "@mui/material";
import {
  TypographyBodyDefault,
  TypographyBodySmallNormal,
  TypographyCTA1,
} from "components/styledComponents/Typography";
import { enumToName } from "utils/utils";
import { navbarList } from "components/Navbar";
import { Edit, Logout } from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { PrimaryButton } from "components/styledComponents/Button";
import { TextFieldStyled } from "components/styledComponents/Input";
import { SnackbarContext } from "components/Snackbar";
import { updateProjectById } from "store/project";
import { updateFolderById } from "store/folder";
import Tooltip from "components/styledComponents/Tooltip";
import { useEventsProvider } from "mixPanelEvent";
import GlobalSearch from "components/GlobalSearch";
import PeopleIcon from "@mui/icons-material/People";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import UserProfileModal from "components/modals/UserProfileModal";
import { hasRolePermission } from "utils/session";
import UserGuideHomeJoyride from "./Joyride/UserGuideHomeJoyride";
import { updateUserGuideStep } from "store/user-guide";
import { postApi } from "utils/fetchUtils";
import moment from "moment";
import { BRAND_TABS } from "containers/assets";
import { getAllNotificationData } from "store/notification";

const CustomSeparatorIcon = ({ iconsValue }: any) => {
  return (
    <Icon
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg": { width: "20px", height: "20px" }, // Adjust the size of the SVG icon
        cursor: "default",
      }}
    >
      {iconsValue}
    </Icon>
  );
};

const HeaderBreadcrumbs = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useContext(SnackbarContext);
  const dispatch = useAppDispatch();

  const { folderId, projectId } = useParams();
  const project = useAppSelector((store) =>
    store.project.projects && projectId
      ? store.project.projects[projectId]
      : undefined
  );
  const folder = useAppSelector((store) =>
    store.folder.allFolders && folderId && folderId !== "drafts"
      ? store.folder.allFolders[folderId]
      : undefined
  );

  const [active, setActive] = useState("");
  const path = active.split("/");

  const [editedProjectName, setEditedProjectName] = useState("");
  const [projectNameEdit, setProjectNameEdit] = useState(false);

  const toggleProjectNameEdit = () => {
    setProjectNameEdit(!projectNameEdit);
  };

  const changeProjectName = () => {
    if (!project) {
      return;
    }
    if (!editedProjectName.match(/^[a-zA-Z\d ]+$/)) {
      showSnackbar("Invalid Project name.", SEVERITY.WARNING);
      return;
    }
    dispatch(
      updateProjectById(
        project.id,
        { name: editedProjectName.trim() },
        showSnackbar
      )
    );
    toggleProjectNameEdit();
  };

  const [editedFolderName, setEditedFolderName] = useState("");
  const [folderNameEdit, setFolderNameEdit] = useState(false);
  const canUpdateFolder = hasRolePermission(
    MODEL_NAMES.FOLDER,
    PERMISSIONS.UPDATE
  );
  const canUpdateProject = hasRolePermission(
    MODEL_NAMES.PROJECT,
    PERMISSIONS.UPDATE
  );
  const userId = useAppSelector((store) => store.user.session?.userId);
  const userGuide = useAppSelector((store) => store.userGuide.userGuide);
  const propertyData = useAppSelector(store => store.property.userProperties);
  const isJoyrideVisible = propertyData && propertyData.length > 0 && !propertyData.some(item => item.key === USER_GUIDE_STEP.HOME) && false;
  const isExportDownloadComplete = propertyData && propertyData.length > 0 && propertyData.some(item => item.key === USER_GUIDE_STEP.EXPORT_DOWNLOAD) && false;

  const toggleFolderNameEdit = () => {
    setFolderNameEdit(!folderNameEdit);
  };

  const changeFolderName = () => {
    if (!folder) {
      return;
    }
    if (!editedFolderName.match(/^[a-zA-Z\d ]+$/)) {
      showSnackbar("Invalid folder name.", SEVERITY.WARNING);
      return;
    }
    dispatch(updateFolderById(folder.id, editedFolderName, showSnackbar));
    toggleFolderNameEdit();
  };

  useEffect(() => {
    setActive(window.location.pathname);
  });

  if (active === ROUTE_PATH_MAP.HOME) return <></>;

  return (
    <Breadcrumbs separator={<CustomSeparatorIcon iconsValue={SeperatorIcon} />}>
      {path.length > 1 &&
        path[1] !== "home" &&
        path.map((val, index) => {
          const icon = navbarList.find(
            (value) => value.label === enumToName(val)
          );
          if (index === 0) {
            return (
              <span key={index} >
                <Tooltip title="Home">
                  <Icon
                    id="home"
                    onClick={() => {
                      navigate(ROUTE_PATH_MAP.HOME);

                    }}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      "& svg": { width: "20px", height: "20px" },

                      ":hover": {
                        "& path": {
                          fill: "var(--color-palette-gray-50, #F6F7F8)",
                        },
                      },
                    }}
                  >
                    {HomeIcon}
                  </Icon>
                </Tooltip>
                {/* {isJoyrideVisible &&
                  isExportDownloadComplete && (
                    <UserGuideHomeJoyride />
                  )} */}
              </span>
            );
          }
          if (index === 1 && val === "editor" && project) {
            let displayName = enumToName(val);
            let navigateTo = ROUTE_PATH_MAP.CREATE;
            switch (project.type) {
              case PROJECT_TYPE.IMAGE_GENERATION:
                displayName = "Image";
                navigateTo = ROUTE_PATH_MAP.IMAGE_GENERATOR;
                break;
              case PROJECT_TYPE.FACEBOOK_AD:
                displayName = "Facebook Ads";
                navigateTo = ROUTE_PATH_MAP.FACEBOOK_ADS;
                break;
              case PROJECT_TYPE.IMAGE:
                displayName = "Ad Editor";
                navigateTo = ROUTE_PATH_MAP.CREATE;
                break;
            }
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: "4px",
                  alignItems: "center",
                  ":hover": {
                    "& p": {
                      color: "var(--color-palette-gray-50, #F6F7F8)",
                    },
                  },
                }}
                onClick={() => navigate(navigateTo)}
              >
                <TypographyBodyDefault sx={{ fontWeight: 600 }}>
                  {displayName}
                </TypographyBodyDefault>
              </Box>
            );
          }
          if (index === 2 && folderId && (folderId === "drafts" || folder)) {
            if (folderNameEdit && folderId !== "drafts") {
              return (
                <ClickAwayListener key={index} onClickAway={changeFolderName}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "4px",
                      alignItems: "center",
                      ":hover": {
                        "& svg": {
                          opacity: 1,
                        },
                        "& p": {
                          color: "var(--color-palette-gray-50, #F6F7F8)",
                        },
                        "& path": {
                          fill: "var(--color-palette-gray-50, #F6F7F8)",
                        },
                      },
                    }}
                  >
                    <TextFieldStyled
                      value={editedFolderName}
                      onChange={(e: any) => setEditedFolderName(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.code === "Enter") {
                          changeFolderName();
                        }
                      }}
                    />
                  </Box>
                </ClickAwayListener>
              );
            }
            return (
              <Box
                key={index}
                // onClick={() => {
                //   if (
                //     folderId === "drafts" ||
                //     !folder ||
                //     !(canUpdateFolder || folder.userId === userId)
                //   )
                //     return;
                //   setEditedFolderName(folder.name);
                //   toggleFolderNameEdit();
                // }}
                sx={{
                  display: "flex",
                  gap: "4px",
                  alignItems: "center",
                  ":hover": {
                    "& svg": {
                      opacity: folderId === "drafts" ? 0 : 1,
                    },
                    "& p": {
                      color: "var(--color-palette-gray-50, #F6F7F8)",
                    },
                    "& path": {
                      fill: "var(--color-palette-gray-50, #F6F7F8)",
                    },
                  },
                }}
              >
                <TypographyBodyDefault
                  sx={{
                    fontWeight: 600,
                    ":hover": {
                      color: "var(--color-palette-gray-50, #F6F7F8)",
                    },
                  }}
                >
                  {folderId === "drafts"
                    ? "Drafts"
                    : folder?.name ?? "Untitled"}
                </TypographyBodyDefault>
                {/* {canUpdateFolder && (
                  <Edit
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "16px",
                      height: "16px",
                      opacity: 0,
                    }}
                  />
                )} */}
              </Box>
            );
          }
          if (
            (index === 2 &&
              projectId &&
              project &&
              path[1] !== "create" &&
              path[1] !== "editor") ||
            (index === 2 && projectId && project && path[1] === "editor") ||
            (index === 3 && projectId && project && path[1] === "create")
          ) {
            if (projectNameEdit) {
              return (
                <ClickAwayListener key={index} onClickAway={changeProjectName}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "4px",
                      alignItems: "center",
                      ":hover": {
                        "& svg": {
                          opacity: 1,
                        },
                        "& p": {
                          color: "var(--color-palette-gray-50, #F6F7F8)",
                        },
                        "& path": {
                          fill: "var(--color-palette-gray-50, #F6F7F8)",
                        },
                      },
                    }}
                  >
                    <TextFieldStyled
                      value={editedProjectName}
                      onChange={(e: any) =>
                        setEditedProjectName(e.target.value)
                      }
                      onKeyDown={(e) => {
                        if (e.code === "Enter") {
                          changeProjectName();
                        }
                      }}
                    />
                  </Box>
                </ClickAwayListener>
              );
            }
            return (
              <Box
                key={index}
                // onClick={() => {
                //   if (!(canUpdateProject || project.userId === userId)) return;
                //   setEditedProjectName(project.name);
                //   toggleProjectNameEdit();
                // }}
                sx={{
                  display: "flex",
                  gap: "4px",
                  alignItems: "center",
                  ":hover": {
                    "& svg": {
                      opacity: 1,
                    },
                    "& p": {
                      color: "var(--color-palette-gray-50, #F6F7F8)",
                    },
                    "& path": {
                      fill: "var(--color-palette-gray-50, #F6F7F8)",
                    },
                  },
                }}
              >
                <TypographyBodyDefault
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {project.name}
                </TypographyBodyDefault>
                {/* {(canUpdateProject || userId === project.userId) && (
                  <Edit
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "16px",
                      height: "16px",
                      opacity: 0,
                    }}
                  />
                )} */}
              </Box>
            );
          }
          if (index === 2 && path[1] === "create") {
            let displayName = enumToName(val);
            let navigateTo = ROUTE_PATH_MAP.CREATE;
            switch (val) {
              case "fb-ads":
                displayName = "Facebook Ads";
                navigateTo = ROUTE_PATH_MAP.FACEBOOK_ADS;
                break;
              case "image-gen":
                displayName = "Image";
                navigateTo = ROUTE_PATH_MAP.IMAGE_GENERATOR;
                break;
              case "ad-copy":
                displayName = "Ad Copies";
                navigateTo = ROUTE_PATH_MAP.TEXT_COPY_GENERATOR;
                break;
            }
            return (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  gap: "4px",
                  alignItems: "center",
                  ":hover": {
                    "& p": {
                      color: "var(--color-palette-gray-50, #F6F7F8)",
                    },
                  },
                }}
                onClick={() => navigate(navigateTo)}
              >
                <TypographyBodyDefault sx={{ fontWeight: 600 }}>
                  {displayName}
                </TypographyBodyDefault>
              </Box>
            );
          }
          if (index === 3 && path[3] === "export" && projectId) {
            return;
          }
          return icon ? (
            <Box
              key={index}
              onClick={() => navigate(icon.value)}
              sx={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                ":hover": {
                  "& p": {
                    color: "var(--color-palette-gray-50, #F6F7F8)",
                  },
                  "& path": {
                    fill: "var(--color-palette-gray-50, #F6F7F8)",
                  },
                },
              }}
            >
              <Icon
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  "& svg": { width: "16px", height: "16px" },
                }}
              >
                {icon.icon}
              </Icon>
              <TypographyBodyDefault sx={{ fontWeight: 600 }}>
                {enumToName(val)}
              </TypographyBodyDefault>
            </Box>
          ) : (
            <Box
              key={index}
              sx={{
                display: "flex",
                gap: "4px",
                alignItems: "center",
                ":hover": {
                  "& p": {
                    color: "var(--color-palette-gray-50, #F6F7F8)",
                  },
                },
              }}
            >
              <TypographyBodyDefault sx={{ fontWeight: 600 }}>
                {enumToName(val)}
              </TypographyBodyDefault>
            </Box>
          );
        })}
    </Breadcrumbs>
  );
};

const StepBreadcrumbs = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("");
  const path = active.split("/");

  const { projectId } = useParams();
  const project = useAppSelector((store) =>
    store.project.projects && projectId
      ? store.project.projects[projectId]
      : undefined
  );

  let steps: { label: string; route: string | null }[] = [];
  let currentStep = 1;

  if (!project) {
    currentStep = 1;
    if (path.length >= 3 && path[2] === "ad-copy") {
      steps.push({ label: "Choose Ads Copies", route: null });
      steps.push({ label: "Export copies", route: null });
    } else {
      steps.push({ label: "Choose Ads Images", route: null });
      steps.push({ label: "Edit Images", route: null });
      steps.push({ label: "Export", route: null });
    }
  } else {
    if (project.type === PROJECT_TYPE.TEXT_GENERATION) {
      currentStep = 2;
      steps.push({ label: "Choose Ads Copies", route: null });
      steps.push({ label: "Export copies", route: null });
    } else {
      switch (project.type) {
        case PROJECT_TYPE.IMAGE_GENERATION:
          steps.push({
            label: "Choose Ads Images",
            route: `${ROUTE_PATH_MAP.IMAGE_GENERATOR}/${project.id}`,
          });
          break;
        case PROJECT_TYPE.FACEBOOK_AD:
          steps.push({
            label: "Choose Ads Images",
            route: `${ROUTE_PATH_MAP.FACEBOOK_ADS}/${project.id}`,
          });
          break;
      }
      steps.push({
        label: "Edit Images",
        route: `${ROUTE_PATH_MAP.IMAGE_EDITOR}/${project.id}`,
      });
      steps.push({
        label: "Export",
        route: `${ROUTE_PATH_MAP.IMAGE_EDITOR}/${project.id}/export`,
      });

      if (path.includes("export")) currentStep = steps.length;
      else if (path.includes(ROUTE_PATH_MAP.IMAGE_EDITOR.slice(1)))
        currentStep = steps.length - 1;
      else currentStep = 1;

      if (
        currentStep === 1 &&
        [PROJECT_TYPE.IMAGE_GENERATION, PROJECT_TYPE.FACEBOOK_AD].includes(
          project.type
        )
      ) {
        steps = steps.map((step) => {
          return {
            ...step,
            route: null,
          };
        });
      }
    }
  }

  useEffect(() => {
    setActive(window.location.pathname);
  });

  return (
    <Breadcrumbs
      separator={<CustomSeparatorIcon iconsValue={SeperatorDashedIcon} />}
    >
      {steps.map((val: any, index) => (
        <Link
          key={index}
          color={currentStep === index + 1 ? "text.primary" : "text.secondary"}
          to={val?.route}
        >
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <span
              style={{
                background: currentStep === index + 1 ? "#0869FB" : "#394455",
                color: currentStep === index + 1 ? "#fff" : "#AEB6C4",
                width: "16px",
                height: "16px",
                borderRadius: "100px",
                fontFamily: "Inter",
                fontSize: "10px",
                fontWeight: 600,
                letterSpacing: "-0.1px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {index + 1}
            </span>
            <TypographyBodySmallNormal
              sx={{
                fontWeight: 500,
                color: currentStep === index + 1 ? "#AEB6C4" : "#7D899C",
              }}
            >
              {enumToName(val.label)}
            </TypographyBodySmallNormal>
          </Box>
        </Link>
      ))}
    </Breadcrumbs>
  );
};

type ActionButton = {
  icon: React.ReactNode;
  name: string;
  onClick: (event: React.MouseEvent) => void;
  hidden?: boolean;
  id?: string;
  disabled?: boolean;
};

type HeaderProps = {
  buttons?: ActionButton[];
  showSteps?: boolean;
};

function Header({ buttons, showSteps }: HeaderProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState();

  const [anchorElNotifiction, setAnchorElNotification] =
    React.useState<HTMLButtonElement | null>(null);
  const openNotificationEl = Boolean(anchorElNotifiction);
  const [openUserDetails, setOpenUserDetails] = useState(false);
  const open = Boolean(anchorEl);
  const { onLogout } = useEventsProvider();
  const path = window.location.pathname;
  const { user } = useAuth0();
  const isBrandLevel = useAppSelector(store => store?.user?.brand?.id);
  const currentBrandId = useAppSelector(store => store?.user?.currentBrandId);
  const isSuperAdmin = useAppSelector(store => store.user.isSuperAdmin);
  const isMultiBrand = useAppSelector(store => store.user.isMultiBrand);
  const checkBrandLevel = isSuperAdmin && isMultiBrand ? currentBrandId : isBrandLevel

  const notificationList = useAppSelector(
    (store) => store.notification.notificationList
  );

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  const handleLogout = () => {
    onLogout();
    navigate(ROUTE_PATH_MAP.AUTH_LOGOUT);
    handleClose();
    handleClose();
  };

  const toggleUserModal = () => {
    handleClose();
    setOpenUserDetails(!openUserDetails);
  };

  const handleUserManagement = () => {
    handleClose();
    navigate(ROUTE_PATH_MAP.USERS);
  };
  useEffect(() => { }, [notificationList]);

  useEffect(() => {
    if (location.state?.openProfile) {
      setOpenUserDetails(true);
      window.history.replaceState({}, "");
    }
  }, [location.state]);

  const handleNotification = (data: any) => {
    postApi("/notification/read-notification", {
      notification_ids: [data?.id],
    }).then(() => {
      dispatch(getAllNotificationData());
    });

    setAnchorElNotification(null);

    const notificationFinalData =
      notificationRedirect?.[data?.notification_type];

    if (notificationFinalData?.path == "/renders") {
      navigate(notificationFinalData.path, {
        state: {
          id: data?.field_value,
          tabNumber: notificationFinalData?.tabNumber,
        },
      });
    }
    if (notificationFinalData?.path == "/assets") {
      [ASSET_TYPE.LOGO, ASSET_TYPE.COLOR, ASSET_TYPE.FONT].includes(
        data?.field_value
      )
        ? navigate(notificationFinalData.path, {
          state: { tabNumber: BRAND_TABS.STYLES },
        })
        : navigate(notificationFinalData.path, {
          state: { tabNumber: BRAND_TABS.ASSETS },
        });
    }
    if (notificationFinalData?.path == "/") {
      if (notificationFinalData?.isModelOpen) {
        navigate("/", { state: { openProfile: true } });
      } else {
        navigate("/");
      }
    }
    if (notificationFinalData?.path == "/contact-us") {
      navigate("/contact-us");
    }
  };

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 3,
      top: 2,
      color: "#ffff",
      backgroundColor: "#F77108",
      padding: "0 4px",
    },
  }));

  return (
    <>
      <Grid
        sx={{
          minHeight: "72px",
          height: "72px",
          background: "#1A202B",
          zIndex: 4,
        }}
      >
        <Grid
          sx={{
            position: "fixed",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            height: "72px",
            zIndex: 4,
            padding: "16px 28px 16px 24px",
            borderRadius: "4px",
            background: "#1A202B",
          }}
        >
          {/* {buttons &&
            buttons.map((btn, idx) => (
              <PrimaryButton
                key={idx}
                onClick={btn.onClick}
                sx={{ padding: "8px 32px" }}
                id={btn.id}
                disabled={btn.disabled}
              >
                <TypographyCTA1>{btn.name}</TypographyCTA1>
                <Icon
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "24px",
                    height: "24px",
                  }}
                >
                  {btn.icon}
                </Icon>
              </PrimaryButton>
            ))} */}

          <Menu
            id="fade-menu"
            sx={{
              ".MuiPaper-root": {
                bgcolor: "#253042",
                color: "#fff",
              },
              ".MuiMenu-list": {
                bgcolor: "var(--color-palette-gray-800, #253042)",
                color: "#fff",
                svg: {
                  fill: "white",
                },
              },
              li: {
                bgcolor: "var(--color-palette-gray-800, #253042)",
                ":hover": {
                  bgcolor: "var(--color-palette-gray-700, #394455)",
                },
              },
            }}
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem onClick={toggleUserModal}>
              <ListItemIcon>
                <AccountCircleIcon fontSize="small" />
              </ListItemIcon>
              Profile
            </MenuItem>

            {checkBrandLevel && <MenuItem onClick={handleUserManagement}>
              <ListItemIcon>
                <PeopleIcon fontSize="small" />
              </ListItemIcon>
              User Management
            </MenuItem>}

            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>

          <Grid
            display={"flex"}
            gap={7}
            alignItems={"center"}
            sx={{

            }}
          >
            <img
              onClick={() => {
                navigate(ROUTE_PATH_MAP.HOME);
              }}
              alt="home"
              src={PixisLogo}
              style={{ width: "40px", height: "40px", cursor: "pointer" }}
            />
            <Grid display={"flex"} gap={4} alignItems={"center"}>
              <HeaderBreadcrumbs />
              {showSteps && (
                <>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ background: "#394455" }}
                  />
                  <StepBreadcrumbs />
                </>
              )}
            </Grid>
          </Grid>
          <Grid
            display={"flex"}
            justifyContent={"flex-end"}
            alignItems={"center"}
            gap={3}
          >
            <Grid display={"flex"} alignItems={"center"} gap={3}>
              {buttons &&
                buttons.map((btn, idx) => (
                  <PrimaryButton
                    key={idx}
                    onClick={btn.onClick}
                    sx={{ padding: "8px 32px" }}
                    id={btn.id}
                    disabled={btn.disabled}
                    style={{

                    }}
                  >
                    <TypographyCTA1>{btn.name}</TypographyCTA1>
                    <Icon sx={{ height: "auto", marginLeft: "4px" }}>
                      {btn.icon}
                    </Icon>
                  </PrimaryButton>
                ))}
            </Grid>

            {!showSteps && path === ROUTE_PATH_MAP.HOME ? (
              <Grid>
                <GlobalSearch />
              </Grid>
            ) : (
              <></>
            )}
            {checkBrandLevel ? <IconButton
              style={{

              }}
              onClick={(e) => setAnchorElNotification(e.currentTarget)}
            >
              <StyledBadge
                badgeContent={
                  notificationList.filter((item: any) => !item?.flag_view)
                    .length
                }
              >
                <NotificationIcon notificationColor="#F77108" />
              </StyledBadge>
            </IconButton> : <></>}
            {/* Notification popover */}
            <Popover
              open={openNotificationEl}
              anchorEl={anchorElNotifiction}
              onClose={() => setAnchorElNotification(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div
                style={{
                  backgroundColor: "#253042",
                  width: "250px",
                  height: "430px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* Header */}
                <div style={{ height: "28px", padding: "4px 12px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <NotificationIcon
                      notificationColor="#F77108"
                      width={18}
                      height={18}
                    />
                    <Button
                      style={{
                        color: "#7C899C",
                        fontSize: "12px",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        const IdsList = notificationList
                          ?.filter((key: any) => !key?.flag_view)
                          .map((key: any) => key?.id);
                        postApi("/notification/read-notification", {
                          notification_ids: IdsList,
                        }).then(() => {
                          dispatch(getAllNotificationData());
                        });
                      }}
                    >
                      Mark all as read
                    </Button>
                  </div>
                  {/* You can add more header content here */}
                </div>

                {/* Body */}
                <div
                  className="scrollbarOfnotification"
                  style={{
                    overflowY: "auto",
                    marginTop: "12px",
                    height: "92%",
                  }}
                >
                  {/* Sample notification item */}

                  {notificationList?.map((notification: any, index) => (
                    <div
                      key={index}
                      className="headerNotification"
                      style={{
                        alignItems: "center",
                        minHeight: "58px",
                        padding: "8px 12px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleNotification(notification);
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "12px",
                          lineHeight: "10px",
                          fontWeight: 400,
                          fontFamily: "Inter",
                          color: "#7D899C",
                          marginBottom: "4px",
                        }}
                      >
                        <FiberManualRecordIcon
                          sx={{
                            marginRight: "4px",
                            fontSize: "4px",
                            color: notification?.flag_view
                              ? "#7D899C"
                              : "#F77108",
                          }}
                        />
                        {moment(notification?.createdAt).calendar()}
                      </Typography>
                      <Typography
                        style={{
                          color: "#ffffff",
                          fontSize: "14px",
                          fontFamily: "Inter",
                          overflowWrap: "break-word",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {notification.message_body}
                      </Typography>
                    </div>
                  ))}

                  {/* You can map through your notificationList here and render notification items */}
                </div>

                {/* Footer */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "36px",
                  }}
                >
                  <Typography
                    style={{
                      color: "#7C899C",
                      cursor: "pointer",
                      fontSize: "12px",
                    }}
                    onClick={() => {
                      setAnchorElNotification(null);
                      navigate("/notification");
                    }}
                  >
                    See all notifications
                  </Typography>
                </div>
              </div>
            </Popover>

            <Grid
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              gap={3}
              className="profile"
              style={{

              }}
            >
              {user?.picture ? (
                <img
                  src={user?.picture}
                  style={{
                    width: "40px",
                    height: "40px",
                    fontSize: "18px",
                    borderRadius: "100px",
                    cursor: "pointer",
                  }}
                  onClick={handleClick}
                />
              ) : (
                <Avatar
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "40px",
                    height: "40px",
                    padding: "8px",
                    fontSize: "18px",
                    borderRadius: "100px",
                    background: "var(--color-palette-gray-800, #253042)",
                    cursor: "pointer",
                  }}
                  onClick={handleClick}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        {openUserDetails && (
          <UserProfileModal open={openUserDetails} onClose={toggleUserModal} />
        )}
      </Grid>
    </>
  );
}

export default Header;
