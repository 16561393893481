import axios, { AxiosError, AxiosResponse, ResponseType } from "axios";
import { SEVERITY, TOKEN_SOURCE } from "./enums";
import { openToast } from "../components/Snackbar/Toasts";
import axiosRetry from "axios-retry";
import isUrl from "is-url-superb"
import ky from "ky-universal"
const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const axiosRetryClient = axios.create();
axiosRetry(axiosRetryClient, {
  retries: 5,
  retryDelay: (retryCount) => retryCount * 1000,
});

function successHandler (response: AxiosResponse) {
  const json = response.data;
  if (json.success) {
    return json.data;
  } else if (response.status === 200) {
    return response.data;
  } else {
    console.error("API error", json);
  }
}

function errorHandler (err: AxiosError | any) {
  console.log(err);
  if (err.response?.status === 401) {
    const tokenSource = window.localStorage.getItem("token_source");
    if (tokenSource && tokenSource === TOKEN_SOURCE.SESSION) {
      window.location.assign("/auth/expired");
    } else {
      window.location.assign("/auth/logout");
    }
  } else {
    console.error("API error", err.response?.data);
    openToast(String(err.response?.data?.error), SEVERITY.ERROR);
    throw err.response?.data?.error ?? err.response?.data ?? err.response ?? err;
  }
}

function getHeaders (payload?: any) {
  const headers: any = payload ?
    payload instanceof FormData ? { "Content-Type": "multipart/form-data" } : { "Content-Type": "application/json" }
    : {};

  const token = window.localStorage.getItem("token");
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  const brandId = window.localStorage.getItem("brand_id");
  if (brandId) {
    headers["x-brand-id"] = brandId;
  }

  return headers;
}

export const createBackendUrl = (url: string) => `${BASE_URL}api/${url}`;

export function getApi (url: string) {
  return axios.get(createBackendUrl(url), { headers: getHeaders() })
  .then(successHandler)
  .catch(errorHandler);
};



export function getApiWithoutCatch (url: string) {
  return axios.get(createBackendUrl(url), { headers: getHeaders() })
  .then(successHandler)
};

export function postApi (url: string, payload: any = {}, responseType?: ResponseType, retry?: boolean) {
  return (retry ? axiosRetryClient : axios).post(createBackendUrl(url), payload, { headers: getHeaders(payload), ...(responseType ? { responseType } : {})})
  .then(successHandler)
  .catch(errorHandler);
};
export function postApiForTest (url: string, payload: any = {}) {
  return axios.post(createBackendUrl(url), payload, { headers: getHeaders(payload) })
}

export function postFormApi (url: string, payload: any = {}, responseType?: ResponseType, retry?: boolean) {

  const formData = new FormData();
  for (const key in payload) {
    if (payload[key] instanceof Array) {
      for (const object of payload[key]) {
        formData.append(key, object);
      }
    } else {
      formData.append(key, payload[key]);
    }
  }

  return (retry ? axiosRetryClient : axios).post(createBackendUrl(url), formData, { headers: getHeaders(formData), ...(responseType ? { responseType } : {})})
  .then(successHandler)
  .catch(errorHandler);
};

export function putApi (url: string, payload: any) {
  return axios.put(createBackendUrl(url), payload, { headers: getHeaders(payload) })
  .then(successHandler)
  .catch(errorHandler);
};

export function deleteApi (url: string) {
  return axios.delete(createBackendUrl(url), { headers: getHeaders() })
  .then(successHandler)
  .catch(errorHandler);
}

export const urlExist = async (url: string) => {
	if (typeof url !== "string") {
		throw new TypeError(`Expected a string, got ${typeof url}`)
	}

	if (!isUrl(url)) {
		return false
	}

	const response = await ky.head(url, {
		throwHttpErrors: false,
    mode: 'no-cors'
	})

	return response !== undefined && (response.status < 400 || response.status >= 500)
}
