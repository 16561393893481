import React, { useRef } from "react";
import styles from "./index.module.css";
import { Skeleton } from "@mui/material";
import { useAppSelector } from "hooks/store";
import { AdDataPrompt, AdVariant } from "utils/types";

type Props = {
  ad: AdDataPrompt;
  variant?: AdVariant;
  onImageLoadCb: Function;
  index?: Number
}
const ImageAd = ({ ad, variant = "normal", onImageLoadCb = () => null, index }: Props) => {
  const output = useAppSelector(store => store.output.allOutputs?.[ad.data.outputId.data]);
  const imageRef = useRef<HTMLImageElement>(null);
  const skeletonRef = useRef<HTMLElement>(null);

  const onImageLoad = () => {
    const myImg = imageRef.current;
    if (!myImg) { return; }

    const realWidth = myImg.naturalWidth;
    const realHeight = myImg.naturalHeight;
    if (realWidth && realHeight) {
      myImg.style.aspectRatio = `${realWidth / realHeight}`;
    }

    if (skeletonRef.current) {
      skeletonRef.current.style.display = "none";
    }
    if (index === 0) {
      onImageLoadCb()
    }

  };

  return (
    <div id={`${ad.id}-img_root`} className={`${styles.imgAdRoot} ${variant} `}>
      <Skeleton
        ref={skeletonRef}
        animation="wave"
        variant="rectangular"
        className={`${styles.skeleton} ${variant}`}
      />
      <div className={`${styles.imageAdContainer} ${variant}`}>
        <div className={`${styles.productImgDiv} ${variant}`}>
          <img
            ref={imageRef}
            onLoad={onImageLoad}
            src={output?.url}
            alt="product_img"
            className={`${styles.productImg} ${variant}`}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(ImageAd);
