import React from "react";
import { Loader } from "../components/Loader";

const LoadingPage = () => {
  return (
    <div style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      display: "flex",
      gap: 20,
      justifyContent: "center",
      alignItems: "center",
      color: "#848484",
    }}>
      <h3>Loading...</h3>
      <Loader />
    </div>
  );
}

export default LoadingPage;
