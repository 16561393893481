import React from "react";
import { Checkbox, FormControlLabel, Select, Slider, TextField, styled } from "@mui/material";
import { TypographyBodySmallNormal } from "./Typography";


export const TextFieldStyled = styled(TextField)(() => {
  return {
    display: "flex",
    padding: "0",
    justifyContent: "space-between",
    alignItems: "center",
    border: "0",

    borderRadius: "8px",
    background: "var(--color-palette-gray-900, #1A202B)",
    ":hover": {
      background: "var(--color-palette-gray-700, #394455)",
    },
    ":disabled": {
      opacity: 0.5,
    },
    input: {
      height: "24px",
      padding: "10px 12px",
      fontFamily: "Inter",
      color: "#AEB6C4",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "22px",
      border: 0,
      caretColor: "white !important",
      ":-webkit-autofill": {
        WebkitBoxShadow: "0 0 0 30px #1A202B inset !important",
        WebkitTextFillColor: "#AEB6C4 !important",
        caretColor: "white !important",
      },
    },
    textarea: {
      fontFamily: "Inter",
      height:"45px !important",
      color: "#AEB6C4",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "22px",
      border: 0,
      caretColor: "white !important",
      ":-webkit-autofill": {
        WebkitBoxShadow: "0 0 0 30px #1A202B inset !important",
        WebkitTextFillColor: "#AEB6C4 !important",
        caretColor: "white !important",
      },
    },
    fieldset: {
      border: 0,
    },
  };
});


type TextAreaProps = {
  placeholder?: string,
  value: string,
  onChange: (newValue: string) => void,
  sx?: any,
};

export const TextAreaStyled = ({ placeholder, value, onChange, sx }: TextAreaProps) => <TextField
  placeholder={placeholder}
  multiline
  rows={3}
  sx={{
    width: "100%",
    "& .MuiInputBase-root": {
      fontSize: "14px !important",
      fontFamily: "Inter",
      fontWeight: "400 !important",
      lineHeight: "22px !important",
      color: "#AEB6C4",
    },
    "& .MuiInputBase-root .MuiInputBase-input": {
      padding: "0px !important",
    },
    ...(sx ?? {}),
  }}
  value={value}
  onChange={e => onChange(e.target.value)}
/>;


export const NumberTextFieldStyled = styled(TextField)(() => {
  return {
    display: "flex",
    padding: "0",
    justifyContent: "flex-start",
    alignItems: "center",
    border: "0",
    borderRadius: "8px",
    background: "var(--color-palette-gray-900, #1A202B)",
    ":hover": {
      background: "var(--color-palette-gray-700, #394455)",
    },
    ":disabled": {
      opacity: 0.5,
    },
    input: {
      height: "24px",
      padding: "10px 12px",
      fontFamily: "Inter",
      color: "#AEB6C4",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "22px",
      border: 0,
      caretColor: "white !important",
      ":-webkit-autofill": {
        WebkitBoxShadow: "0 0 0 30px #1A202B inset !important",
        WebkitTextFillColor: "#AEB6C4 !important",
        caretColor: "white !important",
      },
    },
    fieldset: {
      border: 0,
    },

    "& .MuiInputBase-root": {
      width: "100%",
    },

    "& input[type=number]::-webkit-outer-spin-button": {
      "WebkitAppearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "WebkitAppearance": "none",
      margin: 0,
    },
  };
});


export const SelectStyled = styled(Select)(() => {
  return {
    padding: "0px !important",
    background: "#1A202B",
    color: "#7D899C",
    svg: {
      fill: "#7D899C",
    },
    width: "100%",
    display: "flex",
    // ".MuiSelect-select": {
    //   padding: "10px 12px",
    // },
    fieldset: {
      border: 0,
    },
    "&.Mui-disabled": {
      color: "#7D899C",
      background: "#1A202B",
      opacity: 0.5,
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: "0px",
      borderWidth: "0px",
    },
    ".MuiOutlinedInput-root:focus": { border: 0 },
    ".MuiInputBase-input": {
      padding: "10px 12px !important",
      fontFamily: "Inter !important",
      fontWeight: "400 !important",
      fontSize: "14px !important",
      lineHeight: "22px !important",
      letterSpacing: "0.14px !important",
      color: "#AEB6C4",
    },
  };
});


export const SliderStyled = styled(Slider)(() => {
  return {
    color: "#E3E9EE",
    height: 4,
    paddingBottom: "8px",
    paddingTop: "0",
    "& .MuiSlider-thumb": {
      width: 8,
      height: 8,
      transition: "0.3s cubic-bezier(.47,1.64,.41,.8)",
      color: "#AEB6C4",
      boxShadow: "0 0 0 2px #394455",
    },

    ":hover": {
      "& .MuiSlider-thumb": {
        color: "#fff",
        boxShadow: `0 0 0 2px #AEB6C4`,
        stroke: "#AEB6C4",
      },
    },
    ":active": {
      color: "#0869FB",
      "& .MuiSlider-thumb": {
        color: "#0869FB",
        boxShadow: `0 0 0 2px #AEB6C4`,
        stroke: "#E0E3E5",
      },
    },
    "& .MuiSlider-rail": {
      color: "var(--color-palette-gray-600, #5C6674)"
    },
  };
});


type CheckboxProps = {
  label: string,
  checked: boolean,
  disabled?: boolean,
  onChange: (checked: boolean) => void,
};

export const CheckboxStyled = ({ label, checked, disabled, onChange }: CheckboxProps) =>
  <FormControlLabel sx={{ marginBottom: "0px", color: "white" }} disabled={disabled} control={<Checkbox size="small" sx={{color:'#AEB6C4'}} checked={checked} onChange={(_, checked) => onChange(checked)} />} label={<TypographyBodySmallNormal>{label}</TypographyBodySmallNormal>} />
