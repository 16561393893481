import React from "react";
import { NumberTextFieldStyled } from "./styledComponents/Input";


function CustomTextFieldStyled({ onKeyDown, circularKeyDown = false, ...otherProps }: { onKeyDown: any, circularKeyDown: boolean }) {

    return (
        <NumberTextFieldStyled
            {...otherProps}
            onKeyDown={(event) => {
                if (event.key === "ArrowUp") {
                    event.preventDefault();
                    const e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> = Object.assign({}, event);
                    if (circularKeyDown && e.target.max && e.target.min) {
                        e.target.value = +e.target.value === +e.target.max ? e.target.min : e.target.max ? Number(e.target.max) > (Number(e.target.value) + 1) ? (Number(e.target.value) + 1).toString() : e.target.max : (Number(e.target.value) + 1).toString()
                    } else {
                        e.target.value = e.target.max ? Number(e.target.max) > (Number(e.target.value) + 1) ? (Number(e.target.value) + 1).toString() : e.target.max : (Number(e.target.value) + 1).toString()
                    }
                    onKeyDown(e)
                }
                if (event.key === "ArrowDown") {
                    event.preventDefault();
                    const e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> = Object.assign({}, event);
                    if (circularKeyDown && e.target.max && e.target.min) {
                        e.target.value = +(e.target.value) === +e.target.min ? e.target.max : Number(e.target.min || "0") <= (Number(e.target.value) - 1) ? (Number(e.target.value) - 1).toString() : (e.target.min || "0")
                    } else {
                        e.target.value = Number(e.target.min || "0") <= (Number(e.target.value) - 1) ? (Number(e.target.value) - 1).toString() : (e.target.min || "0")
                    }
                    onKeyDown(e)
                }
            }}
        />
    )
}

export default React.memo(CustomTextFieldStyled);