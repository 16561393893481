import React from "react";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#253042',
        color: 'white',
        maxWidth: 220,
        fontSize: '0.75rem',
        border: '1px solid #253042',
        lineHeight: '1.25rem',
        fontWeight: 700,
        fontFamily: "inter",
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#253042',
        left: '10px !important',
        transform: "unset !important"
    }
}));

type StyledTooltipProps = {
    title: string,
    children: React.ReactElement[] | React.ReactElement;
    styles?: any,
    placement?: "bottom" | "left" | "right" | "top" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start"
}


function StyledTooltip({ title = "", children = <></>, placement = "bottom-start", styles = {} }: StyledTooltipProps) {
    return (
        <HtmlTooltip
            arrow
            placement={placement}
            title={title}
            sx={{
                ...styles
            }}
        >
            {children}
        </HtmlTooltip>
    )
}

export default React.memo(StyledTooltip);