import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { SEVERITY, USER_ROLE } from "utils/enums";
import { getApi, deleteApi, postApi } from "utils/fetchUtils";
import { Brand, CallbackModelType, CallbackType, Invite, Session, User, showSnackbarType } from "utils/types";
import { AppThunk } from "store";
import { getAllAssets } from "store/asset";
import { getVisibleOutputs } from "store/output";
import { getAllProjects } from "store/project";
import { getAllFolders } from "store/folder";
import { getAllTemplates } from "store/template";
import { getAllIssues } from "store/support";
import { getBrandProperties, getUserProperties } from "store/property";
import { getAllNotificationData } from "store/notification";

const initialState = {
  session: null as Session | null,
  brand: null as Brand | null,
  role: null as USER_ROLE | null,
  isMultiBrand: false as boolean,
  isSuperAdmin: false as boolean,
  brandUsers: null as { [id: string]: User } | null,
  invitedUsers: [] as Invite[],
  brands: null as Brand[] | null,
  currentBrandId: null as string | null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateSession: (state, action: PayloadAction<Session>) => {
      state.session = action.payload;
    },
    updateBrand: (state, action: PayloadAction<Brand>) => {
      state.brand = action.payload;
    },
    updateRole: (state, action: PayloadAction<USER_ROLE>) => {
      state.role = action.payload;
    },
    updateIsMultiBrand: (state, action: PayloadAction<boolean>) => {
      state.isMultiBrand = action.payload;
    },
    updateIsSuperAdmin: (state, action: PayloadAction<boolean>) => {
      state.isSuperAdmin = action.payload;
    },
    updateBrandUsers: (state, action: PayloadAction<User[]>) => {
      state.brandUsers = {};
      action.payload.forEach(user => {
        if (!state.brandUsers) { state.brandUsers = {}; }
        state.brandUsers[user.id] = user;
      })
    },
    updateInvitedUsers: (state, action: PayloadAction<Invite[]>) => {
      state.invitedUsers = action.payload;
    },
    updateBrands: (state, action: PayloadAction<Brand[]>) => {
      state.brands = action.payload;
    },
    updateBrandId: (state, action: PayloadAction<string | null>) => {
      state.currentBrandId = action.payload;
    },
  },
});

export default userSlice.reducer;

export const getSession = (callback?: CallbackModelType<Session>): AppThunk =>
  async dispatch => {
    const session: Session = await getApi("session");
    dispatch(userSlice.actions.updateSession(session));
    dispatch(userSlice.actions.updateRole(session.role));
    dispatch(userSlice.actions.updateIsMultiBrand(session.isMultiBrand));
    dispatch(userSlice.actions.updateIsSuperAdmin(session.isSuperAdmin));
    if (session.isMultiBrand && session.brandId.length === 0) {
      dispatch(getBrands());
    }
    callback?.(session);
  }

export const getBrands = (callback?: CallbackType): AppThunk =>
  async dispatch => {
    const brands: Brand[] = await getApi("brand");
    dispatch(userSlice.actions.updateBrands(brands));
    callback?.();
  }

export const selectBrand = (brandId: string | null): AppThunk =>
  dispatch => {
    if (brandId) {
      window.localStorage.setItem("brand_id", brandId);
    } else {
      window.localStorage.removeItem("brand_id");
    }
    dispatch(userSlice.actions.updateBrandId(brandId));
    dispatch(getSession((session) => {
      dispatch(getUserProperties());
      dispatch(getBrand());
      if (!session.isMultiBrand || brandId) {
        dispatch(getBrandUsers());
        dispatch(getAllIssues());
        dispatch(getBrandProperties());
      }
      dispatch(getInvitedUsers());
      dispatch(getAllAssets());
      dispatch(getVisibleOutputs());
      dispatch(getAllProjects());
      dispatch(getAllFolders());
      dispatch(getAllTemplates());
      dispatch(getAllNotificationData());
    }));
  }

export const getBrand = (callback?: CallbackType): AppThunk =>
  async (dispatch, getState) => {
    const brandId = getState().user.session?.brandId ?? "";
    if (brandId.length > 0) {
      const brand: Brand = await getApi(`brand/${brandId}`);
      dispatch(userSlice.actions.updateBrand(brand));
      callback?.();
    }
  }

export const getBrandUsers = (callback?: CallbackType): AppThunk =>
  async dispatch => {
    const users: User[] = await getApi("user/all");
    dispatch(userSlice.actions.updateBrandUsers(users));
    callback?.();
  }

export const updateUserRole = (userId: string, role: USER_ROLE, showSnackbar?: showSnackbarType, callback?: CallbackType, onUserChangeLevel?: Function): AppThunk =>
  async dispatch => {
    const cb = () => {
      showSnackbar?.("Updated user role.", SEVERITY.SUCCESS);
      onUserChangeLevel?.();
      callback?.();
    }

    await postApi(`user/${userId}`, { role });
    dispatch(getBrandUsers(cb));
  }

export const deleteUser = (id: string, email: string, showSnackbar?: showSnackbarType, callback?: CallbackType, onUserRemove?: Function): AppThunk =>
  async dispatch => {
    const cb = () => {
      showSnackbar?.("Removed user.", SEVERITY.SUCCESS);
      onUserRemove?.();
      callback?.();
    }

    await deleteApi(`user/${id}?email=${encodeURIComponent(email)}`);
    dispatch(getBrandUsers(cb));
  }

export const inviteUser = (name: string, email: string, role: USER_ROLE, showSnackbar?: showSnackbarType, callback?: CallbackType): AppThunk =>
  async dispatch => {
    const cb = () => {
      showSnackbar?.("Invited user.", SEVERITY.SUCCESS);
      callback?.();
    }

    await postApi("invite", { name, email, role });
    dispatch(getInvitedUsers(cb));
  }

export const getInvitedUsers = (callback?: CallbackType): AppThunk =>
  async dispatch => {
    const invitedUsers: Invite[] = await getApi("invite");
    dispatch(userSlice.actions.updateInvitedUsers(invitedUsers));
    callback?.();
  }

export const deleteInvite = (id: string, showSnackbar?: showSnackbarType, callback?: CallbackType): AppThunk =>
  async dispatch => {
    const cb = () => {
      showSnackbar?.("Cancelled user invite.", SEVERITY.SUCCESS);
      callback?.();
    }

    await deleteApi(`invite/${id}`);
    dispatch(getInvitedUsers(cb));
  }

