import { useAppDispatch, useAppSelector } from "hooks/store";
import React from "react";
import Joyride, { STATUS, Step } from "react-joyride";
import { createUserProperty } from "store/property";
import userGuide, { updateUserGuideStep } from "store/user-guide";
import { JOYRIDE_TYPE, USER_GUIDE_STEP } from "utils/enums";

const StyledJoyride = ({ steps = [], continuous = true, styles = {}, showProgress = false, name, handleJoyrideCallback, currentStep = [], nextStep, joyrideType, isEndCallback = true, disableScrolling = true, scrollOffset = 20 }: {
    continuous?: boolean
    styles?: Object
    showProgress?: boolean,
    name: string | USER_GUIDE_STEP,
    steps: Step[],
    currentStep?: USER_GUIDE_STEP[],
    nextStep?: USER_GUIDE_STEP,
    joyrideType?: JOYRIDE_TYPE,
    isEndCallback?: Boolean,
    handleJoyrideCallback?: Function,
    disableScrolling?: boolean,
    scrollOffset?: number
}) => {
    const dispatch = useAppDispatch();
   

    const JoyrideCallBack = (data: any) => {
        const { status, type } = data;

        if ([STATUS.FINISHED].includes(status) && type === "tour:end") {
           
            if (joyrideType === JOYRIDE_TYPE.INITIAL_USER_GUIDE && nextStep && isEndCallback) {
                dispatch(updateUserGuideStep(currentStep, nextStep));
            }
            if (joyrideType !== JOYRIDE_TYPE.INITIAL_USER_GUIDE) {
                dispatch(createUserProperty(name, "true"));
            }
        }
        handleJoyrideCallback && handleJoyrideCallback(data)
    };

    return (
        <Joyride
            steps={steps}
            scrollOffset={scrollOffset}
            disableScrolling={disableScrolling}
            hideBackButton={true}
            continuous={continuous}
            callback={JoyrideCallBack}
            hideCloseButton={true}
            showProgress={showProgress}
            disableCloseOnEsc
            styles={{

                options: {
                    arrowColor: "#8615EA",
                    backgroundColor: "#8615EA",
                    primaryColor: "#000",
                    textColor: "white",
                    zIndex: 1301,
                },
                buttonNext: {
                    backgroundColor: "white",
                    color: "black",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "20px",
                    display: joyrideType === JOYRIDE_TYPE.INITIAL_USER_GUIDE ? 'none' : "flex"
                },
                tooltipContent: {
                    color: "white",
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "22px",
                    letterSpacing: "0.14px",
                    padding: "10px 5px",
                    textAlign: "left",
                },
                tooltip: {
                    padding: '1px',
                    fontFamily: "Inter",
                    width: 'auto',
                    maxWidth: '320px',

                },
                tooltipFooter: {
                    marginTop: "0px",
                },
                ...styles,
            }}
        />
    );
}

export default React.memo(StyledJoyride);
