import React, { useRef } from "react";
import styles from "./index.module.css";
import { Skeleton, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LockIcon from "@mui/icons-material/Lock";
import { useAppSelector } from "hooks/store";
import { AdDataPrompt, AdVariant } from "utils/types";
import PixisLogo from "assets/PixisLogo.png";
import { ASSET_TYPE } from "utils/enums";

type Props = {
  ad: AdDataPrompt;
  variant?: AdVariant;
  id?: string;
};

const FacebookAd = ({ ad, variant = "normal" }: Props) => {
  const output = useAppSelector(store => store.output.allOutputs?.[ad?.data?.outputId?.data]);
  const propertyData = useAppSelector(store => store.property.brandProperties);
  const brand = useAppSelector(store => store.user.brand?.name ?? "Pixis");
  var brandName = ""
  if (propertyData && propertyData?.some(item => item.key === "name")) {
    let brandData = propertyData?.filter(item => item.key === "name");
    brandName = brandData[0]?.value;
  } else {
    brandName = brand;
  }
  const brandLogos = useAppSelector(store => (store.asset.brandkitAssets ?? [])
    .filter(a => a.type === ASSET_TYPE.LOGO)
    .sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
  );

  const imageRef = useRef<HTMLImageElement>(null);
  const skeletonRef = useRef<HTMLElement>(null);

  const onImageLoad = () => {
    const myImg = imageRef.current;
    if (!myImg) { return; }

    const realWidth = myImg.naturalWidth;
    const realHeight = myImg.naturalHeight;
    if (realWidth && realHeight) {
      myImg.style.aspectRatio = `${realWidth / realHeight}`;
    }

    if (skeletonRef.current) {
      skeletonRef.current.style.display = "none";
    }
  };

  return (
    <div
      id={`${ad.id}-img_root`}
      className={`${styles.imgAdRoot} ${variant}`}
      style={{ background: "white" }}
    >
      <Skeleton
        ref={skeletonRef}
        style={{ display: "flex" }}
        animation="wave"
        variant="rectangular"
        className={styles.skeleton}
      />
      <div className={`${styles.imageAdContainer} ${variant}`}>
        <div className={`${styles.imgAdHead} ${variant}`}>
          <div className={styles.imgAdTitle}>
            <div className={`${styles.row} ${variant}`}>
              <div className={`${styles.imgAdTitleImg} ${variant}`}>
                <img src={brandLogos.length > 0 ? brandLogos[0].url : PixisLogo} style={{ width: "70%", height: "70%" }} />
              </div>
              <div className={`${styles.imgAdTitleDiv} ${variant}`}>
                <Typography className={`${styles.title} ${variant}`}>
                  {brandName}
                </Typography>
                <Typography className={`${styles.subTitle} ${variant}`}>
                  Sponsored ·
                </Typography>
              </div>
            </div>
            {/* <MoreVertIcon className={`${styles.menuIcon} ${variant}`} /> */}
          </div>
          <div className={styles.descriptionFirstDiv}>
            <Typography className={`${styles.descriptionFirst}  ${variant}`}>
              {ad?.data?.primaryText?.data}
            </Typography>
            {/* {<LockIcon className={`${styles.lock} ${variant}`} />} */}
          </div>
        </div>
        <div className={`${styles.productImgDiv} ${variant}`}>
          <img
            ref={imageRef}
            onLoad={onImageLoad}
            src={output?.url}
            alt="product_img"
            className={`${styles.productImg} ${variant}`}
          />
        </div>
        <div className={`${styles.detailDiv} ${variant}`}>
          <div className={styles.detailSubDiv}>
            {/* <Typography className={`${styles.urlText} ${variant}`}>{"Brand url"}</Typography> */}
            <Typography className={`${styles.subTitleText} ${variant}`}>
              {ad?.data?.headlineText?.data}
            </Typography>
            <Typography className={`${styles.subTitleText2} ${variant}`}>
              {ad?.data?.descriptionText?.data}
            </Typography>
          </div>
          <div className={`${styles.learnMoreBtn} ${variant}`}>
            Learn More
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(FacebookAd);
