import { Action, ThunkAction, combineReducers, configureStore } from "@reduxjs/toolkit";

import userReducer from "store/user";
import assetReducer from "store/asset";
import outputReducer from "store/output";
import projectReducer from "store/project";
import taskReducer from "store/task";
import folderReducer from "store/folder";
import templateReducer from "store/template";
import supportReducer from "store/support";
import propertyReducer from "store/property";
import userGuideReducer from "store/user-guide";
import segmentationReducer from "store/segmentation";
import notificationSlices from 'store/notification'
import { USER_LOGOUT } from "utils/enums";
import cropReducer from "store/crop";
import { loadState } from "store/browser-storage";

const appReducer = combineReducers({
  user: userReducer,
  asset: assetReducer,
  output: outputReducer,
  project: projectReducer,
  task: taskReducer,
  folder: folderReducer,
  template: templateReducer,
  notification:notificationSlices,

  // new reducers
  crop: cropReducer,
  segmentation: segmentationReducer,
  support: supportReducer,
  property: propertyReducer,
  userGuide: userGuideReducer

});

const rootReducer = (state: any, action: any) => {
  if (action.type === USER_LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  preloadedState: loadState(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, unknown, Action>;
