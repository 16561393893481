import React from "react";
import { AdDataPrompt, AdVariant, Output } from "../utils/types";
import { enumToName } from "../utils/utils";
import { Skeleton } from "@mui/material";
import { PROJECT_TYPE } from "utils/enums";
import { cloneDeep } from "lodash";
import GetAdViewComponent from "components/Ad/getAdViewComponent";

const OutputView = ({ output, onLoad, showAsAd = false, useThumbnail = true, styles, variant = "small" }: { output?: Output, onLoad?: React.EventHandler<any>, useThumbnail?: boolean, styles?: any, showAsAd?: boolean, variant?: AdVariant }) => {
    if (!output) {
        return <Skeleton
            style={{ display: "flex" }}
            animation="wave"
            variant="rectangular"
        />;
    }

    if (showAsAd && output.metadata.prompt?.type === PROJECT_TYPE.FACEBOOK_AD) {
        const ad = cloneDeep(output.metadata) as AdDataPrompt;
        ad.data.outputId.data = output.id;

        return <GetAdViewComponent ad={ad} variant={variant} />
        //  getAdViewComponent(ad, variant);
    }

    if (useThumbnail && output.thumbnail) {
        if (output.thumbnail.mimeType.startsWith("video")) {
            return (<video controls loop preload="metadata" src={output.thumbnail.url} className={styles?.output_card__video} style={styles ? {} : { maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto" }} />);
        }
        if (output.thumbnail.mimeType.startsWith("image")) {
            return (<img onLoad={onLoad} src={output.thumbnail.url} alt={`${enumToName(output.type)} Output`} className={styles?.output_card__image} style={styles ? {} : { maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto" }} />);
        }
    }

    if (output.mimeType && output.mimeType.startsWith("video")) {
        return (<video controls loop preload="metadata" src={output.url} className={styles?.output_card__video} style={styles ? {} : { maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto" }} />);
    }
    if (output.mimeType && output.mimeType.startsWith("image")) {
        return (<img onLoad={onLoad} src={output.url} alt={`${enumToName(output.type)} Output`} className={styles?.output_card__image} style={styles ? {} : { maxWidth: "100%", maxHeight: "100%", width: "auto", height: "auto" }} />);
    }

    return (<h3 className={styles["font-title"]} title={`${enumToName(output.type)} Output`}>{`${enumToName(output.type)} Output`}</h3>);
}

export default OutputView;
