import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, useContext } from "react";
import maxPanel from "mixpanel-browser";
import { useAppSelector } from "hooks/store";
import { PROJECT_TYPE } from "utils/enums";

type EventsContextType = {
    onLogoClick: () => void,
    onLoginClick: () => void,
    onUserAccountClick: () => void,
    onLogout: () => void,
    onQuickLinkImageGenClick: () => void,
    onQuickLinkAdCopyClick: () => void,
    onQuickLinkBillboardClick: () => void,
    onAddNewFolderClick: () => void,
    onFolderCreated: (folderId: string) => void,
    onFolderDelete: (folderId: string) => void,
    onFoldersDelete: (folderIds: string[]) => void,
    onFolderCopyLink: (folderId: string) => void,
    onFolderOpen: (folderId: string) => void,
    onProjectCreated: () => void,
    onProjectDelete: (projectId: string) => void,
    onProjectsDelete: (projectIds: string[]) => void,
    onProjectCopyLink: (projectId: string) => void,
    onProjectOpen: (projectId: string, projectType: string) => void,
    onRenderDelete: (folderId: string, projectId: string, renderId: string) => void,
    onRendersDelete: (renderIds: string[]) => void,
    onRenderCopyLink: (folderId: string, projectId: string, renderId: string) => void,
    onRenderDownload: (folderId: string, projectId: string, renderId: string) => void,
    onRenderOpenNewTab: (folderId: string, projectId: string, renderId: string) => void,
    onRenderPreview: (folderId: string, projectId: string, renderId: string) => void,
    onRenderPreviewGrid: (folderId: string, projectId: string, renderId: string) => void,
    onRenderPreviewList: (folderId: string, projectId: string, renderId: string) => void,
    onHelpClick: () => void,
    onBrandAssetDelete: (assetIds: string[]) => void,
    onBrandAssetsDelete: (assetIds: string[]) => void,
    onBrandAssetDownload: () => void,
    onBrandAssetCopylink: () => void,
    onBrandAssetOpenNewTab: () => void,
    onBrandAssetEdit: () => void,
    onBrandAssetAdd: () => void,
    onBrandStyleAddLogo: () => void,
    onBrandStyleAddFont: () => void,
    onBrandStyleAddColor: () => void,
    onCreateAdCopyClick: () => void,
    onCreateImageEditorClick: () => void,
    onCreateImageGenClick: () => void,
    onCreateFbAddClick: () => void,
    onCreatePromptGenClick: (projectType: PROJECT_TYPE) => void,
    onCreatePromptCopy: (projectType: PROJECT_TYPE) => void,
    onCreatePromptBookmark: (projectType: PROJECT_TYPE) => void,
    onCreatePromptBookmarkRemove: (projectType: PROJECT_TYPE) => void,
    onCreatePromptBookmarkExport: (exportType: string, projectType: PROJECT_TYPE) => void,
    onImageGenSelectSecene: (projectType: PROJECT_TYPE) => void,
    onImageGenSelectProduct: (projectType: PROJECT_TYPE) => void,
    onImageGenProductUpload: (projectType: PROJECT_TYPE) => void,
    onImageGenGoToEditor: (projectType: PROJECT_TYPE) => void,
    onImageEdtrAddNewAspectRatio: (projectType: PROJECT_TYPE) => void,
    onImageEdtrCreateNewSet: (projectType: PROJECT_TYPE) => void,
    onImageEdtrUndoClick: (projectType: PROJECT_TYPE) => void,
    onImageEdtrRedoClick: (projectType: PROJECT_TYPE) => void,
    onImageEdtrZoomInClick: (projectType: PROJECT_TYPE) => void,
    onImageEdtrZoomOutClick: (projectType: PROJECT_TYPE) => void,
    onImageEdtrFitClick: (projectType: PROJECT_TYPE) => void,
    onImageEdtrMagicEraserClick: (projectType: PROJECT_TYPE) => void,
    onImageEdtrMagicSwaperClick: (projectType: PROJECT_TYPE) => void,
    onImageEdtrUpscalerClick: (projectType: PROJECT_TYPE) => void,
    onImageEdtrRemoveBGClick: (projectType: PROJECT_TYPE) => void,
    onImageEdtrSegmentClick: (projectType: PROJECT_TYPE) => void,
    onImageEdtrTextGenClick: (projectType: PROJECT_TYPE) => void,
    onImageEdtrElemntIconsClick: (projectType: PROJECT_TYPE) => void,
    onImageEdtrElementShapClick: (projectType: PROJECT_TYPE) => void,
    onImageEdtrLayerClick: (projectType: PROJECT_TYPE) => void,
    onImageEdtrInsterClick: (projectType: PROJECT_TYPE) => void,
    onImageEdtrTextHeading: (projectType: PROJECT_TYPE) => void,
    onImageEdtrTextSubHeading: (projectType: PROJECT_TYPE) => void,
    onImageEdtrBody: (projectType: PROJECT_TYPE) => void,
    onImageEdtrEmoji: (projectType: PROJECT_TYPE) => void,
    onTemplateClick: () => void,
    onTemplateDelete: () => void,
    onPSDClick: () => void,
    onContactUsClick: () => void,
    onContactUsPrevious: () => void,
    onContactUsNext: () => void,
    onFaQCategoryClick: (category: string) => void,
    onCreateJiraTicket: () => void,
    onImageEditorShadowDrop: (projectType: PROJECT_TYPE) => void,
    onImageEditorShadowGlow: (projectType: PROJECT_TYPE) => void,
    onImageEditorShadowNone: (projectType: PROJECT_TYPE) => void,
    onAddUserClick: () => void,
    onUserRemove: () => void,
    onUserChangeLevel: () => void,
    onProfileSave: () => void,
    onProfileResetPassword: () => void
}

const EventsContext = createContext({} as EventsContextType);

const EventsProvider = ({ children }: { children: React.ReactNode }) => {

    const { user } = useAuth0();
    const userRole = useAppSelector(store => store.user.role);

    const genericMaxPanel = (eventName: string, payload: object = {}, timestamp: boolean = true, accessLevel: boolean = true, userDetails = true) => {
        const data: any = { ...payload }
        if (timestamp) {
            data.timestamp = Date.now();
        }
        if (accessLevel) {
            data.user_access_level = userRole;
        }
        if (userDetails) {
            data.email_id = user?.email || "N/A";
            data.client_name = user?.name || "N/A";
        }
        try {
            maxPanel.track(eventName, {
                ...data,
            });
        } catch (error) {
            console.log("Unable to send mixpanel event");
        }
    }

    return (
        <EventsContext.Provider
            value={{
                onLogoClick: () => genericMaxPanel("ca_home_logo"),
                onLoginClick: () => genericMaxPanel("ca_login"),
                onUserAccountClick: () => genericMaxPanel("ca_user_account"),
                onLogout: () => genericMaxPanel("ca_logout"),
                onQuickLinkImageGenClick: () => genericMaxPanel("ca_QuickLink_GenImage"),
                onQuickLinkAdCopyClick: () => genericMaxPanel("ca_QuickLink_GenAdCopy"),
                onQuickLinkBillboardClick: () => genericMaxPanel("ca_QuickLink_billboard"),
                onAddNewFolderClick: () => genericMaxPanel("ca_AddNewFolder", {}, false),
                onFolderCreated: (folderId: string) => genericMaxPanel("ca_NewFolderCreated", { folder_id: folderId }),
                onFolderDelete: (folderId: string) => genericMaxPanel("ca_folder_delete", { folder_id: folderId }, false),
                onFoldersDelete: (folderIds: string[]) => genericMaxPanel("ca_folders_delete", { folder_ids: folderIds }, false),
                onFolderCopyLink: (folderId: string) => genericMaxPanel("ca_folder_CopyLink", { folder_id: folderId }, false),
                onFolderOpen: (folderId: string) => genericMaxPanel("ca_open_folder", { folder_id: folderId }, false),
                onProjectCreated: () => genericMaxPanel("ca_AddNewProject"),
                onProjectDelete: (projectId: string) => genericMaxPanel("ca_project_delete", { project_id: projectId }, false),
                onProjectsDelete: (projectIds: string[]) => genericMaxPanel("ca_projects_delete", { project_ids: projectIds }, false),
                onProjectCopyLink: (projectId: string) => genericMaxPanel("ca_project_CopyLink", { project_id: projectId }, false),
                onProjectOpen: (projectId: string, projectType: string) => genericMaxPanel("ca_open_project", { project_id: projectId, project_type: projectType }, false),
                onRenderDelete: (folderId: string, projectId: string, renderId: string) => genericMaxPanel("ca_render_delete", { folder_id: folderId, project_id: projectId, render_id: renderId }),
                onRendersDelete: (renderIds: string[]) => genericMaxPanel("ca_renders_delete", { render_ids: renderIds }),
                onRenderCopyLink: (folderId: string, projectId: string, renderId: string) => genericMaxPanel("ca_renders_CopyLink", { folder_id: folderId, project_id: projectId, render_id: renderId }, false),
                onRenderDownload: (folderId: string, projectId: string, renderId: string) => genericMaxPanel("ca_renders_download", { folder_id: folderId, project_id: projectId, render_id: renderId }, false),
                onRenderOpenNewTab: (folderId: string, projectId: string, renderId: string) => genericMaxPanel("ca_renders_OpenNewTab", { folder_id: folderId, project_id: projectId, render_id: renderId }, false),
                onRenderPreview: (folderId: string, projectId: string, renderId: string) => genericMaxPanel("ca_renders_preview", { folder_id: folderId, project_id: projectId, render_id: renderId }, false),
                onRenderPreviewGrid: (folderId: string, projectId: string, renderId: string) => genericMaxPanel("ca_renders_preview_GridView", { folder_id: folderId, project_id: projectId, render_id: renderId }, false),
                onRenderPreviewList: (folderId: string, projectId: string, renderId: string) => genericMaxPanel("ca_renders_preview_ListView", { folder_id: folderId, project_id: projectId, render_id: renderId }, false),
                onHelpClick: () => genericMaxPanel("ca_help"),
                onBrandAssetDelete: () => genericMaxPanel("ca_BrandAsset_delete"),
                onBrandAssetsDelete: (assetIds: string[]) => genericMaxPanel("ca_BrandAssets_delete", { asset_ids: assetIds }),
                onBrandAssetCopylink: () => genericMaxPanel("ca_BrandAsset_CopyLink"),
                onBrandAssetDownload: () => genericMaxPanel("ca_BrandAsset_download"),
                onBrandAssetAdd: () => genericMaxPanel("ca_AddAsset"),
                onBrandAssetEdit: () => genericMaxPanel("ca_BrandAsset_edit"),
                onBrandAssetOpenNewTab: () => genericMaxPanel("ca_BrandAsset_OpenNewTab"),
                onBrandStyleAddColor: () => genericMaxPanel("ca_AddColors"),
                onBrandStyleAddFont: () => genericMaxPanel("ca_AddFont"),
                onBrandStyleAddLogo: () => genericMaxPanel("ca_AddLogo"),
                onCreateAdCopyClick: () => genericMaxPanel("ca_create_AdCopy"),
                onCreateImageEditorClick: () => genericMaxPanel("ca_create_ImgEditor"),
                onCreateFbAddClick: () => genericMaxPanel("ca_create_FbAdGen"),
                onCreateImageGenClick: () => genericMaxPanel("ca_create_ImageGen"),
                onCreatePromptGenClick: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_prompt_generation", { project_type: projectType }),
                onCreatePromptBookmark: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_add_bookmark", { project_type: projectType }),
                onCreatePromptCopy: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_prompt_copy", { project_type: projectType }),
                onCreatePromptBookmarkExport: (exportType: string, projectType: PROJECT_TYPE) => genericMaxPanel("ca_bookmark_export", { project_type: projectType, type: exportType }),
                onCreatePromptBookmarkRemove: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_bookmark_remove", { project_type: projectType }),
                onImageGenGoToEditor: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImageGen_GoToEditor", { type: projectType, }),
                onImageGenProductUpload: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImageGen_ProductUpload", { type: projectType }),
                onImageGenSelectProduct: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImageGen_SelectProduct", { type: projectType }),
                onImageGenSelectSecene: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImageGen_SelectScene", { type: projectType }),
                onImageEdtrAddNewAspectRatio: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_AddNewAspectRatio", { type: projectType }, false, false, false),
                onImageEdtrCreateNewSet: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_CreateNewSet", { type: projectType }, false, false, false),
                onImageEdtrUndoClick: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_undo", { type: projectType }, false, false, false),
                onImageEdtrRedoClick: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_redo", { type: projectType, }, false, false, false),
                onImageEdtrZoomInClick: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_ZoomIn", { type: projectType }, false, false, false),
                onImageEdtrZoomOutClick: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_ZoomOut", { type: projectType }, false, false, false),
                onImageEdtrFitClick: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_fit", { type: projectType }, false, false, false),
                onImageEdtrMagicEraserClick: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_MagicEraser", { type: projectType, }, false, false, false),
                onImageEdtrMagicSwaperClick: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_MagicSwap", { type: projectType }, false, false, false),
                onImageEdtrSegmentClick: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_Segment", { type: projectType }, false, false, false),
                onImageEdtrUpscalerClick: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_Upscale", { type: projectType }, false, false, false),
                onImageEdtrRemoveBGClick: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_RemoveBG", { type: projectType }, false, false, false),
                onImageEdtrTextGenClick: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_TextGen", { type: projectType }, false, false, false),
                onImageEdtrElementShapClick: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_elements_shape", { type: projectType }, false, false, false),
                onImageEdtrElemntIconsClick: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_elements_icons", { type: projectType, }, false, false, false),
                onImageEdtrInsterClick: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_insert", { type: projectType }, false, false, false),
                onImageEdtrLayerClick: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_layer", { type: projectType }, false, false, false),
                onImageEdtrBody: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_text_body", { type: projectType }, false, false, false),
                onImageEdtrTextHeading: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_text_heading", { type: projectType, }, false, false, false),
                onImageEdtrTextSubHeading: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_text_subheading", { type: projectType }, false, false, false),
                onImageEdtrEmoji: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_text_emoji", { type: projectType }, false, false, false),
                onTemplateClick: () => genericMaxPanel("ca_Templates_clicked", {}, false, true, true),
                onTemplateDelete: () => genericMaxPanel("ca_Templates_delete", {}, false, true, true),
                onPSDClick: () => genericMaxPanel("ca_upload_PSD_clicked", {}, false, true, true),
                onContactUsClick: () => genericMaxPanel("ca_H&S_ContactUs", {}, false, true, true),
                onContactUsPrevious: () => genericMaxPanel("ca_H&S_PreviousVideo", {}, false, true, true),
                onContactUsNext: () => genericMaxPanel("ca_H&S_NextVideo", {}, false, true, true),
                onFaQCategoryClick: (category: string) => genericMaxPanel("ca_H&S_FAQClicked", { FAQ_Category: category }, false, true, true),
                onCreateJiraTicket: () => genericMaxPanel("ca_H&S_CreateTicket", {}, false, true, true),
                onImageEditorShadowDrop: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_Shadow_Drop", { type: projectType }, false, false, false),
                onImageEditorShadowGlow: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_Shadow_Glow", { type: projectType }, false, false, false),
                onImageEditorShadowNone: (projectType: PROJECT_TYPE) => genericMaxPanel("ca_ImgEditor_Shadow_None", { type: projectType }, false, false, false),
                onAddUserClick: () => genericMaxPanel("ca_AddUser_Clicked", {}, false, true, true),
                onUserRemove: () => genericMaxPanel("ca_remove_user", {}, false, true, true),
                onUserChangeLevel: () => genericMaxPanel("ca_ChangeUserLevel", {}, false, true, true),
                onProfileSave: () => genericMaxPanel("ca_Profile_Save", {}, false, true, true),
                onProfileResetPassword: () => genericMaxPanel("ca_Profile_ResetPassword", {}, false, true, true),
            }}
        >
            {children}
        </EventsContext.Provider>
    )
}
export default EventsProvider;

export const useEventsProvider = () => useContext(EventsContext);
