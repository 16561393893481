import { ASSET_TYPE, PROJECT_TYPE } from "utils/enums";
import { Asset, Output, Project } from "utils/types";

const KEY = "redux";

export function loadState () {
  try {
    const serializedState = window.localStorage.getItem(KEY);
    if (!serializedState) return undefined;

    const localState = JSON.parse(serializedState);

    const reduxState = convertStateLocalToRedux(localState);

    return reduxState;
  } catch (e) {
    // console.log(e);
    return undefined;
  }
}

export function saveState (state: any) {
  try {
    const localState = convertStateReduxToLocal(state);

    const serializedState = JSON.stringify(localState);
    window.localStorage.removeItem(KEY);
    window.localStorage.setItem(KEY, serializedState);
  } catch (e) {
    // console.log(e);
    // Ignore
  }
}

const preloadedState = {
  user: {
    session: null,
    brand: null,
    role: null,
    isMultiBrand: false,
    isSuperAdmin: false,
    brandUsers: null,
    invitedUsers: [],
    brands: null,
    currentBrandId: null,
  },
  asset: {
    allAssets: null,
    brandkitAssets: null,
    imageAssets: [],
    videoAssets: [],
    fontAssets: [],
    colorAssets: [],
    greenScreenAssets: null,
    productAssets: [],
    backgroundAssets: [],
    backgroundDisplayAssets: [],
  },
  output: {
    allOutputs: null,
    visibleOutputs: null,
    billboardOutputs: null,
    imageOutputs: null,
    otherOutputs: null,
  },
  project: {
    projects: null,
    sceneProjects: null,
    projectRequestId: {},
  },
  task: {
    allTasks: null,
  },
  folder: {
    allFolders: null,
  },
  template: {
    allTemplates: null,
  },
  crop: {
    cropEnabled: false,
    cropDone: false,
  },
  segmentation: {
    maskData: null,
  },
  property: {
    userProperties: null,
    brandProperties: null,
  },
  support: {
    supportIssues: null,
  }
};

const convertStateReduxToLocal = (state: any) => {
  const localState = JSON.parse(JSON.stringify(preloadedState));
  localState.user = JSON.parse(JSON.stringify(state.user));
  localState.asset.allAssets = { ...state.asset.allAssets };
  localState.output.visibleOutputs = { ...state.output.visibleOutputs };
  localState.project.projects = { ...state.project.projects };
  localState.folder.allFolders = { ...state.folder.allFolders };
  localState.template.allTemplates = { ...state.template.allTemplates };
  localState.property = JSON.parse(JSON.stringify(state.property));
  localState.support = JSON.parse(JSON.stringify(state.support));
  return localState;
};

const convertStateLocalToRedux = (localState: any) => {
  const reduxState = JSON.parse(JSON.stringify(localState));

  // populate assets
  const allAssets: Asset[] = localState.asset.allAssets ? Object.values(localState.asset.allAssets || {}) : [];

  reduxState.asset = {
    allAssets: localState.asset.allAssets,
    brandkitAssets: allAssets.filter(asset => asset.type === ASSET_TYPE.FONT || asset.type === ASSET_TYPE.COLOR || asset.type === ASSET_TYPE.LOGO),
    imageAssets: allAssets.filter(asset => asset.type === ASSET_TYPE.IMAGE),
    greenScreenAssets: allAssets.filter(asset => asset.type === ASSET_TYPE.GREEN_SCREEN),
    productAssets: allAssets.filter(asset => asset.type === ASSET_TYPE.PRODUCT),
    backgroundAssets: allAssets.filter(asset => asset.type === ASSET_TYPE.BACKGROUND),
    backgroundDisplayAssets: allAssets.filter(asset => asset.type === ASSET_TYPE.BACKGROUND_DISPLAY),
  };

  // populate outputs
  const visibleOutputs: Output[] = localState.output.visibleOutputs ? Object.values(localState.output.visibleOutputs) : [];

  reduxState.output.allOutputs = localState.output.visibleOutputs;
  reduxState.output.visibleOutputs = localState.output.visibleOutputs;

  reduxState.output.billboardOutputs = {};
  visibleOutputs.filter(output => output.type === PROJECT_TYPE.BILLBOARD).forEach(output => {
    if (!output.projectId) { return; }

    if (!reduxState.output.billboardOutputs[output.projectId]) reduxState.output.billboardOutputs[output.projectId] = [output];
    else reduxState.output.billboardOutputs[output.projectId].push(output);
  });

  reduxState.output.imageOutputs = {};
  visibleOutputs.filter(output => [PROJECT_TYPE.IMAGE, PROJECT_TYPE.IMAGE_SET, PROJECT_TYPE.FACEBOOK_AD, PROJECT_TYPE.IMAGE_GENERATION, PROJECT_TYPE.IMAGE_EDITOR].includes(output.type)).forEach(output => {
    if (!output.projectId) { return; }

    if (!reduxState.output.imageOutputs[output.projectId]) reduxState.output.imageOutputs[output.projectId] = [output];
    else reduxState.output.imageOutputs[output.projectId].push(output);
  });

  reduxState.output.otherOutputs = [];
  visibleOutputs.filter(output => !output.projectId).forEach(output => {
    reduxState.output.otherOutputs.push(output);
  });

  // populate projects
  const allProjects: Project[] = localState.project.projects ? Object.values(localState.project.projects || {}) : [];

  const sceneProjects = reduxState.project.sceneProjects ? reduxState.project.sceneProjects : {};

  allProjects.forEach(project => {
    if (project.mainProjectId) {
      if (sceneProjects[project.mainProjectId]) {
        sceneProjects[project.mainProjectId].push(project);
      } else {
        sceneProjects[project.mainProjectId] = [project];
      }
    }
  });

  reduxState.project = {
    projects: localState.project.projects,
    sceneProjects: sceneProjects,
    projectRequestId: {},
  };

  return reduxState;
};
