const ENABLE_CROP = 'ENABLE_CROP';
const CROP_DONE = 'CROP_DONE';
const CANCEL_CROP = 'CANCEL_CROP';
const RESET_CROP = 'RESET_CROP';

type EnableCropAction = {
  type: typeof ENABLE_CROP;
  payload: {
    cropEnabled: true;
  };
};

type CancelCropAction = {
  type: typeof CANCEL_CROP;
  payload: {
    cropEnabled: false;
  };
};

type CropDoneAction = {
  type: typeof CROP_DONE;
  payload: {
    cropDone: true;
  };
};

type ResetCropStateAction = {
  type: typeof RESET_CROP;
  payload: {
    cropEnabled: false;
    cropDone: false;
  };
};

type CropState = {
  cropEnabled: boolean;
  cropDone: boolean;
};

const initialState: CropState = {
  cropEnabled: false,
  cropDone: false,
};

const cropReducer = (
  state = initialState,
  action:
    | EnableCropAction
    | CancelCropAction
    | CropDoneAction
    | ResetCropStateAction
): CropState => {
  switch (action.type) {
    case ENABLE_CROP:
    case CANCEL_CROP:
    case CROP_DONE:
    case RESET_CROP:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default cropReducer;

export const enableCrop = () : EnableCropAction => ({
  type: ENABLE_CROP,
  payload: {
    cropEnabled: true,
  },
});

export const cancelCrop = () : CancelCropAction => ({
  type: CANCEL_CROP,
  payload: {
    cropEnabled: false,
  },
});

export const cropDone = () : CropDoneAction => ({
  type: CROP_DONE,
  payload: {
    cropDone: true,
  },
});

export const resetCrop = (): ResetCropStateAction => ({
  type: RESET_CROP,
  payload: {
    cropDone: false,
    cropEnabled: false,
  },
});
