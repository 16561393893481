import { DEFAULT_PROMPT } from "components/Prompt/utils";
import { ASSET_TYPE, INVITE_STATUS, OUTPUT_STATUS, PROJECT_TYPE, SCOPE, SESSION_TYPE, SEVERITY, TASK_STATUS, TASK_TYPE, USER_GUIDE_STEP, USER_ROLE } from "./enums";


///// AUTH /////


export type Session = {
  type: SESSION_TYPE,
  userId: string,
  brandId: string,
  role: USER_ROLE,
  permissions: string[],
  isMultiBrand: boolean,
  isSuperAdmin: boolean,
  issued: number,
  expires: number,
  data?: any,
  encryptedData?: string,
};

export type User = {
  id: string,
  brandId: string,
  email: string,
  name: string,
  role: USER_ROLE,
  roleId: string,
  userRoleId: number,
  createdAt: string,
  updatedAt: string,
};

export type Brand = {
  id: string,
  name: string,
  userCount: number,
  logo?: Asset,
  brandWebsite: string,
};

export type Invite = {
  id: string,
  name: string,
  email: string,
  role: USER_ROLE,
  brandId: string,
  invitedById: string,
  status: INVITE_STATUS,
  createdAt: string,
  updatedAt: string,
  expiresAt: string,
  deletedAt?: string,
};


///// MODEL /////


export type Project = {
  id: string,
  name: string,
  scope: SCOPE,
  type: PROJECT_TYPE,
  data: any,
  userId: string,
  brandId: string,
  taskId?: string,
  folderId?: string,
  mainProjectId?: string,
  outputId?: string,
  createdAt: string,
  updatedAt: string,
  deletedAt?: string,
};

export type Asset = {
  id: string,
  name: string,
  url: string,
  type: ASSET_TYPE,
  mimeType: string,
  scope: SCOPE,
  tags: string[],
  userId: string,
  brandId?: string,
  createdAt: string,
  updatedAt: string,
  deletedAt?: string,
  fileName: string,
  thumbnail?: {
    url: string,
    mimeType: string,
  } | null,
  metadata: {
    [key: string]: any,
  },
};

export type Output = {
  id: string,
  url: string,
  type: PROJECT_TYPE,
  mimeType: string,
  scope: SCOPE,
  status: OUTPUT_STATUS,
  userId: string,
  brandId: string,
  projectId?: string,
  createdAt: string,
  updatedAt: string,
  deletedAt?: string,
  key?: string,
  metadata: any,
  fileName: string,
  thumbnail: {
    mimeType: string,
    url: string,
  },
};

export type Task = {
  id: string,
  type: TASK_TYPE,
  status: TASK_STATUS,
  progress: number,
  data: any,
  encryptedData?: string,
  userId: string,
  brandId: string,
  projectId?: string,
  outputId?: string,
  createdAt: Date,
  updatedAt?: Date,
  completedAt?: Date,
};

export type Folder = {
  id: string,
  name: string,
  userId: string,
  brandId: string,
  createdAt: string,
  updatedAt: string,
  deletedAt?: string,
};

export type Template = {
  id: string,
  scope: SCOPE,
  type: PROJECT_TYPE,
  tags: string[],
  data?: any,
  metadata: any,
  userId: string,
  brandId: string,
  outputId: string,
  createdAt: string,
  updatedAt: string,
  deletedAt?: string,
};


///// CUSTOM /////


export type Position = { x: number, y: number };

export type Text = {
  position: Position,
  text: string,
  fontFamily: string,
  fontSize: number,
  bold: boolean,
  italic: boolean,
  underline: boolean,
  fontCase: string,
  color: string,
  opacity: number,
};

export const DEFAULT_TEXT: Text = {
  position: { x: 0, y: 0 },
  text: "Text",
  fontFamily: "Arial",
  fontSize: 24,
  bold: false,
  italic: false,
  underline: false,
  fontCase: "none",
  color: "#000000",
  opacity: 100,
};

export type AllAssets = { [id: string]: Asset };
export type AllOutputs = { [id: string]: Output };
export type AllFolders = { [id: string]: Folder };

export type showSnackbarType = (message: string, severity?: SEVERITY, duration?: number) => void;
export type CallbackType = () => void;
export type CallbackModelType<T> = (model: T) => void;


///// PROMPT /////


export type LockableString = {
  data: string,
  locked: boolean,
};

export type AdDataPrompt = {
  id: string,
  prompt: PromptInfo,
  data: {
    primaryText: LockableString,
    headlineText: LockableString,
    descriptionText: LockableString,
    outputId: LockableString,
  },
};

export type AdVariant = "small" | "large" | "normal" | "preview_small" | "grid_small" | "render" | "ex_small";

export const DEFAULT_AD: AdDataPrompt = {
  id: "",
  prompt: DEFAULT_PROMPT,
  data: {
    primaryText: { data: "", locked: false },
    headlineText: { data: "", locked: false },
    descriptionText: { data: "", locked: false },
    outputId: { data: "", locked: false },
  },
};

export type PromptAdvanced = {
  seed: number,
  dilation: {
    lower: number,
    upper: number,
  },
  depth: number,
  canny: number,
  normal: number,
};

export type PromptInfo = {
  raw: (string | number)[],
  texts: string[],
  backgroundAsset?: string,
  backgroundDisplayAsset?: string
  aiPrompt: string,
  type?: PROJECT_TYPE,
  product?: string,
  mood?: string,
  audience?: string,
  offer?: string,
  occasion?: string,
  useBrandInfo: boolean,
  extraPrompt: string,
  includeFomo: boolean,
  includeEmojis: boolean,
  includeQuestions: boolean,
  includeUSP: boolean,
  useBrandName: boolean,
  advanced: PromptAdvanced,
  productsInformation: ProductInformation[],
  multiDilation: { [id: string]: PromptAdvanced["dilation"] },
};

export type HistoryItem = {
  id: string,
  prompt: PromptInfo,
  ads: AdDataPrompt[],
};

export type SavedItem = {
  historyId: string,
  adId: string,
  projectId?: string,
}

export type HistoryList = HistoryItem[];

export type ProductPosition = {
  top: number,
  left: number,
  scaleX: number,
  scaleY: number,
  angle: number,
}

export type ProductInformation = {
  productId: string,
  canvasElementId: string,
  position?: ProductPosition,
  useInAdCopy: boolean,
}

///// IMAGE EDITOR /////

export type ExtendedCanvas = fabric.Canvas & {
  historyInit: () => void;
  undo: (callback?: any) => void;
  redo: () => void;
};

export type FabricGroupExtended = fabric.Group & {
  svgUid: number;
  id: string;
  layerType: string;
  isSvg?: boolean;
  exportedAsset: string;
  cropX?: number;
  cropY?: number;
  cropWidth?: number;
  cropHeight?: number;
  isBackground?: boolean;
};

export type ExtendedFabricCircle = fabric.Circle & {
  id: string;
  layerType: string;
};

export type ExtendedFabricRect = fabric.Rect & {
  id: string;
  layerType: string;
  isBackground: boolean;
};

export type ExtendedFabricImage = fabric.Image & {
  naturalWidth: number;
  naturalHeight: number;
  id: string;
  layerType: string;
  src: string;
  filters: any[];
  elements?: HTMLImageElement[];
  isBackground?: boolean;
  objectReplaced: fabric.Object | undefined;
  svgXML?: SVGSVGElement;
  isSvg: boolean;
  oldSrc?: string;
  formerWidth?: number;
  formerHeight?: number;
  formerCropX: number;
  formerCropY: number;
  pxWidth?: number;
  pxHeight?: number;
  blob: Blob;
  assetId?: string;
  assetType?: string;
  colorsMap?: {
    [k: string]: {
      hash: string;
      color: string;
      attribute: string;
      className?: string | boolean;
      index: number;
      stroke?: boolean;
      replaceSpace?: boolean;
    }[];
  };
};

export type ExtendedFabricTextbox = fabric.Textbox & {
  id: string;
  layerType: string;
  isCustomFont?: boolean;
  isEmoji?: boolean;
  name: string,
  editable: boolean;
  fontWeight: string | number;
  fontFamilyName: string;
  fontFamilyId: string;
  assetId?: string;
  assetType?: string;
};

export type ExtendedFabricPath = fabric.Path & {
  id: string;
  layerType: string;
  isSvg?: boolean;
  exportedAsset: string;
  cropX?: number;
  cropY?: number;
  cropWidth?: number;
  cropHeight?: number;
};

export type ExtendedFabricObject = fabric.Object & {
  id?: string;
  layerType?: string;
  layerLocked?: boolean;
  assetId?: string;
  assetType?: string;
  isBackground?: boolean
};

export type FilterProps = {
  reset: string;
};

export type ShapeProps = {
  x: number;
  y: number;
  subType: string;
  width: number;
  height: number | string;
  name?: string;
  id?: string;
  scaleX?: number;
  scaleY?: number;
  rotation?: number;
  color?: string;
  opacity?: number;
  key?: number;
  fill?: number;
  kind?: string;
  type?: string;
  visible?: boolean;
  tracking?: number;
  positionLocked?: false;
  flipX?: boolean;
  flipY?: boolean;
  offsetX?: number;
  offsetY?: number;
  exportedAsset?: string;
  children?: ShapeProps[];
  layerType?: string;
  isBackground?: boolean;
  stroke?: string;
  strokeWidth?: number;
  points?: { x: number, y: number }[];
  strokeUniform?: boolean;
};

export type TextProps = ShapeProps & {
  text: string;
  fontFamily: string;
  textAlign: string;
  fontSize: number;
  font: string;
  lineHeight: number;
  leading: number;
  padding: number;
  fauxBold?: boolean;
  allLocked: boolean;
  blendMode: string;
  direction: string;
  fontStyle?: "" | "normal" | "italic" | "oblique";
  pixelsLocked: boolean;
  letterSpacing: number;
  verticalScale: number;
  horizontalScale: number;
  fauxItalic?: boolean;
  underline?: boolean;
  strikethrough?: boolean;
  overline?: boolean
  capitalization: string;
  fontError?: string;
  isEmoji?: boolean;
  isCustomFont?: boolean;
  editable?: boolean;
  fontWeight?: number | string;
};

export type ImageProps = ShapeProps & {
  exportedAsset: string;
  isBackground?: boolean;
  filters?: any[];
  isSvg?: boolean;
  svgObj?: fabric.Group;
  flipX?: boolean;
  flipY?: boolean;
  cropX?: number;
  cropY?: number;
  naturalWidth?: number;
  naturalHeight?: number;
  widthInPixels?: number;
  heightInPixels?: number;
  cropWidth?: number;
  cropHeight?: number;
  stroke?: string;
  assetId?: string;
  assetType?: string;
  strokeWidth?: number;
};

export type SegmentMaskData = {
  image: string;
  maskId2maskPath: {
    [k: number]: string;
  };
  pixel2maskId: {
    [k: string]: number[];
  };
};

export type PropertyData = {
  id: string;
  modelType: string;
  modelId: string;
  key: string;
  value: string;
  createdAt: string;
  updatedAt: string;
}

export type MaskImage = {
  img: HTMLImageElement;
  id: string;
  path: string;
};

export type Issue = {
  id: string,
  key: string;
  type: string;
  status: string;
  description: string;
  summary: string;
  createdAt: string;
  attachments: [{
    mimeType: string;
    thumbnail: string;
    url: string;
  }];
};

export type Shape = {
  name: string;
  stroke: string;
  strokeWidth: number;
};

export type ExtendedShape = {
  name: string;
  stroke: string;
  strokeWidth: number;
  tags: string[];
  svg: string;
  fill: string;
};


export type UserGuide = {
  isNewUser: boolean;
  completedStep: USER_GUIDE_STEP[] | [],
  currentStep: USER_GUIDE_STEP | null
}