import React from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#394455",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "0.14px",
        color: "white",
        padding: '16px'
    },
}));

type InfoProps = {
    title: any,
    iconSize?: string,
    placement?: "bottom" | "left" | "right" | "top" | "bottom-end" | "bottom-start" | "left-end" | "left-start" | "right-end" | "right-start" | "top-end" | "top-start",
}

function Info({ title = "", iconSize = "20px", placement = "bottom-start" }: InfoProps) {
    return (
        <HtmlTooltip
            arrow
            placement={placement}
            title={title}
            sx={{
                "& .MuiTooltip-tooltip .MuiTooltip-arrow": {
                    color: "#394455",
                },
                whiteSpace: "pre-wrap",
            }}
        >
            <ErrorOutlineIcon sx={{
                color: "var(--color-palette-gray-400, #AEB6C4)",
                fontSize: iconSize,
                cursor: "pointer",
                transform: "rotate(180deg)",
            }} />
        </HtmlTooltip>
    );
}

export default React.memo(Info);
