import { PROJECT_TYPE } from "utils/enums";
import { PromptInfo } from "utils/types";
import { ProductPlacerImages } from "./ProductPlacer/utils";
import { capitalize } from "lodash";
import { DEFAULT_ADCOPY_PARAMS } from "api/ai";

export const DEFAULT_PROMPT: PromptInfo = {
    raw: [0],
    texts: [""],
    aiPrompt: "",
    useBrandInfo: DEFAULT_ADCOPY_PARAMS.useBrandInfo,
    extraPrompt: DEFAULT_ADCOPY_PARAMS.extraPrompt,
    includeFomo: DEFAULT_ADCOPY_PARAMS.includeFomo,
    includeEmojis: DEFAULT_ADCOPY_PARAMS.includeEmojis,
    includeQuestions: DEFAULT_ADCOPY_PARAMS.includeQuestions,
    includeUSP: DEFAULT_ADCOPY_PARAMS.includeUSP,
    useBrandName: DEFAULT_ADCOPY_PARAMS.useBrandName,
    advanced: {
        seed: 564645564,
        dilation: {
            lower: 10,
            upper: 20,
        },
        depth: 1,
        canny: 0.3,
        normal: 0,
    },
    productsInformation: [],
    multiDilation: {},
};

export type PromptOutput = {
    images?: ProductPlacerImages,
}

export enum PROMPT_STATE {
    "DISPLAY" = "DISPLAY",
    "INTERACTIVE" = "INTERACTIVE",
};

export type PromptInfoFormat = {
    type: "text" | "dropdown",
    values?: string[] | { [group: string]: string[] },
    required?: boolean,
    multiple?: boolean,
    backgroundColor?: string,
};

export const VALID_TYPES = [PROJECT_TYPE.FACEBOOK_AD, PROJECT_TYPE.IMAGE_GENERATION, PROJECT_TYPE.TEXT_GENERATION];

export const MOODS = ["cheerful", "sunny", "cheery", "jaunty", "energetic", "upbeat", "wholesome", "optimistic", "perky", "aspirational", "ecstatic",
    "happy", "nostalgia", "adventurous", "daring", "sharp", "sporty", "enterprising", "fearless", "brave", "brazen", "vintage", "bold", "ambitious",
    "assertive", "excited", "thrilled", "zealous", "playful", "goofy", "active", "cozy", "tranquil", "wacky", "confidence", "silly", "whimsical", "snug", "comfortable",
    "luxurious", "funky", "extravagance"];

export const OCCASIONS: { [occasion: string]: string[] } = {
    "Occasions": ["Birthdays", "Surprises", "Farewell", "Promotions", "Monthaversaries", "Engagements", "Weddings", "Grandparent's Day", "Friendship Day", "Super Bowl Sunday", "Groundhog Day", "Parent's Day", "National Childrens Day", "Citizenship Day", "Graduation", "Housewarming", "Pregnancy", "Bridal shower", "Bachelorette", "Bar Mitzvahs", "Quinceafieras", "Retirements"],
    "Festivals": ["Hannukah", "Thanksgiving", "Christmas", "StPatrics Day", "Easter", "Ed ALFitr", "Diwali",],
    "Seasons": ["Spring", "fall", "winter", "autumn", "pre-winter", "winter solstice"],
}

export const PROMPT_PARAMS_FORMAT: { [param: string]: PromptInfoFormat } = {
    selector: { type: "dropdown", values: ["type", "product", "mood", "audience", "offer", "occasion"] },

    texts: { type: "text" },
    type: { required: true, type: "dropdown", values: VALID_TYPES, backgroundColor: "#0869FB" },
    product: { type: "text", backgroundColor: "#0869FB", multiple: true },
    mood: { type: "dropdown", values: MOODS, backgroundColor: "#0869FB" },
    audience: { type: "text", backgroundColor: "#0869FB" },
    offer: { type: "text", backgroundColor: "#0869FB" },
    occasion: { type: "dropdown", values: OCCASIONS, backgroundColor: "#0869FB" },
};

export const SELECTOR_PROMPT_PARAM = "selector" as keyof PromptInfo;
export const SELECTOR_PROMPT_TEXT = "/";
export const MULTIPLE_SPLITTER = ",";

const DISPLAY_MAPPING: { [input: string]: string } = {
    [PROJECT_TYPE.TEXT_GENERATION]: "Ad Copies",
    [PROJECT_TYPE.IMAGE_GENERATION]: "Creatives",
    [PROJECT_TYPE.FACEBOOK_AD]: "Facebook Ad",
}

export const getDisplayString = (value: string) => DISPLAY_MAPPING[value] ?? capitalize(value);

export const TINT_MODE = ["color", "overlay", "multiply", "softlight", "hue"];
