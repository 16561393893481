import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { CallbackType, Issue, showSnackbarType } from "../utils/types";
import { getApi, postApi, postFormApi } from "utils/fetchUtils";
import { SEVERITY } from "utils/enums";

const initialState = {
  supportIssues: null as { [id: string]: Issue } | null,
}

export const supportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {
    updateIssues: (state, action: PayloadAction<Issue[]>) => {
      state.supportIssues = {};
      action.payload.forEach(issue => {
        if (!state.supportIssues) { state.supportIssues = {}; }
        state.supportIssues[issue.id] = issue;
      })
    },
  },
});

export default supportSlice.reducer;

export const getAllIssues = (callback?: CallbackType): AppThunk =>
  async dispatch => {
    const issues: Issue[] = await getApi("support/all");
    dispatch(supportSlice.actions.updateIssues(issues));
    callback?.();
  }

export const createIssue = (payload: { name: string, email: string, type: string, subject: string, description: string, file?: File }, showSnackbar?: showSnackbarType, callback?: CallbackType): AppThunk =>
  async dispatch => {
    const cb = () => {
      showSnackbar?.("Issue Created.", SEVERITY.SUCCESS);
      callback?.();
    }

    if (payload.file) {
      await postFormApi("support/create", payload);
    } else {
      await postApi("support/create", payload);
    }

    if (showSnackbar) {
      dispatch(getAllIssues(cb));
    } else {
      cb();
    }
  }

export const escalateIssue = (key: string, payload: { name: string, email: string }, showSnackbar?: showSnackbarType, callback?: CallbackType): AppThunk =>
  async () => {
    await postApi(`support/${key}/escalate`, payload);
    showSnackbar?.("Issue Escalated.", SEVERITY.SUCCESS);
    callback?.();
  }
