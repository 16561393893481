import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { postApi, getApi, deleteApi, postFormApi } from '../utils/fetchUtils';
import { Project, CallbackType, showSnackbarType, CallbackModelType } from '../utils/types';
import { SEVERITY } from '../utils/enums';
import { AppThunk, store } from '../store';
import { outputSlice } from "./output";
import { getUniqueId } from "../utils/utils";

const initialState = {
  projects: null as { [id: string]: Project } | null,
  sceneProjects: null as { [id: string]: Project[] } | null,
  projectRequestId: {} as { [id: string]: string },
};

export const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    updateProjects: (state, action: PayloadAction<Project[]>) => {
      const updatedProjects: { [id: string]: Project } = {};
      action.payload.forEach(project => {
        updatedProjects[project.id] = { ...(state.projects?.[project.id] ?? {}), ...project };
      });
      state.projects = { ...updatedProjects };
    },

    updateSceneProjects: (state, action: PayloadAction<Project[]>) => {
      state.sceneProjects = {};
      action.payload.forEach(project => {
        if (project.mainProjectId) {
          if (!state.sceneProjects) { state.sceneProjects = {}; }

          if (state.sceneProjects[project.mainProjectId]) {
            state.sceneProjects[project.mainProjectId].push(project);
          } else {
            state.sceneProjects[project.mainProjectId] = [project];
          }
        }
      });
    },

    deleteProjectAction: (state, action: PayloadAction<string>) => {
      if (state.projects) {
        const project = state.projects[action.payload];
        if (project?.mainProjectId && state.sceneProjects?.[project.mainProjectId]) {
          state.sceneProjects[project.mainProjectId] = state.sceneProjects[project.mainProjectId].filter((prj) => prj.id !== project.id);
        }
        if (project?.id && state.sceneProjects?.[project.id]) {
          delete state.sceneProjects[project.id];
        }
        delete state.projects[action.payload];
      }
    },

    updateProjectsById: (state, action: PayloadAction<Project>) => {
      if (!state.projects) { state.projects = {}; }
      state.projects[action.payload.id] = { ...(state.projects[action.payload.id] ?? {}), ...action.payload };

      if (!state.sceneProjects) { state.sceneProjects = {}; }
      if (action.payload.mainProjectId) {
        if (state.sceneProjects[action.payload.mainProjectId]) {
          state.sceneProjects[action.payload.mainProjectId] = state.sceneProjects[action.payload.mainProjectId].filter((proj) => proj.id !== action.payload.id)
          state.sceneProjects[action.payload.mainProjectId].push(action.payload);
        } else {
          state.sceneProjects[action.payload.mainProjectId] = [action.payload];
        }
        state.sceneProjects[action.payload.mainProjectId] = state.sceneProjects[action.payload.mainProjectId].sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);
      }
    },

    updateProjectsData: (state, action: PayloadAction<{ id: string, data: any }>) => {
      if (!state.projects) { state.projects = {}; }
      const project = (state.projects[action.payload.id] ?? { data: {} });
      state.projects[action.payload.id] = { ...project, data: { ...project.data, ...action.payload.data } };
    },

    updateProjectsWithoutData: (state, action: PayloadAction<Project>) => {
      if (!state.projects) { state.projects = {}; }
      const project = (state.projects[action.payload.id] ?? { data: {} });
      state.projects[action.payload.id] = { ...project, ...action.payload, data: { ...action.payload.data, ...project.data } };
    },

    setUpdateRequestId: (state, action: PayloadAction<{ projectId: string, requestId: string }>) => {
      state.projectRequestId[action.payload.projectId] = action.payload.requestId;
    },

  },
});

export default projectSlice.reducer;

export const createProject = (payload: any, showSnackbar?: showSnackbarType, callback?: CallbackModelType<Project>): AppThunk =>
  async dispatch => {
    const cb = (project: Project) => {
      showSnackbar?.("Project Created.", SEVERITY.SUCCESS);
      callback?.(project);
    }

    const project: Project = await postApi("project", payload);
    dispatch(projectSlice.actions.updateProjectsById(project));
    if (showSnackbar) {
      dispatch(getAllProjects(() => cb(project)));
    } else {
      cb(project);
    }
  }

export const createProjectFromPsd = (payload: any, showSnackbar?: showSnackbarType, callback?: CallbackModelType<Project>): AppThunk =>
  async dispatch => {
    const cb = (project: Project) => {
      showSnackbar?.("Project Created.", SEVERITY.SUCCESS);
      callback?.(project);
    }

    const project: Project = await postFormApi("project/create-from-file", payload);
    dispatch(projectSlice.actions.updateProjectsById(project));
    if (showSnackbar) {
      dispatch(getAllProjects(() => cb(project)));
    } else {
      cb(project);
    }
  }

export const getAllProjects = (callback?: CallbackType): AppThunk =>
  async dispatch => {
    const projects: Project[] = await getApi("project");
    const sortedProjects = projects.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);
    dispatch(projectSlice.actions.updateProjects(sortedProjects));
    dispatch(projectSlice.actions.updateSceneProjects(sortedProjects));
    callback?.();
  }

export const getProjectById = (id: string, callback?: CallbackModelType<Project>): AppThunk =>
  async dispatch => {
    const project: Project = await getApi(`project/${id}`);
    dispatch(projectSlice.actions.updateProjectsById(project));
    callback?.(project);
  }

export const updateProjectById = (id: string, data: any, showSnackbar?: showSnackbarType, waitForMore: boolean = true): AppThunk =>
  async (dispatch, getState) => {
    dispatch(projectSlice.actions.updateProjectsData({ id, data }));

    if (!showSnackbar && waitForMore) {
      const requestId = getUniqueId();
      dispatch(projectSlice.actions.setUpdateRequestId({ projectId: id, requestId }));

      await new Promise(resolve => setTimeout(resolve, 500));

      const savedRequestId = getState().project.projectRequestId[id];
      if (requestId !== savedRequestId) {
        return;
      }
    }

    const project: Project = await postApi(`project/${id}`, data);
    dispatch(projectSlice.actions.updateProjectsWithoutData(project));
    showSnackbar?.("Project updated.", SEVERITY.SUCCESS);
  }

export const deleteProjectById = (id: string, showSnackbar?: showSnackbarType, callback?: CallbackType, recursive?: boolean): AppThunk =>
  async dispatch => {
    const cb = () => {
      showSnackbar?.("Project Deleted.", SEVERITY.SUCCESS);
      callback?.();
    }

    if (!recursive) await deleteApi(`project/${id}`);

    const projects = Object.values(store.getState().project.projects ?? {});
    const outputs = Object.values(store.getState().output.allOutputs ?? {});

    for (const project of projects.filter(p => p.mainProjectId === id)) {
      dispatch(deleteProjectById(project.id, undefined, undefined, true));
    }

    for (const output of outputs.filter(o => o.projectId === id)) {
      dispatch(outputSlice.actions.removeOutput(output.id));
    }

    dispatch(projectSlice.actions.deleteProjectAction(id));

    if (!recursive) cb();
  }

export const deleteProjectsById = (ids: string[], showSnackbar?: showSnackbarType, callback?: CallbackType): AppThunk =>
  async dispatch => {
    const cb = () => {
      showSnackbar?.("Project Deleted.", SEVERITY.SUCCESS);
      callback?.();
    }

    for (const id of ids) {
      dispatch(deleteProjectById(id));
    }

    cb();
  }
