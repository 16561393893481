import { Box, Chip, styled } from "@mui/material";


export const StyledChip = styled(Box)(() => {
  return {
    padding: "4px 12px",
    gap: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "1000px",
    cursor: "pointer",

    color: "var(--color-palette-white, #FFF)",
    textAlign: "center",

    /* Body/Default/Normal */
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "22px",
    letterSpacing: "0.14px",

    // fontFamily: "Inter",
    // fontSize: "12px",
    // fontStyle: "normal",
    // fontWeight: "400",
    // lineHeight: "14px",
    // letterSpacing: "0.14px",
  };
});


// export const StyledChip = styled(Chip)(() => {
//   return {
//     p: "4px",
//     color: "var(--color-palette-white, #FFF)",
//     textAlign: "center",

//     /* Body/Default/Normal */
//     fontFamily: "Inter",
//     fontSize: "14px",
//     fontStyle: "normal",
//     fontWeight: "400",
//     lineHeight: "22px",
//     letterSpacing: "0.14px",

//     // fontFamily: "Inter",
//     // fontSize: "12px",
//     // fontStyle: "normal",
//     // fontWeight: "400",
//     // lineHeight: "14px",
//     // letterSpacing: "0.14px",

//   };
// });
