import React from "react";
import { SliderStyled } from "components/styledComponents/Input";
import { Box, Grid, Typography, capitalize } from "@mui/material";
import { PromptAdvanceOptionsProps } from "./utils";
import { PromptInfo } from "utils/types";
import styles from "./PromptAdvanceOptions.module.css";
import Info from "components/styledComponents/Info";

const Dilation = ({ advanced, onUpdate }: PromptAdvanceOptionsProps) => {
  const min = 0,
    max = 40,
    step = 1;

  return (
    <>
      <Grid
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        gap={1}
        width={"100%"}
        flexWrap={"wrap"}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Grid
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={0.5}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                color: "#AEB6C4",
                fontSize: "11px",
                fontStyle: "normal",
                fontWeight: 500,
              }}
            >
              Dilation
            </Typography>
            <Info placement="right" iconSize="16px" title={`Enhances clarity and visual prominence in your creations.`} />
          </Grid>
          <Typography
            sx={{
              fontFamily: "Inter",
              color: "#fff",
              fontSize: "11px",
              fontStyle: "normal",
              fontWeight: 500,
            }}
          >
            {advanced.dilation.lower}-{advanced.dilation.upper}
          </Typography>
        </Box>
        <SliderStyled
          size="small"
          value={Object.values(advanced.dilation)}
          min={min}
          step={step}
          max={max}
          onChange={(e) => {
            const values = e.target?.value as number[];
            onUpdate("dilation", { lower: values[0], upper: values[1] });
          }}
        />
      </Grid>
    </>
  );
};

export default Dilation;

export type PromptMultiDilationProps = {
  multiDilation: PromptInfo["multiDilation"];
  onUpdate: (param: string, value: any) => void;
};

export const MultiDilation = ({
  multiDilation,
  onUpdate,
  prompt,
}: PromptMultiDilationProps & { prompt: PromptInfo }) => {
  const min = 0,
    max = 40,
    step = 1;

  return (
    <>
      {Object.keys(multiDilation).map((key, index) => {
        const value = multiDilation[key];
        const displayTag = prompt.product?.split(",")[index]?.trim();
        return (
          <div key={index} style={{ width: "175px" }} className={styles.prompt_adv_opt_subMain}>
            <Grid
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-start"}
              gap={1}
              width={"100%"}
              flexWrap={"wrap"}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Grid
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  gap={0.5}

                >
                  <Typography
                    sx={{
                      fontFamily: "Inter",
                      color: "#AEB6C4",
                      fontSize: "11px",
                      fontStyle: "normal",
                      fontWeight: 500,
                    }}
                  >
                    {displayTag ? `${capitalize(displayTag)} Dilation` : "Dilation"}
                  </Typography>
                  <Info placement="right" iconSize="16px" title={`Enhances how the product interacts with the creation (low value to preserve details, large value to remove artifacts surrounding the product)`} />
                </Grid>
                <Typography
                  sx={{
                    fontFamily: "Inter",
                    color: "#fff",
                    fontSize: "11px",
                    fontStyle: "normal",
                    fontWeight: 500,
                  }}
                >
                  {value.lower}-{value.upper}
                </Typography>
              </Box>
              <SliderStyled
                size="small"
                value={Object.values(value)}
                min={min}
                step={step}
                max={max}
                onChange={(e) => {
                  const values = e.target?.value as number[];
                  onUpdate("multiDilation", {
                    ...multiDilation,
                    [key]: { lower: values[0], upper: values[1] },
                  });
                }}
              />
            </Grid>
          </div>
        );
      })}
    </>
  );
};
