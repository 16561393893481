import React from "react";
import { Grid, capitalize } from "@mui/material";
import { PromptInfo } from "utils/types";
import { CheckboxStyled, TextAreaStyled } from "components/styledComponents/Input";

type Props = {
  prompt: PromptInfo,
  onUpdate: (param: string, value: any) => void,
}

const AdCopyParams = ({ prompt, onUpdate }: Props) => {
  return (<>
    <Grid
      display={"flex"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      gap={3}
      width={"100%"}
      flexWrap={"wrap"}
    >
      {prompt.productsInformation.map((info, index) => {
        const displayTag = prompt.product?.split(",")[index]?.trim();
        if (!displayTag) { return <></>; }

        return <CheckboxStyled
          key={index}
          label={capitalize(displayTag)}
          checked={info.useInAdCopy}
          onChange={checked => onUpdate("productsInformation", [
            ...prompt.productsInformation.slice(0, index),
            { ...info, useInAdCopy: checked },
            ...prompt.productsInformation.slice(index + 1),
          ])}
        />;
      })}
      <CheckboxStyled label="Use Brand Details" checked={prompt.useBrandInfo} onChange={checked => onUpdate("useBrandInfo", checked)} />
      <CheckboxStyled label="Use Brand Name" checked={prompt.useBrandName} onChange={checked => onUpdate("useBrandName", checked)} />
      <CheckboxStyled label="FOMO" checked={prompt.includeFomo} onChange={checked => onUpdate("includeFomo", checked)} />
      <CheckboxStyled label="Include Emoji" checked={prompt.includeEmojis} onChange={checked => onUpdate("includeEmojis", checked)} />
      <CheckboxStyled label="Include Questions" checked={prompt.includeQuestions} onChange={checked => onUpdate("includeQuestions", checked)} />
      <CheckboxStyled label="USP Focus" checked={prompt.includeUSP} onChange={checked => onUpdate("includeUSP", checked)} />
    </Grid>
    <TextAreaStyled
      placeholder="Extra prompt..."
      value={prompt.extraPrompt ?? ""}
      onChange={value => onUpdate("extraPrompt", value)}
    />
  </>);
};

export default AdCopyParams;
