import { PromptInfo, Output, Task } from "utils/types";
import { postApi, postFormApi } from "utils/fetchUtils";

export type AdCopyParams = {
    extraPrompt: string,
    includeFomo: boolean,
    includeEmojis: boolean,
    includeQuestions: boolean,
    useBrandInfo: boolean,
    offer: string,
    mood: string,
    occasion: string,
    audience: string,
    includeUSP: boolean, // TODO: remove
    useBrandName: boolean, // TODO: remove
};

export const DEFAULT_ADCOPY_PARAMS: AdCopyParams = {
    extraPrompt: "",
    includeFomo: false,
    includeEmojis: false,
    includeQuestions: false,
    useBrandInfo: true,
    offer: "",
    mood: "",
    occasion: "",
    audience: "",
    useBrandName: true,
    includeUSP: true,
};

export type AdCopyResponseItem = {
    primaryText: string,
    headlineText: string,
    descriptionText: string,
};

export type AdCopyResponse = AdCopyResponseItem[];

function getAdCopyParamsFromPrompt (prompt: PromptInfo) {
    const gptParams = JSON.parse(JSON.stringify(DEFAULT_ADCOPY_PARAMS));

    if (prompt.mood) { gptParams.mood = prompt.mood; }
    if (prompt.audience) { gptParams.audience = prompt.audience; }
    if (prompt.offer) { gptParams.offer = prompt.offer; }
    if (prompt.occasion) { gptParams.occasion = prompt.occasion; }

    gptParams.useBrandInfo = prompt.useBrandInfo;
    gptParams.extraPrompt = prompt.extraPrompt;
    gptParams.includeFomo = prompt.includeFomo;
    gptParams.includeEmojis = prompt.includeEmojis;
    gptParams.includeQuestions = prompt.includeQuestions;
    gptParams.includeUSP = prompt.includeUSP;
    gptParams.useBrandName = prompt.useBrandName;

    return gptParams;
}

export const generateAdCopy = (prompt: PromptInfo): Promise<AdCopyResponse> =>
    postApi("ai/ad-copy", {
        ...getAdCopyParamsFromPrompt(prompt),
        productIds: prompt.productsInformation.filter(info => info.useInAdCopy).map(info => info.productId),
    });

export const creativeDiffusion = (
    productFile: Blob,
    maskFile: Blob[],
    compositeFile: Blob,
    prompt: string,
    depthWeightage: number,
    cannyWeightage: number,
    normalWeightage: number,
    multiDilation: string,
    seed: number,
    number: number,
    projectId?: string,
): Promise<Task> => postFormApi("ai/creative-diffusion", {
    productFile,
    maskFile,
    compositeFile,
    prompt,
    depthWeightage,
    cannyWeightage,
    normalWeightage: normalWeightage ?? 1,
    multiDilation,
    seed,
    number,
    ...(projectId ? { project_id: projectId } : {}),
});

export const magicEraser = (productFile: Blob, maskFile: Blob, projectId: string): Promise<Output> => postFormApi("ai/magic-eraser", { productFile, maskFile, project_id: projectId }, undefined, true);

export const magicPen = (productFile: Blob, maskFile: Blob, prompt: string, projectId: string): Promise<Output> => postFormApi("ai/magic-pen", { productFile, maskFile, prompt, project_id: projectId }, undefined, true);

export const combineSegment = (maskArray: string[]): Promise<string> => postApi("ai/add-masks", { masks: maskArray }, undefined, true);

export const blendSegment = (file: Blob, mask: Blob, color: string, opacity: number, mode: string, projectId: string): Promise<Output> => postFormApi("ai/blend", { file, mask, color, opacity, mode, project_id: projectId }, undefined, true);

export const imageUpscale = (file: Blob): Promise<string> => postFormApi("ai/image-upscale", { file }, "blob", true);

export const removeBackground = (url: string): Promise<string> => postApi("ai/background-removal", { url }, "blob", true);

export const generateBackground = (input: string, model: string): Promise<any> => postApi("ai/background-generation", { input, model }, undefined, true);
