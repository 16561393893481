import React from "react";
import { STATUS, Step } from "react-joyride";
import StyledJoyride from "components/Joyride";
import { JOYRIDE_TYPE, USER_GUIDE_STEP } from "utils/enums";
import { useAppDispatch } from "hooks/store";
import { createUserProperty } from "store/property";

const AssetsDetailJoyride = () => {


    const dispatch = useAppDispatch();
    const steps: Step[] = [
        {
            target: "#logos",
            disableBeacon: true,
            placement: "center",

            content: "Help AI create custom images and text by sharing brand Logos, Colors, Fonts, Brand Guidelines and Brand Website",
            locale: {
                last: 'Close'
            }
        },
    ];

    const handleJoyrideCallback = (data: any) => {
        const { status, type } = data;

        if ([STATUS.FINISHED].includes(status) && type === "tour:end") {
            dispatch(createUserProperty(USER_GUIDE_STEP.ASSETS_DETAILS, "true"));
        }
    }

    return (
        <StyledJoyride
            steps={steps}
            name={USER_GUIDE_STEP.ASSETS_DETAILS}
            joyrideType={JOYRIDE_TYPE.INITIAL_USER_GUIDE}
            currentStep={[USER_GUIDE_STEP.ASSETS_DETAILS]}
            nextStep={USER_GUIDE_STEP.END}
            handleJoyrideCallback={handleJoyrideCallback}
            styles={{
                buttonNext: {
                    backgroundColor: "white",
                    color: "black",
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "20px",
                    display: "flex",
                    margin: '4px'
                },
            }}
        />
    );
}

export default React.memo(AssetsDetailJoyride);
