import { store } from "store";
import { SESSION_TYPE } from "./enums";
import { DEFAULT_TEXT, Session } from "./types";
import { enumToName } from "./utils";

export function getProjectDataForSession (session?: Session | null) {
    const projectData: any = {};

    if (!session || !session.data) { return projectData; }

    const cta = session.data.headline && session.data.headline.length > 0 ? session.data.headline
        : session.data.cta && session.data.cta.length > 0 ? enumToName(session.data.cta)
            : "Buy now!";
    const offer = session.data.description && session.data.description.length > 0 ? session.data.description : "Huge discounts available!";

    projectData.creativeImage = session.data.creative_image;
    projectData.ctaText = {
        ...DEFAULT_TEXT,
        text: cta,
    };
    projectData.offerText = {
        ...DEFAULT_TEXT,
        text: offer,
    };

    return projectData;
}

export const isSessionRestricted = (session?: Session | null) => !session || session.type === SESSION_TYPE.EXTERNAL;

export const hasRolePermission = (resourceName: string, permission: string) => {
    const permissions = store.getState().user.session?.permissions ?? [];
    const permissionString = `ca:${resourceName}:${permission}:view`;
    return permissions.some(perm => perm === permissionString);
}
