import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getApi } from '../utils/fetchUtils';
import { Task } from '../utils/types';
import { AppThunk } from '../store';

const initialState = {
  allTasks: null as { [id: string]: Task } | null,
};

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    updateAllTasks: (state, action: PayloadAction<Task[]>) => {
      state.allTasks = {};
      action.payload.forEach(task => {
        if (!state.allTasks) { state.allTasks = {}; }
        state.allTasks[task.id] = task;
      });
    },
    updateTask: (state, action: PayloadAction<Task>) => {
      if (!state.allTasks) { state.allTasks = {}; }
      state.allTasks[action.payload.id] = action.payload;
    },
  },
});

export default taskSlice.reducer;

export const getAllTasks = (): AppThunk =>
  async dispatch => {
    const allTasks: Task[] = await getApi("task");
    dispatch(taskSlice.actions.updateAllTasks(allTasks));
  }

export const getTaskById = (id: string): AppThunk =>
  async dispatch => {
    const task: Task = await getApi(`task/${id}`);
    dispatch(taskSlice.actions.updateTask(task));
  }
