import React from "react";
import {
  SliderStyled,
} from "components/styledComponents/Input";
import { Box, Grid, Typography } from "@mui/material";
import { PromptAdvanceOptionsProps } from "./utils";
import CustomTextFieldStyled from "components/CustomTextFieldStyled";
import Info from "components/styledComponents/Info";

const Seed = ({ advanced, onUpdate }: PromptAdvanceOptionsProps) => {
  const min = 100_000_000,
    max = 1_000_000_000,
    step = 1;

  const onChange = (e: any) => {
    onUpdate("seed", parseInt(e.target.value) || 0);
  };

  return (
    <>
      <Grid
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        gap={1}
        width={"100%"}
        flexWrap={"wrap"}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            gap: "4px"
          }}
        >
          <Grid
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={0.5}

          >
            <Typography
              sx={{
                fontFamily: "Inter",
                color: "#AEB6C4",
                fontSize: "11px",
                fontStyle: "normal",
                fontWeight: 500,
              }}
            >
              Seed
            </Typography>
            <Info placement="right" iconSize="16px" title={`Your starting point for crafting unique patterns in creative designs.`} />
          </Grid>

          <CustomTextFieldStyled
            sx={{
              borderRadius: "4px",
              "& .MuiInputBase-root .MuiInputBase-input": {
                textAlign: "center",
                height: "5px",
              },
            }}
            type="number"
            name="seed"
            value={advanced.seed}
            onChange={onChange}
          />
        </Box>
        <SliderStyled
          size="small"
          value={advanced.seed}
          min={min}
          step={step}
          max={max}
          onChange={(_, newValue) => onUpdate("seed", newValue)}
        />
      </Grid>
    </>
  );
};

export default Seed;
