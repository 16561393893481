import React from "react";
import CollectionsIcon from "@mui/icons-material/Collections";
import styles from "./PromptAdvanceOptions.module.css";
import { TypographyBodyMedium } from "components/styledComponents/Typography";
import { Accordion, AccordionDetails, AccordionSummary, Grid } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Seed from "./Seed";
import Dilation, { MultiDilation } from "./Dilation";
import Depth from "./Depth";
import Canny from "./Canny";
import { PromptInfo, PromptAdvanced } from "utils/types";
import AdCopyParams from "./AdCopyParams";
import { PROJECT_TYPE } from "utils/enums";

type Props = {
  prompt: PromptInfo;
  onUpdate: (param: string, value: any) => void;
  onUpdatePrompt: (param: string, value: any) => void;
};

export const getAdvancedOptionsUI = (
  advanced: PromptAdvanced,
  onUpdate: (param: string, value: any) => void,
  prompt?: PromptInfo,
  onUpdatePrompt?: (param: string, value: any) => void,
) => {
  return (
    <div className={styles.prompt_adv_opt_sub}>
      {(!prompt || prompt.type !== PROJECT_TYPE.TEXT_GENERATION) && <div className={styles.prompt_adv_opt_subMain}>
        <Seed advanced={advanced} onUpdate={onUpdate} />
      </div>}
      {(!prompt || prompt.type !== PROJECT_TYPE.TEXT_GENERATION) && <div className={styles.prompt_adv_opt_subMain}>
        <Depth advanced={advanced} onUpdate={onUpdate} />
      </div>}
      {(!prompt || prompt.type !== PROJECT_TYPE.TEXT_GENERATION) && <div className={styles.prompt_adv_opt_subMain}>
        <Canny advanced={advanced} onUpdate={onUpdate} />
      </div>}
      {!prompt && (
        <div className={styles.prompt_adv_opt_subMain}>
          <Dilation advanced={advanced} onUpdate={onUpdate} />
        </div>
      )}
      {prompt && prompt.type !== PROJECT_TYPE.TEXT_GENERATION && onUpdatePrompt && (
        <MultiDilation multiDilation={prompt.multiDilation} onUpdate={onUpdatePrompt} prompt={prompt} />
      )}
      {prompt && prompt.type !== PROJECT_TYPE.IMAGE_GENERATION && onUpdatePrompt && (
        <AdCopyParams onUpdate={onUpdatePrompt} prompt={prompt} />
      )}
    </div>
  );
};

const PromptAdvanceOptions = ({ prompt, onUpdate, onUpdatePrompt }: Props) => {
  return (
    <div className={styles.prompt_adv_opt_root}>
      <Accordion
        sx={{
          backgroundColor: "transparent",
          boxShadow: "none",
          "& .MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded": {
            minHeight: "0px !important",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon style={{ color: "#7D899C" }} />}
          sx={{
            justifyContent: "left",
            padding: "0px",
            margin: "0px",
            minHeight: "unset !important",
            "& .Mui-expanded": {
              margin: "0px ",
            },
            "& .MuiAccordionSummary-content": {
              flexGrow: "unset",
              margin: "0px",
            },
          }}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className={styles.prompt_adv_opt_sub}>
            <CollectionsIcon className={styles.icon} />
            <Grid
              display={"flex"}
              alignItems={"center"}
              gap={1}
            >
              <TypographyBodyMedium>Options</TypographyBodyMedium>
            </Grid>
          </div>
        </AccordionSummary>
        <AccordionDetails className={styles.prompt_adv_opt_accordion_details}>
          {getAdvancedOptionsUI(
            prompt.advanced,
            onUpdate,
            prompt,
            onUpdatePrompt,
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default React.memo(PromptAdvanceOptions);
