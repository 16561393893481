import { Grid, Icon } from "@mui/material";
import { DownloadIcon } from "components/Icons";
import { Loader } from "components/Loader";
import React, { useCallback, useContext, useState } from "react";
import { Asset, Output } from "utils/types";
import { downloadFiles } from "utils/utils";
import Tooltip from "./styledComponents/Tooltip";
import { useEventsProvider } from "mixPanelEvent";
import { SnackbarContext } from "./Snackbar";

type AsyncDownloadIconProps = {
  files: Output[] | Asset[];
  styles?: any;
  folderId?: string;
};

const AsyncDownloadIcon = ({ files, styles, folderId }: AsyncDownloadIconProps) => {

  const [isDownloading, setIsDownloading] = useState(false);
  const { onRenderDownload, onBrandAssetDownload } = useEventsProvider();
  const { showSnackbar } = useContext(SnackbarContext);

  const handleOnClick = useCallback(async () => {
    if (isDownloading || files.length === 0) return;
    const file = files[0];
    if ("name" in file) { // Asset has name
      onBrandAssetDownload();
    } else {
      onRenderDownload(folderId ?? "N/A", file.projectId ?? "N/A", file.id);
    }

    setIsDownloading(true);
    await downloadFiles(files, showSnackbar);
    setIsDownloading(false);
  }, [isDownloading, files]);

  return (
    <Grid
      className={styles ? styles.actionIcon : ""}
      sx={
        styles
          ? {
            ".MuiCircularProgress-root": {
              minHeight: "20px",
              minWidth: "20px",
            },
          }
          : { minWidth: "24px", minHeight: "24px", maxHeight: "24px" }
      }
    >
      {isDownloading ? (
        <Loader type="primary" />
      ) : (
        <Tooltip title={"Download"}>
          <Icon
            sx={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleOnClick();
            }}
          >
            {DownloadIcon}
          </Icon>
        </Tooltip>
      )}
    </Grid>
  );
};

export default AsyncDownloadIcon;
