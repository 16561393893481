import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { postApi, getApi, deleteApi } from "utils/fetchUtils";
import { Template, CallbackType, showSnackbarType, CallbackModelType } from "utils/types";
import { SEVERITY } from "utils/enums";
import { AppThunk, store } from "store";
import { getVisibleOutputs, outputSlice } from "store/output";

const initialState = {
  allTemplates: null as { [id: string]: Template } | null,
};

const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    updateTemplates: (state, action: PayloadAction<Template[]>) => {
      state.allTemplates = {};
      action.payload.forEach(template => {
        if (!state.allTemplates) { state.allTemplates = {}; }
        state.allTemplates[template.id] = { ...(state.allTemplates[template.id] ?? {}), ...template };
      });
    },

    deleteTemplateAction: (state, action: PayloadAction<string>) => {
      delete state.allTemplates?.[action.payload];
    },

    updateTemplatesById: (state, action: PayloadAction<Template>) => {
      if (!state.allTemplates) { state.allTemplates = {}; }
      state.allTemplates[action.payload.id] = { ...(state.allTemplates[action.payload.id] ?? {}), ...action.payload };
    },

    updateTemplatesData: (state, action: PayloadAction<{ id: string, data: any }>) => {
      if (!state.allTemplates) { state.allTemplates = {}; }
      const template = (state.allTemplates[action.payload.id] ?? { data: {} });
      state.allTemplates[action.payload.id] = { ...template, data: { ...template.data, ...action.payload.data } };
    },

    updateTemplatesWithoutData: (state, action: PayloadAction<Template>) => {
      if (!state.allTemplates) { state.allTemplates = {}; }
      const template = (state.allTemplates[action.payload.id] ?? { data: {} });
      state.allTemplates[action.payload.id] = { ...template, ...action.payload, data: { ...action.payload.data, ...template.data } };
    },

  },
});

export default templateSlice.reducer;

export const createTemplate = (payload: any, showSnackbar?: showSnackbarType, callback?: CallbackModelType<Template>): AppThunk =>
  async dispatch => {
    const cb = (template: Template) => {
      showSnackbar?.("Template Created.", SEVERITY.SUCCESS);
      callback?.(template);
    }

    const template: Template = await postApi("template", payload);
    dispatch(templateSlice.actions.updateTemplatesById(template));
    dispatch(getAllTemplates(() => cb(template)));
  }

export const createTemplateFromFile = (payload: any, showSnackbar?: showSnackbarType, callback?: CallbackModelType<Template>): AppThunk =>
  async dispatch => {
    const cb = (template: Template) => {
      showSnackbar?.("Template Created.", SEVERITY.SUCCESS);
      callback?.(template);
    }

    const formData = new FormData();
    formData.append("file", payload.file);
    formData.append("type", payload.type);
    formData.append("scope", payload.scope);

    for (const tag of payload.tags) {
      formData.append("tags[]", tag);
    }

    const template: Template = await postApi("template/create-from-file", formData);
    dispatch(templateSlice.actions.updateTemplatesById(template));
    dispatch(getVisibleOutputs(() => cb(template)));
  }

export const getAllTemplates = (callback?: CallbackType): AppThunk =>
  async dispatch => {
    const templates: Template[] = await getApi("template");
    const sortedTemplates = templates.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1);
    dispatch(templateSlice.actions.updateTemplates(sortedTemplates));
    callback?.();
  }

export const getTemplateById = (id: string, callback?: CallbackModelType<Template>): AppThunk =>
  async dispatch => {
    const template: Template = await getApi(`template/${id}`);
    dispatch(templateSlice.actions.updateTemplatesById(template));
    callback?.(template);
  }

export const deleteTemplateById = (id: string, showSnackbar?: showSnackbarType, callback?: CallbackType): AppThunk =>
  async dispatch => {
    const cb = () => {
      showSnackbar?.("Template Deleted.", SEVERITY.SUCCESS);
      callback?.();
    }

    await deleteApi(`template/${id}`);
    const template = Object.values(store.getState().template.allTemplates ?? {}).find(t => t.id === id);
    dispatch(templateSlice.actions.deleteTemplateAction(id));
    if (template) {
      dispatch(outputSlice.actions.removeOutput(template.outputId))
    }

    cb();
  }
