import React from "react";
import { SliderStyled } from "components/styledComponents/Input";
import { Box, Grid, Typography } from "@mui/material";
import { PromptAdvanceOptionsProps } from "./utils";
import Info from "components/styledComponents/Info";

const Canny = ({ advanced, onUpdate }: PromptAdvanceOptionsProps) => {
  const min = 0,
    max = 1,
    step = 0.05;

  return (
    <>
      <Grid
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        gap={1}
        width={"100%"}
        flexWrap={"wrap"}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Grid
            display={"flex"}
            flexDirection={"row"}
            alignItems={"center"}
            gap={0.5}

          >
            <Typography
              sx={{
                fontFamily: "Inter",
                color: "#AEB6C4",
                fontSize: "11px",
                fontStyle: "normal",
                fontWeight: 500,
              }}
            >
              Canny
            </Typography>
            <Info placement="right" iconSize="16px" title={`Highlights contours, improving visual analysis and detail perception.`} />

          </Grid>

          <Typography
            sx={{
              fontFamily: "Inter",
              color: "#fff",
              fontSize: "11px",
              fontStyle: "normal",
              fontWeight: 500,
            }}
          >
            {advanced.canny}
          </Typography>
        </Box>
        <SliderStyled
          size="small"
          value={advanced.canny}
          min={min}
          step={step}
          max={max}
          onChange={(_, newValue) => onUpdate("canny", newValue)}
        />
      </Grid>
    </>
  );
};

export default Canny;
